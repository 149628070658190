export class SettingsDispatcher<S extends object> implements ProxyHandler<S> {
    settings: S;

    private listeners = new Map<string, Array<(value: any) => void>>();

    subscribe(p: string, listener: (value: any) => void): void {
        let l = this.listeners.get(p);
        if (!l) {
            l = [];
            this.listeners.set(p, l);
        }
        l.push(listener);
    }

    unsubscribe(p: string, listener: (value: any) => void): void {
        const l = this.listeners.get(p);
        if (!l) return;
        const i = l.indexOf(listener);
        if (i !== -1) l.splice(i, 1);
    }

    constructor(settings: S) {
        this.settings = new Proxy<S>(settings, this);
    }

    set(settings: S, p: string, value: any): boolean {
        // @ts-ignore
        settings[p] = value;

        const l = this.listeners.get(p);
        if (l) {
            for (const listener of l)
                listener(value);
        }
        return true;
    }
}
