import {
    BackSide, BoxGeometry,
    Color,
    FrontSide,
    Matrix4,
    Mesh,
    PlaneGeometry,
    ShaderLib,
    ShaderMaterial,
    Texture,
    Uniform,
    UniformsUtils, WebGLCubeRenderTarget
} from "three";
import {Background} from "./Background.js";

// copy of ShaderLib.background.fragmentShader, but with dithering
const backgroundFragShader = `
#include <common>
#include <dithering_pars_fragment>
uniform sampler2D t2D;
uniform float backgroundIntensity;
varying vec2 vUv;

void main() {
	vec4 texColor = texture2D( t2D, vUv );
    texColor.rgb *= backgroundIntensity;
    gl_FragColor = texColor;
    
	#include <tonemapping_fragment>
	#include <colorspace_fragment>
	#include <dithering_fragment>
}
`;

export class WebGLBackground extends Background {

    renderColor(): void {
        const background = this.background as Color;
        this.renderingManager.renderer.setClearColor(background, this.settingsDispatcher.settings.backgroundAlpha);
        this.renderingManager.renderer.clear();
    }

    renderTexture(): void {
        const background = this.background as Texture;

        if (this.planeMesh === undefined) {
            const planeGeometry = new PlaneGeometry(2, 2);
            this.planeMesh = new Mesh(
                planeGeometry,
                new ShaderMaterial({
                    uniforms: UniformsUtils.clone(ShaderLib.background.uniforms),
                    vertexShader: ShaderLib.background.vertexShader,
                    fragmentShader: backgroundFragShader,
                    side: FrontSide,
                    depthTest: false,
                    depthWrite: false,
                    fog: false,
                    dithering: true
                })
            );
            this.planeMesh.frustumCulled = false; // Required for correct rendering in VR
            planeGeometry.deleteAttribute('normal');

            // enable code injection for non-built-in material
            Object.defineProperty(this.planeMesh.material, 'map', {
                get: function () {
                    return this.uniforms.t2D.value;
                }
            });

            this.backgroundScene.children.length = 0;
            this.backgroundScene.add(this.planeMesh);
        }

        if (this.boxMesh) {
            this.backgroundScene.remove(this.boxMesh);
            this.boxMesh = undefined;
        }

        // @ts-ignore
        this.planeMesh.material.uniforms.t2D.value = this.background;

        if (background.matrixAutoUpdate === true) {
            // @ts-ignore
            background.updateMatrix();
        }

        // @ts-ignore
        this.planeMesh.material.uniforms.uvTransform.value.copy(this.background.matrix);

        if (this.currentBackground !== this.background ||
            this.currentBackgroundVersion !== background.version) {

            // @ts-ignore
            this.planeMesh.material.needsUpdate = true;

            this.currentBackground = this.background;
            this.currentBackgroundVersion = background.version;
        }

        this.syncCamera();
        this.renderingManager.renderer.render(this.backgroundScene, this.backgroundCamera);
    }

    renderCubemap(): void {
        if (this.boxMesh === undefined) {

            const uniforms = UniformsUtils.clone(ShaderLib.backgroundCube.uniforms);
            uniforms.rotMatrix = new Uniform(new Matrix4());

            const backgroundMaterial = new ShaderMaterial({
                uniforms: uniforms,
                vertexShader: ShaderLib.backgroundCube.vertexShader,
                fragmentShader: ShaderLib.backgroundCube.fragmentShader,
                side: BackSide,
                depthTest: false,
                depthWrite: false,
                fog: false
            });

            const boxGeometry = new BoxGeometry(100, 100, 100);
            this.boxMesh = new Mesh(boxGeometry, backgroundMaterial);
            this.boxMesh.frustumCulled = false; // Required for correct rendering in VR

            boxGeometry.deleteAttribute('normal');
            boxGeometry.deleteAttribute('uv');

            // enable code injection for non-built-in material
            Object.defineProperty(this.boxMesh.material, 'envMap', {
                get: function () {
                    return this.uniforms.envMap.value;
                }
            });
            this.backgroundScene.children.length = 0;
            this.backgroundScene.add(this.boxMesh);
        }

        if (this.planeMesh) {
            this.backgroundScene.remove(this.planeMesh);
            this.planeMesh = undefined;
        }

        const texture = this.settingsDispatcher.settings.background === "environment" ? this.renderingManager.uniforms.envMap.value :
            (this.background instanceof WebGLCubeRenderTarget ? this.background.texture : this.background);

        // @ts-ignore
        this.boxMesh.material.uniforms.envMap.value = texture;

        if (this.currentBackground !== this.background ||
            this.currentBackgroundVersion !== texture.version) {

            // @ts-ignore
            this.boxMesh.material.needsUpdate = true;

            this.currentBackground = this.background;
            this.currentBackgroundVersion = texture.version;
        }

        this.syncCamera();
        this.renderingManager.renderer.render(this.backgroundScene, this.backgroundCamera);
    }

}
