import {
    ShaderMaterial, Vector2, Uniform
} from "three";

// language=GLSL
const fragmentShader = `
    uniform sampler2D maskTexture;
    varying vec2 vUv;
    
    void main() {
        vec2 c0 = textureOffset(maskTexture, vUv, ivec2(0, OFFSET)).rg;
        vec2 c1 = textureOffset(maskTexture, vUv, ivec2(0, -OFFSET)).rg;
        vec2 c2 = textureOffset(maskTexture, vUv, ivec2(OFFSET, 0)).rg;
        vec2 c3 = textureOffset(maskTexture, vUv, ivec2(-OFFSET, 0)).rg;
        vec2 c4 = textureOffset(maskTexture, vUv, ivec2(OFFSET_DIAG, OFFSET_DIAG)).rg;
        vec2 c5 = textureOffset(maskTexture, vUv, ivec2(-OFFSET_DIAG, -OFFSET_DIAG)).rg;
        vec2 c6 = textureOffset(maskTexture, vUv, ivec2(OFFSET_DIAG, -OFFSET_DIAG)).rg;
        vec2 c7 = textureOffset(maskTexture, vUv, ivec2(-OFFSET_DIAG, OFFSET_DIAG)).rg;
        float d0=(c0.x-c1.x)*0.5;
        float d1=(c2.x-c3.x)*0.5;
        float d3=(c4.x-c5.x)*0.5;
        float d4=(c6.x-c7.x)*0.5;
        float d=length(vec2(length(vec2(d0,d1)), length(vec2(d3,d4))));
        float visibilityFactor = min(min(min(c0.y,c1.y), min(c2.y,c3.y)), min(min(c4.y,c5.y), min(c6.y,c7.y)));
        gl_FragColor = vec4((1.0-visibilityFactor>0.001) ? vec2(d, 0.0): vec2(0.0, d), 1.0, 1.0);
    }
`;

// language=GLSL
const vertexShader = `
    varying vec2 vUv;
    
    void main() {
        vUv = position.xy*0.5+0.5;
        gl_Position = vec4(position.xy,1.0,1.0);
    }
`;

export class OutlineEffectMaterial extends ShaderMaterial {
    constructor() {
        super({
            uniforms: {
                maskTexture: new Uniform(null),
                texelSize: new Uniform(new Vector2())
            },
            defines: {
                OFFSET: 0,
                OFFSET_DIAG: 0
            },

            fragmentShader: fragmentShader,
            vertexShader: vertexShader,

            depthWrite: false,
            depthTest: false
        });
        this.updatePixelRatio();
    }

    updatePixelRatio(): void {
        this.defines.OFFSET = Math.min(Math.round(2.5 * devicePixelRatio), 7);
        this.defines.OFFSET_DIAG = Math.min(Math.round(1.6 * devicePixelRatio), 7);
        this.needsUpdate = true;
    }
}
