import { PointerInput } from "../InputHandler.js";
import {Vector2, Vector3} from "three";
import { Observable, Subscription } from "rxjs";
import { MouseButton } from "../common.js";
import { SinglePointerTool } from "./SinglePointerTool.js";
import {Zoom} from "./Zoom.js";
import {Vector3Const} from "../Helpers/common-utils.js";
import {Api} from "../Api.js";

export class Pan extends SinglePointerTool {
    static get Name(): string { return "pan"; }

    private _dragObservable: Observable<PointerInput>;
    private _speed: number;
    private _dragHandle: Subscription;
    private previousEvent: PointerInput;

    get name(): string {
        return Pan.Name;
    }

    constructor(private _api: Api) {
        super();
        this.mouseButton = MouseButton.middle;
        this.touchCount = 2;

        this._dragObservable = _api.inputHandler.createDragObservable(this.observableOptions,
            e => this._downCallback(e),
            e => this.moveCallback(e),
            e => this.upCallback(e));

        if(!this._pivotPoint) this.createPivotPoint();
        if(!this._renderPass) this.createRenderPass(this._api.camera, this._api.renderingManager.composer);
        this.enabled = true;
    }

    private set speed(point: Vector3) {
        let distance: number;
        if (point)
            distance = this._api.camera.position.distanceTo(point);
        else {
            // If no point was picked assume camera is far away from any model
            // TODO: use Picker.pickNearestModelCenter instead?
            distance = Zoom.getDistanceToNearestModel(this._api.camera.position, this._api.models, 10, 1000);
        }
        const viewWorldSize = this._api.camera.getViewWorldSize(distance) * 2;
        this._speed = viewWorldSize / Math.max(this._api.container.clientWidth, this._api.container.clientHeight);
    }

    private async _downCallback(event: PointerInput): Promise<PointerInput> {
        const point = await this._api.picker.pickForNavigation(new Vector2(event.x, event.y));
        if(point) this.updatePivotPoint(point);
        this.speed = point ? point : null;
        this.previousEvent = event;
        return event;
    }

    private moveCallback = (() => {
        const up = new Vector3();
        const right = new Vector3();

        return (event: PointerInput) => {
            const deltaX = event.screenX - this.previousEvent.screenX;
            const deltaY = event.screenY - this.previousEvent.screenY;

            up.copy(Vector3Const.forward);
            right.copy(Vector3Const.right);

            up.applyQuaternion(this._api.camera.quaternion);
            right.applyQuaternion(this._api.camera.quaternion);

            right.multiplyScalar(-deltaX * this._speed);
            up.multiplyScalar(deltaY * this._speed);

            this._api.camera.position.add(right).add(up);
            this._api.camera.callListeners();

            this.previousEvent = event;
            return event;
        };
    })();

    public upCallback(event: PointerInput): PointerInput {
        this._pivotPoint.visible = false;
        this._api.renderingManager.redraw();
        return event;
    }

    set enabled(enabled: boolean) {
        if (this._dragHandle) {
            this._dragHandle.unsubscribe();
            this._dragHandle = null;
            this._renderPass.scene.remove(this._pivotPoint);
        }
        if (!enabled) return;

        this._dragHandle = this._dragObservable.subscribe();
        this._pivotPoint.visible = false;
        this._renderPass.scene.add(this._pivotPoint);
    }

    get enabled(): boolean {
        return !!this._dragHandle;
    }
}
