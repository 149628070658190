import {
    Vector3,
    WebGLRenderer,
    Intersection,
    Matrix4,
    Ray,
    LineSegments,
    Frustum,
    InterleavedBufferAttribute,
    Box3, Mesh, InstancedInterleavedBuffer,
} from "three";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import {Caster} from "../Picker/Caster.js";
import {Web3DLineMaterial} from "../Rendering/Web3DLineMaterial.js";
import {MeshLineGeometry} from "./MeshLineGeometry.js";
import {iterate} from "../Helpers/common-utils.js";

const _start = new Vector3();
const _end = new Vector3();

export class MeshLine extends Mesh {
    private _inverseMatrix = new Matrix4();
    private _ray = new Ray();
    private _frustum = new Frustum();
    private _box = new Box3();
    private vStart = new Vector3();
    private vEnd = new Vector3();
    private interPoint = new Vector3();
    private segmentPoint = new Vector3();

    declare material: LineMaterial | LineMaterial[] | Web3DLineMaterial | Web3DLineMaterial[];
    declare geometry: MeshLineGeometry;

    constructor(geometry: MeshLineGeometry, material: LineMaterial | LineMaterial[] | Web3DLineMaterial | Web3DLineMaterial[]) {
        super(geometry, material);
        this.material = material as any;
        const m = Array.isArray(material) ? material[0] : material;
        if (m && m.defines) m.dashed ? m.defines.USE_DASH = "" : delete m.defines.USE_DASH;
        this.onBeforeRender = (renderer: WebGLRenderer) => {
            iterate(this.material, m => {
                if (m.resolution) renderer.getSize(m.resolution);
                if (geometry && geometry.hasVisibilityMask && (m instanceof Web3DLineMaterial))
                    m.hasVisibilityAttribute = true;
            });
        };
    }

    update(vectors: Vector3[]): void {
        this.geometry.setLinePoints(vectors);
        if ((this.material as LineMaterial).dashed) this.computeLineDistances();
        if (this.geometry.boundingBox) this.geometry.computeBoundingBox();
        if (this.geometry.boundingSphere) this.geometry.computeBoundingSphere();
    }

    computeLineDistances(): void {
        const geometry = this.geometry;
        const instanceStart = geometry.attributes.instanceStart;
        const instanceEnd = geometry.attributes.instanceEnd;
        const lineDistances = new Float32Array( 2 * instanceStart.count );

        for ( let i = 0, j = 0, l = instanceStart.count; i < l; i ++, j += 2 ) {
            _start.fromBufferAttribute( instanceStart, i );
            _end.fromBufferAttribute( instanceEnd, i );
            lineDistances[ j ] = ( j === 0 ) ? 0 : lineDistances[ j - 1 ];
            lineDistances[ j + 1 ] = lineDistances[ j ] + _start.distanceTo( _end );
        }
        const instanceDistanceBuffer = new InstancedInterleavedBuffer( lineDistances, 2, 1 ); // d0, d1
        geometry.setAttribute( 'instanceDistanceStart', new InterleavedBufferAttribute( instanceDistanceBuffer, 1, 0 ) ); // d0
        geometry.setAttribute( 'instanceDistanceEnd', new InterleavedBufferAttribute( instanceDistanceBuffer, 1, 1 ) ); // d1
    }

    override raycast(caster: Caster, intersects: Intersection[]): void {
        if (!this.geometry.boundingBox) return;

        const geometry = this.geometry;
        if (!geometry.attributes.instanceEnd)
            return;

        const matrixWorld = this.matrixWorld;

        this._box.copy(geometry.boundingBox);
        this._box.applyMatrix4(matrixWorld);

        if (!caster.frustum.intersectsBox(this._box)) return;

        this._inverseMatrix.copy(matrixWorld).invert();
        this._ray.copy(caster.ray).applyMatrix4(this._inverseMatrix);
        this._frustum.copy(caster.frustum);
        for (const plane of this._frustum.planes) plane.applyMatrix4(this._inverseMatrix);

        const step = (this && this instanceof LineSegments) ? 2 : 1;

        const positions = (geometry.attributes.instanceEnd as InterleavedBufferAttribute).data.array;

        for (let i = 0, l = positions.length / 3 - 1; i < l; i += step) {
            this.vStart.fromArray(positions, 3 * i);
            this.vEnd.fromArray(positions, 3 * i + 3);

            if (!this.intersect(this._ray, this._frustum, this.vStart, this.vEnd, this.interPoint)) continue;

            this.interPoint.applyMatrix4(this.matrixWorld);
            const distance = caster.ray.origin.distanceTo(this.interPoint);
            if (distance < caster.near || distance > caster.far) continue;

            this.segmentPoint.applyMatrix4(this.matrixWorld);
            intersects.push({
                distance: distance,
                point: this.segmentPoint.clone(),
                index: i,
                object: this
            });
        }
    }

    private intersect(ray: Ray, frustum: Frustum, lineStart: Vector3, lineEnd: Vector3, target: Vector3): boolean {
        ray.distanceSqToSegment(lineStart, lineEnd, target, this.segmentPoint);
        return frustum.containsPoint(this.segmentPoint);
    }
}
