import {
    Box3,
    BoxGeometry,
    Color,
    DoubleSide,
    Float32BufferAttribute,
    Intersection,
    LessEqualDepth,
    Mesh,
    MeshBasicMaterial,
    Object3D, Quaternion,
    Raycaster,
    Vector3
} from "three";
import {MeshLine} from "./CustomObjects/MeshLine.js";
import {MeshLineGeometry} from "./CustomObjects/MeshLineGeometry.js";
import {Web3DLineMaterial} from "./Rendering/Web3DLineMaterial.js";
import {Api} from "./Api.js";
import {AlwaysDepth} from "three/src/constants.js";

export class ClipBox extends Object3D {
    origin?: any;
    box: Box3;
    private readonly _color: Color = new Color(0.6,0.6,0.8);
    private readonly _selectedColor: Color = new Color(0.8,0.6,0.2);
    private readonly _hoverColor: Color = new Color(1,1,1);
    private _boxGeometryColors: Float32Array = new Float32Array(24 * 3);
    private _boxGeometry: BoxGeometry;
    private _boxMeshMaterial: MeshBasicMaterial;
    private boxLines: MeshLine[] = []; // The 12 lines that make up the edges of the box
    private boxMesh: Mesh;
    private localCornerPositions: Vector3[] = [];
    private readonly _positionSigns = [1,1,1,1,1,-1,1,-1,1,1, -1, -1, -1, 1, -1, -1, 1, 1, -1, -1, -1, -1, -1, 1, -1, 1, -1, 1, 1, -1, -1, 1, 1, 1, 1, 1, -1, -1, 1, 1, -1, 1, -1, -1, -1, 1, -1, -1, -1, 1, 1, 1, 1, 1, -1, -1, 1, 1, -1, 1, 1, 1, -1, -1, 1, -1, 1, -1, -1, -1, -1, -1];
    private _selected = false;
    private _currentHighlightIndex: number = -1;
    private cornerToFaces: Map<number, number[]> = new Map();
    private lineToFaces: Map<number, number[]> = new Map();
    private cornerToLines: Map<number, number[]> = new Map();

    /**
     * indices:
     * 0 - 5: faces
     * 6 - 17; edges
     * 18 - 25: corners
     * @param _api
     */

    private _edgesVisible = true;

    get edgesVisible(): boolean {
        return this._edgesVisible;
    }

    set edgesVisible(value: boolean) {
        if (value) {
            this.addBoxLines();
        } else {
            this.removeBoxLines();
        }
        this._edgesVisible = value;
    }

    getBox(): Box3 {
        return this.box; // TODO: decide if box should be private
    }

    getCornerToLines(cornerIndex: number): number[] {
        return this.cornerToLines.get(cornerIndex);
    }

    getCornerToFaces(cornerIndex: number): number[] {
        return this.cornerToFaces.get(cornerIndex);
    }

    getFaceIndicesFromLine(lineIndex: number): number[] {
        return this.lineToFaces.get(lineIndex);
    }


    get selected(): boolean {
        return this._selected;
    }

    constructor(private _api: Api, visible: boolean = true) {
        super();
        this.box = new Box3();
        this.createCornerPositions();
        this.createBoxMesh();
        this.createBoxLines();
        this.createCornerToLinesMap();
        this.createLineToFacesMap();
        this.createCornerToFacesMap();
        this.edgesVisible = visible;
    }

    update(position: Vector3, quaternion: Quaternion = new Quaternion(), box: Box3): void {
        this.box.copy(box);
        this.updateBoxMesh();
        this.updateBoxLines();
        this.position.copy(position);
        this.quaternion.copy(quaternion);
        this.updateWorldMatrix(true, true);
    }

    updateFromCenterAndBox(center: Vector3, box: Box3): void {
        this.box.copy(box);
        this.updateBoxMesh();
        this.updateBoxLines();
        this.position.copy(center);
        this.updateWorldMatrix(true, true);
    }

    select(): void {
        this._selected = true;
        if (!this.boxMesh.parent) {
            this.add(this.boxMesh);
            this.updateWorldMatrix(true, true);
        }

        for (let i = 0; i < 12; i++) {
            (this.boxLines[i].material as Web3DLineMaterial).color = this._selectedColor;
            (this.boxLines[i].material as Web3DLineMaterial).depthFunc = AlwaysDepth;
            (this.boxLines[i].material as Web3DLineMaterial).depthTest = false;
            (this.boxLines[i].material as Web3DLineMaterial).depthWrite = false;
            (this.boxLines[i].material as Web3DLineMaterial).needsUpdate = true;
        }
    }

    deselect(): void {
        this._selected = false;
        if(this.boxMesh.parent)
            this.remove(this.boxMesh);

        for (let i = 0; i < 12; i++) {
            (this.boxLines[i].material as Web3DLineMaterial).color = this._color;
            (this.boxLines[i].material as Web3DLineMaterial).depthFunc  = LessEqualDepth;
            (this.boxLines[i].material as Web3DLineMaterial).depthTest = true;
            (this.boxLines[i].material as Web3DLineMaterial).depthWrite = true;
            (this.boxLines[i].material as Web3DLineMaterial).needsUpdate = true;
        }
    }

    highlightByIndex(index: number): void {
        if (index === this._currentHighlightIndex) return;
        this._currentHighlightIndex = index;
        if (this._currentHighlightIndex === -1) {
            this.updateHighlightedFaces([]);
            this.updateHighlightedLines([]);
            return;
        }

        if (this._currentHighlightIndex < 6) {
            this.updateHighlightedFaces([this._currentHighlightIndex]);
            this.updateHighlightedLines([]);
            return;
        }

        if (this._currentHighlightIndex < 18) {
            this.updateHighlightedLines([this._currentHighlightIndex]);
            this.updateHighlightedFaces([]);
            return;
        }

        if (this._currentHighlightIndex < 26) {
            this.updateHighlightedLines(this.cornerToLines.get(this._currentHighlightIndex).slice(0,2)); // return the horizontal lines.
            this.updateHighlightedFaces([]);
            return;
        }
    }

    override raycast(raycaster:Raycaster, intersects:Intersection[]): void {
        if (!this.parent) // do not pick if it is not added to the scene
            return;

        // try pick edges. Edges always have priority over faces, allowing picking edges through faces. This could be changed if preference is to not pick hidden edges.
        const intersections: Intersection[] = [];
        if (this._edgesVisible)
            raycaster.intersectObjects(this.boxLines, false, intersections);  // replace this with override that gives the wire index.

        if (intersections.length > 0) {
            const intersection = intersections[0];

            // if more than 2 intersections check if first 3 make up a corner and if so return the corner index
            if (intersections.length > 2) {
                const indices = [];
                for (let i = 0; i < 3; i++)
                    indices.push(intersections[i].object.userData.index);
                const corner = this.getCornerFromLines(indices);
                if (corner) {
                    intersection.index = corner;
                    intersects.push(intersection);
                    return;
                }
            }

            // return the closest edge index.
            intersection.index = intersection.object.userData.index;
            intersects.push(intersection);
            return;
        }

        // box faces should only be picked if box is selected
        if (this._selected && intersections.length === 0) {
            raycaster.intersectObject(this.boxMesh, false, intersections);
            if (intersections.length > 0) {
                const intersection = intersections[0];
                intersection.index = Math.floor(intersection.faceIndex / 2);
                intersects.push(intersection);
            }
        }
    }

    private changeLineColor(indices: number[]): void {
        for (let i = 0; i < 12; i++) {
            if (indices.includes(i + 6)) {
                (this.boxLines[i].material as Web3DLineMaterial).color = this._hoverColor;
            } else {
                (this.boxLines[i].material as Web3DLineMaterial).color = this._selected ? this._selectedColor : this._color;
            }

            (this.boxLines[i].material as Web3DLineMaterial).needsUpdate = true;
        }
    }

    private getCornerFromLines(lineIndices: number[]): number {
        for (const corner of this.cornerToLines.entries()) {
            if (corner[1].every(line => lineIndices.includes(line))) return corner[0];
        }
    }
    private createCornerToFacesMap(): void {
        this.cornerToFaces.set(18, [5, 3, 1]);
        this.cornerToFaces.set(19, [5, 0, 3]);
        this.cornerToFaces.set(20, [5, 2, 0]);
        this.cornerToFaces.set(21, [5, 1, 2]);

        this.cornerToFaces.set(22, [1, 3, 4]);
        this.cornerToFaces.set(23, [3, 0, 4]);
        this.cornerToFaces.set(24, [0, 2, 4]);
        this.cornerToFaces.set(25, [2, 1, 4]);
    }

    private createLineToFacesMap(): void {
        this.lineToFaces.set(6, [5, 3]);
        this.lineToFaces.set(7, [5, 0]);
        this.lineToFaces.set(8, [5 ,2]);
        this.lineToFaces.set(9, [5, 1]);

        this.lineToFaces.set(10, [1, 3]);
        this.lineToFaces.set(11, [3, 0]);
        this.lineToFaces.set(12, [0, 2]);
        this.lineToFaces.set(13, [2, 1]);

        this.lineToFaces.set(14, [4, 3]);
        this.lineToFaces.set(15, [4, 0]);
        this.lineToFaces.set(16, [4, 2]);
        this.lineToFaces.set(17, [4, 1]);
    }

    private createCornerPositions(): void {
        for (let i = 0; i < 8; i++) {
            this.localCornerPositions.push(new Vector3());
        }
    }

    private createBoxMesh(): void {
        this._boxGeometry = new BoxGeometry();
        for (let i = 0; i < 24; i++) {
            this._boxGeometryColors[i*3] = this._selectedColor.r;
            this._boxGeometryColors[i*3+1] = this._selectedColor.g;
            this._boxGeometryColors[i*3+2] = this._selectedColor.b;
        }
        this._boxGeometry.setAttribute("color", new Float32BufferAttribute(this._boxGeometryColors, 3));
        this._boxMeshMaterial = new MeshBasicMaterial({vertexColors: true, transparent: true, opacity: 0.5, depthTest: true, depthWrite: true, side: DoubleSide});
        this.boxMesh = new Mesh(this._boxGeometry, this._boxMeshMaterial);
    }

    private createBoxLines(): void {
        for (let i = 0; i < 12; i++) {
            this.boxLines.push(new MeshLine(new MeshLineGeometry(), new Web3DLineMaterial({color: this._color, linewidth: 2, depthTest: true, depthWrite: true, depthFunc: LessEqualDepth}, this._api.renderingManager.uniforms)));
            this.boxLines[i].userData.index = i + 6;
        }
    }

    private addBoxLines(): void {
        for (let i = 0; i < 12; i++)
            this.add(this.boxLines[i]);
    }

    private removeBoxLines(): void {
        for (let i = 0; i < 12; i++)
            this.remove(this.boxLines[i]);
    }

    private updateBoxMesh(): void {
        const size = new Vector3();
        this.box.getSize(size);
        this._setBoxGeometrySize(size);
    }

    private updateBoxLines(): void {
        // update corner positions
        this.localCornerPositions[0].set(this.box.min.x, this.box.min.y, this.box.min.z);
        this.localCornerPositions[1].set(this.box.max.x, this.box.min.y, this.box.min.z);
        this.localCornerPositions[2].set(this.box.max.x, this.box.max.y, this.box.min.z);
        this.localCornerPositions[3].set(this.box.min.x, this.box.max.y, this.box.min.z);

        this.localCornerPositions[4].set(this.box.min.x, this.box.min.y, this.box.max.z);
        this.localCornerPositions[5].set(this.box.max.x, this.box.min.y, this.box.max.z);
        this.localCornerPositions[6].set(this.box.max.x, this.box.max.y, this.box.max.z);
        this.localCornerPositions[7].set(this.box.min.x, this.box.max.y, this.box.max.z);

        // bottom outline
        this.boxLines[0].update([
            this.localCornerPositions[0],
            this.localCornerPositions[1]
        ]);
        this.boxLines[1].update([
            this.localCornerPositions[1],
            this.localCornerPositions[2]
        ]);
        this.boxLines[2].update([
            this.localCornerPositions[2],
            this.localCornerPositions[3]
        ]);
        this.boxLines[3].update([
            this.localCornerPositions[3],
            this.localCornerPositions[0]
        ]);

        // side lines
        this.boxLines[4].update([
            this.localCornerPositions[0],
            this.localCornerPositions[4]
        ]);
        this.boxLines[5].update([
            this.localCornerPositions[1],
            this.localCornerPositions[5]
        ]);
        this.boxLines[6].update([
            this.localCornerPositions[2],
            this.localCornerPositions[6]
        ]);
        this.boxLines[7].update([
            this.localCornerPositions[3],
            this.localCornerPositions[7]
        ]);

        // top outline
        this.boxLines[8].update([
            this.localCornerPositions[4],
            this.localCornerPositions[5]
        ]);
        this.boxLines[9].update([
            this.localCornerPositions[5],
            this.localCornerPositions[6]
        ]);
        this.boxLines[10].update([
            this.localCornerPositions[6],
            this.localCornerPositions[7]
        ]);
        this.boxLines[11].update([
            this.localCornerPositions[7],
            this.localCornerPositions[4]
        ]);

        for (const boxLine of this.boxLines) {
            boxLine.geometry.computeBoundingBox();
        }
    }

    private _setBoxGeometrySize( size: Vector3): void {
        const positions = this.boxMesh.geometry.attributes.position.array;

        const x = size.x / 2;
        const y = size.y / 2;
        const z = size.z / 2;

        for (let i = 0; i < positions.length; i += 3) {
            positions[i] = this._positionSigns[i] * x;
            positions[i + 1] = this._positionSigns[i + 1] * y;
            positions[i + 2] = this._positionSigns[i + 2] * z;
        }

        this.boxMesh.geometry.attributes.position.needsUpdate = true;
        this.boxMesh.geometry.computeBoundingBox();
        this.boxMesh.geometry.computeBoundingSphere();
    }

    private createCornerToLinesMap(): void {
        this.cornerToLines.set(18, [9,6,10]);
        this.cornerToLines.set(19,[6,7,11]);
        this.cornerToLines.set(20,[7,8,12]);
        this.cornerToLines.set(21, [8,9,13]);

        this.cornerToLines.set(22, [17,14,10]);
        this.cornerToLines.set(23,[14,15,11]);
        this.cornerToLines.set(24,[15,16,12]);
        this.cornerToLines.set(25, [16,17,13]);
    }

    private updateHighlightedFaces(indices: number[]): void {
        this.changeFaceColors(indices);
    }

    private updateHighlightedLines(indices: number[]): void {
        this.changeLineColor(indices);
        this._api.renderingManager.redraw();
    }

    private changeFaceColors(indices: number[]): void {
        const colors = this.boxMesh.geometry.attributes.color.array as Float32Array;
        this.setSelectedColor(colors);

        if (indices && indices.length > 0) {
            const corners = [];
            for (const item of indices) {
                corners.push(item * 4);
                corners.push(item * 4 + 1);
                corners.push(item * 4 + 2);
                corners.push(item * 4 + 3);
            }

            for (const corner of corners) {
                colors[corner * 3] = this._hoverColor.r;
                colors[corner * 3 + 1] = this._hoverColor.g;
                colors[corner * 3 + 2] = this._hoverColor.b;
            }
        }

        this.boxMesh.geometry.attributes.color.needsUpdate = true;
        this._api.renderingManager.redraw();
    }

    private setSelectedColor(colors: Float32Array): void {
        for (let i = 0; i < colors.length; i += 3) {
            colors[i] = this._selectedColor.r;
            colors[i + 1] = this._selectedColor.g;
            colors[i + 2] = this._selectedColor.b;
        }
    }
}