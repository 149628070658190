import {GPUPicker} from "../../Picker/GPUPicker.js";
import {RenderingManager} from "../RenderingManager.js";
import {Web3DCamera} from "../Web3DCamera.js";
import {SettingsDispatcher} from "../../SettingsDispatcher.js";
import {Settings} from "../../common.js";
import {
    DoubleSide,
    Mesh,
    PlaneGeometry,
    Vector2, Vector3,
    WebGPUCoordinateSystem
} from "three";
import {MeshBasicNodeMaterial, texture, vec2, uniform} from "../../Three.WebGPU.js";

export class WebGPUGPUPicker extends GPUPicker {

    private readonly coordsUniform = uniform(vec2(new Vector2));

    constructor(
        renderingManager: RenderingManager,
        camera: Web3DCamera,
        container: HTMLElement,
        settingsDispatcher: SettingsDispatcher<Settings>,
    ) {
        super(renderingManager, camera, container, settingsDispatcher);

        this.pickCamera.coordinateSystem = WebGPUCoordinateSystem;
        this.pickCamera.updateProjectionMatrix();

        const geometryFX = new PlaneGeometry(2, 2);
        const materialFX = new MeshBasicNodeMaterial();
        materialFX.side = DoubleSide;
        const quad = new Mesh(geometryFX, materialFX);
        quad.scale.set(1,-1,1);
        this.pickScene.add(quad);

        const depthTexture = this.renderingManager.composer.depthTexture;
        materialFX.colorNode = texture(depthTexture, this.coordsUniform);
    }

    async pickDepth(viewPosition: Vector2): Promise<{depth: number, tolerance: number}> {
        const renderer = this.renderingManager.renderer as any; // WebGPURenderer

        // @ts-ignore
        this.coordsUniform.value.copy(viewPosition);

        renderer.setRenderTarget(this.singlePixelRT);
        renderer.clear();
        renderer.render(this.pickScene, this.pickCamera);

        const buffer = await renderer.readRenderTargetPixelsAsync(this.singlePixelRT, 0, 0, 1, 1);
        const depth = buffer[0];
        return {depth: depth === 1 ? undefined : (depth + 1) / 2, tolerance: 1}; // Depth correction: WebGPU in Chrome returns depth buffer values in [-1,1]
    }
}
