import {
    Object3D,
    Plane,
    Vector3
} from "three";

export class ClipPlane extends Object3D {
    plane: Plane;
    selected: boolean;
    origin?: any;

    get coplanarPoint(): Vector3 {
        return this.position;
    }

    set coplanarPoint(value: Vector3) {
        this.position.copy(value);
    }

    setPosition(position: Vector3): void {
        this.position.copy(position);
        this.constant = -this.normal.dot(position);
    }

    get normal(): Vector3 {
        return this.plane.normal;
    }

    get constant(): number {
        return this.plane.constant;
    }

    set constant(v: number) {
        this.plane.constant = v;
    }

    distanceToPoint(point: Vector3): number {
        return this.plane.distanceToPoint(point);
    }

    constructor(
        normal: Vector3,
        position: Vector3,
    ) {
        super();
        this.plane = new Plane().setFromNormalAndCoplanarPoint(normal, position);
        this.position.copy(position);
    }

    setNormal(normal?: Vector3): void {
        this.plane.setFromNormalAndCoplanarPoint(normal, this.position);
    }
}
