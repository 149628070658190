import {Fly} from "./Fly.js";
import {InputHandler} from "../InputHandler.js";
import {Web3DCamera} from "../Rendering/Web3DCamera.js";
import {MouseButton} from "../common.js";
import {Euler, Quaternion, Vector2} from "three";
import {DirectionToSpherical} from "../Helpers/utils.js";
import {Subscription} from "rxjs";
import {Key} from "ts-key-enum";

export class Walk extends Fly {
    static override get Name(): string { return "walk"; }

    private mouseSubscription: Subscription;

    override get name(): string {
        return Walk.Name;
    }

    constructor(inputs: InputHandler, camera: Web3DCamera) {
        super(inputs, camera);
        this.keys.set(Key.ArrowUp, {axis: "z", sign: -1});
        this.keys.set(Key.ArrowDown, {axis: "z", sign: 1});
        this.keys.set(Key.ArrowLeft, {axis: "rot", sign: 1});
        this.keys.set(Key.ArrowRight, {axis: "rot", sign: -1});
        this.mouseButton = MouseButton.left;
        this.touchCount = 1;
        this.enabled = false;
    }

    async lock(): Promise<void> {
        await document.body.requestPointerLock();
    }

    private onLockChange = () => {
        if (document.pointerLockElement) {
            this.calculateRotationPoint(undefined);
            this.mouseSubscription = this.inputs.pointerMove$.subscribe((event) => {
                const e = event.originalEvent as MouseEvent;
                this.updateOriginalCamera();
                const sphericalDirection = DirectionToSpherical(this._originalDirection, this._originalUp);
                this.translateCamera(new Vector2(e.movementX, e.movementY), sphericalDirection);
                this._camera.callListeners();
            });
        }
        else {
            if (this.mouseSubscription) this.mouseSubscription.unsubscribe();
        }
    }

    override set enabled(enabled: boolean) {
        super.enabled = enabled;
        if (enabled)
            document.addEventListener("pointerlockchange", this.onLockChange);
        else
            document.removeEventListener("pointerlockchange", this.onLockChange);
    }

    protected override move(delta: number): void {
        if (this._dir.lengthSq() > 0) {
            this._speed = Math.min(this._speed + delta * this._acceleration, this._maxSpeed);
            this._fly.copy(this._dir);
            this._fly.y = 0;
            this._fly.applyQuaternion(this._camera.quaternion);
            this._fly.z = this._dir.y;
            this._fly.multiplyScalar(delta * this._speed);
            this._camera.position.add(this._fly);
            this._camera.callListeners();
        }
        const rot = (this._dir as any).rot as number;
        if (rot) {
            this._camera.quaternion.premultiply(new Quaternion().setFromEuler(new Euler(0, 0, delta * rot * 0.001)));
            this._camera.callListeners();
        }
    }
}