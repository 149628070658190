import { DirectionToSpherical } from "../Helpers/utils.js";
import {
    InputHandler,
    PointerInput,
} from "../InputHandler.js";
import { Vector3, Vector2 } from "three";
import { Observable, Subscription } from "rxjs";
import { Web3DCamera } from "../Rendering/Web3DCamera.js";
import { MouseButton } from "../common.js";
import { SinglePointerTool } from "./SinglePointerTool.js";
import {Vector3Const} from "../Helpers/common-utils.js";
import {Api} from "../Api.js";

export abstract class AbstractOrbit extends SinglePointerTool {
    protected EPS = 0.02;

    rotationSpeed = new Vector2(0.003, 0.007);
    protected _camera: Web3DCamera;
    protected _dragHandle: Subscription;
    protected dragObservable: Observable<PointerInput>;
    private _offset = new Vector3();

    protected _originalPosition: Vector3;
    protected _originalDirection: Vector3;
    protected _originalUp: Vector3;
    protected _rotationPoint: Vector3;
    protected calculatingRotationPoint: boolean;

    protected _startEvent: PointerInput;

    protected constructor(protected inputs: InputHandler, camera: Web3DCamera) {
        super();
        this.mouseButton = MouseButton.left;
        this.touchCount = 1;

        this._camera = camera;

        this.dragObservable = inputs.createDragObservable(this.observableOptions,
            e => this.downCallback(e),
            e => this.moveCallback(e),
            e => this.upCallback(e));
    }

    set enabled(enabled: boolean) {
        if (this._dragHandle) {
            this._dragHandle.unsubscribe();
            this._dragHandle = null;
        }
        if (!enabled) return;

        this._dragHandle = this.dragObservable.subscribe();
    }

    get enabled(): boolean {
        return !!this._dragHandle;
    }

    protected downCallback(event: PointerInput): PointerInput {
        this._startEvent = event;
        this._rotationPoint = undefined;
        return event;
    }

    protected async moveCallback(event: PointerInput): Promise<void> {
        if (this.calculatingRotationPoint) return;
        if (!this._rotationPoint) {
            this.calculatingRotationPoint = true;
            await this.calculateRotationPoint(event);
            this.calculatingRotationPoint = false;
        }

        const delta = new Vector2(event.screenX - this._startEvent.screenX, event.screenY - this._startEvent.screenY);
        const sphericalDirection = DirectionToSpherical(
            this._originalDirection,
            this._originalUp
        );
        this.translateCamera(delta, sphericalDirection);
        this._camera.callListeners();
    }

    protected upCallback(event: PointerInput): PointerInput {
        this._rotationPoint = undefined;
        return event;
    }

    protected async calculateRotationPoint(event: PointerInput): Promise<void> {
        this.updateOriginalCamera();
    }

    protected translateCamera(delta: Vector2, sphericalDirection: Vector2): void {
        sphericalDirection.x = Math.max(this.EPS, Math.min(sphericalDirection.x + delta.y * this.rotationSpeed.y, Math.PI - this.EPS));
        sphericalDirection.y -= delta.x * this.rotationSpeed.x;
        this._camera.rotateSpherical(this._rotationPoint, this._offset, sphericalDirection);
    }

    protected updateOriginalCamera = (() => {
        this._originalUp = new Vector3();
        const right = new Vector3();

        return () => {
            this._originalDirection = this._camera.getWorldDirection(new Vector3());
            this._originalPosition = this._camera.position.clone();

            this._originalUp.copy(Vector3Const.forward);
            right.copy(Vector3Const.right);

            this._originalUp.applyQuaternion(this._camera.quaternion);
            right.applyQuaternion(this._camera.quaternion);

            const offset = new Vector3()
                .copy(this._rotationPoint)
                .sub(this._originalPosition);
            const direction = this._originalDirection;

            this._offset.set(offset.dot(right), offset.dot(direction), offset.dot(this._originalUp));
        };
    })();
}

export class Orbit extends AbstractOrbit {
    static get Name(): string { return "orbit"; }
    useWorldCenter: boolean = false;

    get name(): string {
        return Orbit.Name;
    }

    override set enabled(enabled: boolean) {
        if (this._dragHandle) {
            this._dragHandle.unsubscribe();
            this._dragHandle = null;
            this._renderPass.scene.remove(this._pivotPoint);
        }
        if (!enabled) return;

        this._dragHandle = this.dragObservable.subscribe();

        this._pivotPoint.visible = false;
        this._renderPass.scene.add(this._pivotPoint);
    }

    override get enabled(): boolean {
       return super.enabled;
    }

    constructor(private _api: Api) {
        super(_api.inputHandler, _api.camera);
        if(!this._pivotPoint) this.createPivotPoint();
        if(!this._renderPass) this.createRenderPass(this._api.camera, this._api.renderingManager.composer);
        this.enabled = true;
    }

    protected override async calculateRotationPoint(event: PointerInput): Promise<void> {
        const screenPoint = new Vector2(this._startEvent.x, this._startEvent.y);
        let point: Vector3;
        if (!this.useWorldCenter) point = await this.inputs.picker.pickForNavigation(screenPoint);
        if (!point) point = this.inputs.picker.pickNearestModelCenter(screenPoint);
        this._rotationPoint = point ? point : new Vector3(0, 0, 0);
        if(point) this.updatePivotPoint(point);
        await super.calculateRotationPoint(event);
    }

    protected override upCallback(event: PointerInput): PointerInput {
        super.upCallback(event);
        this._pivotPoint.visible = false;
        this._api.renderingManager.redraw();
        return event;
    }
}
