import {MouseButton, Settings, SnappingTool, SnapType, Web3DEventName} from "../common.js";
import {Box3, Line3, Matrix4, Plane, Quaternion, Ray, Raycaster, Vector3} from "three";
import {addIntersection, InputHandler, PointerInput, PointerObservableOptions} from "../InputHandler.js";
import {Api} from "../Api.js";
import {ClipBox} from "../ClipBox.js";
import {mergeMap} from "rxjs/operators";
import {IIntersection} from "../Picker/IIntersection.js";
import {Tool} from "./Tool.js";
import {ClipPlaneModel} from "../ClipPlaneModel.js";
import {Cursor3D} from "../Picker/Cursor3D.js";
import {Observable, Subscription} from "rxjs";
import {screenPositionToRay} from "../Picker/Picker.js";
import {calculateIntersectionOfLines} from "../Helpers/utils.js";
import {Key} from "ts-key-enum";

interface DragConfiguration {
    snapType: SnapType;
    faces: number[]; // faces of the clip box
    guideLines: Line3[]; // guide lines for dragging
    chosenGuideLine: number; // index of the guide line that is being dragged
    dragPlane: Plane; // plane for dragging corners
}

export class ClipBoxTool  extends Tool implements SnappingTool {
    static get Name(): string { return "clipBox"; }

    private dragConfiguration: DragConfiguration = {
        snapType: undefined,
        faces: [],
        guideLines: [new Line3(), new Line3()], // guide lines for dragging faces along box axes
        chosenGuideLine: -1, // index of the guide line that is being dragged
        dragPlane: new Plane() // plane for dragging corners
    };

    private _moveSubscription: Subscription;
    private _tapsHandle: Subscription;
    private _tapsObservableOptions: PointerObservableOptions;
    private _dragObservable: Observable<PointerInput>;
    private _dragSubscription: Subscription;
    private model: ClipPlaneModel;
    private _rayCaster: Raycaster;
    private ray: Ray = new Ray();
    private intersectionPoint: Vector3 = new Vector3();
    private _cursor: Cursor3D;
    private _snapTypes: SnapType[] = [SnapType.FACE];
    private _controlsVisible: boolean = true;
    private _blockHoverSelect = false; // prevents hover selection when another tool is dragging
    private _dragging = false; // indicates if the tool is currently dragging
    private dragObjectSelected = false; // indicates if the tool is allowed to drag

    get allowedSnapTypes(): SnapType[] {
        return [SnapType.FACE];
    }

    set snapTypes(value: SnapType[]) {
        this._snapTypes = value;
        if (this.enabled) this._cursor.snapTypes = value;
    }

    get snapTypes(): SnapType[] {
        return this._snapTypes;
    }

    get name(): string {
        return ClipBoxTool.Name;
    }

    get controlsVisible(): boolean {
        return this._controlsVisible;
    }

    set controlsVisible(visible: boolean) {
        if (this.model)
            this.model.clipBox.edgesVisible = visible;
        this._controlsVisible = visible;
        this._api.renderingManager.redraw();
    }

    getClipBoxId(): number {
        return this.getModel().clipBox.id;
    }

    override get enabled(): boolean {
        return !!this._tapsHandle;
    }

    override set enabled(enabled: boolean) {
        if (this._tapsHandle) {
            this._tapsHandle.unsubscribe();
            this._tapsHandle = null;
            this._cursor.unsubscribe();
        }
        if (!enabled) return;

        // deselect clip box when tool is enabled
        this._api.selection.subtract(this.getModel().modelId, [this.getClipBoxId()]);

        this._cursor = this._api.cursor;
        this._cursor.snapTypes = this.snapTypes;
        this._cursor.subscribe();

        const taps = this._inputs.createSnappedTapObservable(this._cursor, this._tapsObservableOptions);
        this._tapsHandle = taps.subscribe(event => {
            this._addClipBox(event);
        });
    }

    constructor(
        private _inputs: InputHandler,
        private _api: Api,
        private settings: Settings
    ) {
        super();

        this.snapTypes = this.allowedSnapTypes;

        this.settings.clipPlaneCuts = false;
        this.settings.intersectingClipPlanes = false;

        this._rayCaster = new Raycaster();

        this._tapsObservableOptions = {button: MouseButton.left, touchCount: 1};

        this._dragObservable = _api.inputHandler.createDragObservable({button: MouseButton.left, touchCount: 3},
            e => this.downCallback(e),
            e => this.moveCallback(e),
            e => this.upCallback(e));

        // if another tool starts dragging prevent hover selection.
        this._api.eventDispatcher.subscribe(Web3DEventName.DragStart, e => {if (!(e.detail instanceof ClipBoxTool)) this._blockHoverSelect = true;});

        // enable hover selection when another tool stops dragging.
        this._api.eventDispatcher.subscribe(Web3DEventName.DragEnd, e => {if (!(e.detail instanceof ClipBoxTool)) this._blockHoverSelect = false;});

        this.startKeyListener();
    }

    add(position: Vector3, quaternion: Quaternion, box: Box3, origin?: any): ClipBox {
        const clipBox = this.getModel().addClipBox(position, quaternion, box, origin);
        clipBox.origin = origin;
        return clipBox;
    }

    clear(origin?: any): void {
        this._api.selection.subtract(this.getModel().modelId, [this.getClipBoxId()]);
        this.getModel().removeClipBox(origin);
        this._api.camera.callListeners();
        this._api.renderingManager.redraw(true);
    }

    startEditing(): void {
        this.startMoveSubscription();
        this._dragSubscription = this._dragObservable.subscribe();
    }

    stopEditing(): void {
        this.stopMoveSubscription();
        if (this._dragSubscription) {
            this._dragSubscription.unsubscribe();
            this._dragSubscription = null;
        }
    }

    private startMoveSubscription(): void {
        if (this._moveSubscription)
            return;
        this._moveSubscription = this._api.inputHandler.pointerMove$.pipe(mergeMap(addIntersection(this._api.inputHandler.picker, [this.model]))).subscribe(e => this.onHover(e));
    }

    private stopMoveSubscription(): void {
        if (this._moveSubscription) {
            this._moveSubscription.unsubscribe();
            this._moveSubscription = null;
        }
    }

    private onHover(event: PointerInput): void {
        if (this._blockHoverSelect)
            return;
        if (event.intersection && event.intersection.object && event.intersection.object instanceof ClipBox) {
            this.setDragConfiguration(event.intersection);
            this.dragObjectSelected = true;
        } else {
            this.clearDragConfiguration();
            this.dragObjectSelected = false;
        }

        this._api.renderingManager.redraw();
    }

    private setDragConfiguration(intersection: IIntersection): void {
        this.model.highlightBoxByIndex(intersection.index);
        if (intersection.index < 6) {
            this.dragConfiguration.snapType = SnapType.FACE;
            this.dragConfiguration.faces[0] = intersection.index;
            this.dragConfiguration.guideLines[0].set(intersection.point, intersection.point.clone().add(this.model.copyBoxPlaneNormal(intersection.index)));
            return;
        }

        if (intersection.index < 18) {
            this.dragConfiguration.snapType = SnapType.LINE;
            [this.dragConfiguration.faces[0], this.dragConfiguration.faces[1]] = this.model.clipBox.getFaceIndicesFromLine(intersection.index);
            this.dragConfiguration.guideLines[0].set(intersection.point, intersection.point.clone().add(this.model.copyBoxPlaneNormal(this.dragConfiguration.faces[0])));
            this.dragConfiguration.guideLines[1].set(intersection.point, intersection.point.clone().add(this.model.copyBoxPlaneNormal(this.dragConfiguration.faces[1])));
            return;
        }

        if (intersection.index < 26) {
            this.dragConfiguration.snapType = SnapType.POINT;
            const faceIndices = this.model.clipBox.getCornerToFaces((intersection.index));
                let dragPlaneIndex = -1;
                for (let i = 0; i < faceIndices.length; i++) {
                    const normalZ = this.model.copyBoxPlaneNormal(faceIndices[i]).z;
                    if (Math.abs(normalZ) > 0.999) {
                        dragPlaneIndex = i;
                        this.dragConfiguration.dragPlane.set(this.model.copyBoxPlaneNormal(faceIndices[i]), this.model.copyPlaneConstant(faceIndices[i]));
                    }
                }

                if (dragPlaneIndex === -1) {
                    // find alternative plane.
                } else {
                    let count = 0;
                    for (let i = 0; i < faceIndices.length; i++) {
                        if (i !== dragPlaneIndex) {
                            this.dragConfiguration.faces[count] = faceIndices[i];
                            count++;
                        }
                    }
                }
            return;
        }

    }

    private clearDragConfiguration(): void {
        this.dragConfiguration.snapType = undefined;
        this.model.highlightBoxByIndex(-1);
    }

    protected downCallback(event: PointerInput): PointerInput {
        if (this.dragObjectSelected === false)
            return event;

        if (this.dragConfiguration.snapType !== undefined) {
            this._api.eventDispatcher.dragStart(this);
            this._dragging = true;
            this.updateLimitingBoundingBox(); // sets limits for how far box can be dragged based on the world bounding box of all models
            this.stopMoveSubscription();
        }
        return event;
    }

    protected async moveCallback(event: PointerInput): Promise<void> {
        if (!this._dragging)
            return;

        if (this.dragConfiguration.snapType === SnapType.FACE) {
            this.faceDrag(event);
        } else if (this.dragConfiguration.snapType === SnapType.LINE) {
            this.edgeDrag(event);
        } else if (this.dragConfiguration.snapType === SnapType.POINT) {
            this.cornerDrag(event);
        }
    }

    protected upCallback(event: PointerInput): PointerInput {
        this.startMoveSubscription();
        this._dragging = false;
        this.dragConfiguration.chosenGuideLine = -1;

        this._api.eventDispatcher.dragEnd(this);
        return event;
    }

    private updateLimitingBoundingBox(): void {
        this.model.updateLimitingPointsFromBoundingBox(this.getWorldBoundingBox());
    }

    private getWorldBoundingBox(): Box3 {
        const boundingBox = new Box3();
        const models = this._api.models.getModels();
        for (const m of models) {
            if (m !== this.model)
                boundingBox.union(m.getModelBoundingBox());
        }
        return boundingBox;
    }

    private faceDrag(event: PointerInput): void {
        screenPositionToRay({ x: event.x, y: event.y }, this._api.container, this._api.camera, this.ray);
        this.intersectionPoint.copy(calculateIntersectionOfLines(this.ray.origin, this.ray.origin.clone().add(this.ray.clone().direction), this.dragConfiguration.guideLines[0].start, this.dragConfiguration.guideLines[0].end)[0]);
        this.model.moveFacesToWorldPosition([this.dragConfiguration.faces[0]], [this.intersectionPoint]);
    }

    private edgeDrag(event: PointerInput): void {
        screenPositionToRay({ x: event.x, y: event.y }, this._api.container, this._api.camera, this.ray);

        // when movement starts pick the closest line to the pick ray to move along then continue moving along the same line
        if (this.dragConfiguration.chosenGuideLine === -1) {
            const [point1OnPickRay, pointOnGuideLine1] = calculateIntersectionOfLines(this.ray.origin, this.ray.origin.clone().add(this.ray.clone().direction), this.dragConfiguration.guideLines[0].start, this.dragConfiguration.guideLines[0].end);
            const [point2OnPickRay, pointOnGuideLine2] = calculateIntersectionOfLines(this.ray.origin, this.ray.origin.clone().add(this.ray.clone().direction), this.dragConfiguration.guideLines[1].start, this.dragConfiguration.guideLines[1].end);

            const distanceSq1 = point1OnPickRay.distanceToSquared(pointOnGuideLine1);
            const distanceSq2 = point2OnPickRay.distanceToSquared(pointOnGuideLine2);

            if (distanceSq1 < distanceSq2) {
                this.dragConfiguration.chosenGuideLine = 0;
                this.model.moveFacesToWorldPosition([this.dragConfiguration.faces[0]], [point1OnPickRay]);
                this.model.highlightBoxByIndex(this.dragConfiguration.faces[0]);
            } else {
                this.dragConfiguration.chosenGuideLine = 1;
                this.model.moveFacesToWorldPosition([this.dragConfiguration.faces[1]], [point2OnPickRay]);
                this.model.highlightBoxByIndex(this.dragConfiguration.faces[1]);
            }

        } else {
            this.intersectionPoint = calculateIntersectionOfLines(this.ray.origin, this.ray.origin.clone().add(this.ray.clone().direction), this.dragConfiguration.guideLines[this.dragConfiguration.chosenGuideLine].start, this.dragConfiguration.guideLines[this.dragConfiguration.chosenGuideLine].end)[1];
            this.model.moveFacesToWorldPosition([this.dragConfiguration.faces[this.dragConfiguration.chosenGuideLine]], [this.intersectionPoint]);
        }
    }

    private cornerDrag(event: PointerInput): void {
        screenPositionToRay({ x: event.x, y: event.y }, this._api.container, this._api.camera, this.ray);
        this.ray.intersectPlane(this.dragConfiguration.dragPlane, this.intersectionPoint);
        this.model.moveFacesToWorldPosition([this.dragConfiguration.faces[0], this.dragConfiguration.faces[1]], [ this.intersectionPoint, this.intersectionPoint]);
    }

    private _addClipBox(event: PointerInput): PointerInput {
        let position: Vector3;
        let normal: Vector3;

        if (this._cursor.intersection.snapLineStart) {
            position = this._cursor.intersection.point.clone();
            normal = this._cursor.intersection.snapLineEnd.clone().sub(this._cursor.intersection.snapLineStart).normalize();
            if (normal.dot(this._api.camera.position.clone().sub(position)) > 0) normal.negate();

            // align box to line normal only if line is horizontal
            if (Math.abs(normal.z) > 0.01)
                normal.set(1,0,0);
        }
        else if (this._cursor.intersection.normal) {
            position = this._cursor.intersection.point.clone().add(this._cursor.intersection.normal.clone().multiplyScalar(0.01));
            normal = this._cursor.intersection.normal.clone().negate();

            // align box to face normal only if face normal is horizontal
            if (Math.abs(normal.z) > 0.01)
                normal.set(1,0,0);
        } else {
            position = this._cursor.intersection.point.clone();
            normal = new Vector3(1, 0, 0);
        }

        const maxSize = this.clipBoxMaxSizeToFitWorldBoundingBox(position);
        const sizeFromCamera = this.clipBoxSizeFromCameraPosition(position);
        const size = Math.min(maxSize, sizeFromCamera);
        const box = new Box3(new Vector3(-size,-size,-size), new Vector3(size,size,size));

        const up = new Vector3(0,0,1);
        const xAxis = normal.clone();
        const yAxis = up.clone().cross(normal).normalize();
        const zAxis = normal.clone().cross(yAxis).normalize();

        const quaternion = new Quaternion().setFromRotationMatrix(new Matrix4().makeBasis(xAxis, yAxis, zAxis));

        this.add(position, quaternion, box, this);

        return event;
    }

    private getModel(): ClipPlaneModel {
        if (this.model) return this.model;

        this.model = this._api.models.get(ClipPlaneModel.ModelId) as ClipPlaneModel;
        if (this.model) return this.model;

        this.model = new ClipPlaneModel(this._api, this.settings);
        this._api.models.add(this.model);
        return this.model;
    }

    private clipBoxSizeFromCameraPosition(clipBoxPosition: Vector3): number {
        return 0.2 * this._api.camera.position.distanceTo(clipBoxPosition);
    }

    private clipBoxMaxSizeToFitWorldBoundingBox(position: Vector3): number {
        const wbb = this._api.models.worldBoundingBox.value;
        const x = Math.min( Math.abs(position.x - wbb.min.x) ,  Math.abs(position.x - wbb.max.x) );
        const y = Math.min( Math.abs(position.y - wbb.min.y) ,  Math.abs(position.y - wbb.max.y) );
        const z = Math.min( Math.abs(position.z - wbb.min.z) ,  Math.abs(position.z - wbb.max.z) );
        return Math.min(x, y, z) / Math.sqrt(3);
    }

    private startKeyListener(): void {
        this._api.inputHandler.keyDown$.subscribe((e: KeyboardEvent) => {
            if (e.code === Key.Delete || e.code === Key.Backspace)
                this.removeSelected();
        });
    }

    private removeSelected(): void {
        if (this.model.clipBox.selected) {
            this._api.selection.subtract(this.getModel().modelId, [this.getClipBoxId()]);
            this.getModel().removeClipBox(this);
        }
    }
}