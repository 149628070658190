import {
    Box3,
    DataTexture, FloatType,
    LinearSRGBColorSpace,
    Material,
    Matrix4, Mesh, MeshBasicMaterial, NearestFilter,
    NoToneMapping, OrthographicCamera,
    Plane, PlaneGeometry,
    PMREMGenerator, RedFormat, RenderTargetOptions, RGBAFormat, Scene, Texture, TypedArray,
    Uniform,
    Vector2, WebGLRenderTarget
} from "three";
import {Api} from "../Api.js";
import {Web3DCamera} from "./Web3DCamera.js";
import {XRManager} from "./XRManager.js";
import isMobile from "ismobilejs";
import {GeometryObject3D} from "../Model.js";
import {WebGLRenderer, WebGLRendererParameters} from "./WebGLRenderer.js";
import {WebGLPassComposer} from "./WebGLPassComposer.js";
import {iterate} from "../Helpers/common-utils.js";
import {PassComposer} from "./PassComposer.js";
import {ImageMimeType} from "../common.js";
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader.js";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader.js";
import {WebGPURenderer} from "../Three.WebGPU.js";
import {WebGPUPassComposer} from "./WebGPU/WebGPUPassComposer.js";
import {UnsignedByteType} from "three/src/constants.js";

export interface GlobalMaterialUniforms {
    viewSize: Uniform;
    pixelRatio: Uniform;
    globalOpacity: Uniform;
    globalTransparent?: boolean;
    depthPeelingEnabled: Uniform;
    peelingDepthTexture: Uniform;
    envMap: Uniform;
    xrEnabled?: boolean;
}

export class RenderingManager {
    enabled: boolean;
    composer: PassComposer;
    renderer: WebGLRenderer;
    private isIntelGPU: boolean;
    isAdrenoGPU: boolean;
    private renderRequested: boolean;
    private renderMainRequested: boolean;
    private beforeRenderListeners: Array<() => void> = [];
    private afterRenderListeners: Array<() => void> = [];
    private animationFrameListeners: Array<(timestamp: number) => void> = [];
    xr: XRManager;
    private _isMobile: any;
    private lastMainRenderTime: number;
    clippingPlanes: Plane[] = [];
    private lastContainerRect: DOMRect;
    private lastPixelRatio: number;
    private prevCameraMatrixWorld = new Matrix4();
    private isCameraMoving: boolean;

    uniforms: GlobalMaterialUniforms;

    get fullRender(): boolean {
        // rendering fully on render request idle (progressive rendering)
        return (!this.isCameraMoving || !this.renderRequested) && !this._api.settingsDispatcher.settings.continuousRendering;
    }

    private readonly _camera: Web3DCamera;
    private _boundingBox: Box3;

    constructor(private _api: Api) {
        this.uniforms = {
            viewSize: new Uniform(new Vector2(this.width, this.height)),
            pixelRatio: new Uniform(devicePixelRatio),
            globalOpacity: new Uniform(1),
            depthPeelingEnabled: new Uniform(Number(false)),
            peelingDepthTexture: new Uniform(null),
            envMap: new Uniform(null)
        };
        this.renderRequested = true;
        this.renderMainRequested = true;
        this._camera = _api.camera;
        this.composer = this.initializeRenderer();

        this._api.camera.subscribe(() => {
            if (!this.xr.isStarted)
                this._camera.fitNearAndFarPlanes(this._boundingBox);
            this.redraw();
        });
        this._api.models.worldBoundingBox.subscribe((aabb: Box3) => {
            if (!this.xr.isStarted) {
                this._boundingBox = aabb;
                this._camera.fitNearAndFarPlanes(this._boundingBox);
                this.redraw();
            }
        });
        this._api.settingsDispatcher.subscribe("darkModeMaterials", () => this.redraw());
        this._api.settingsDispatcher.subscribe("renderEdges", () => this.redraw());
        this._api.settingsDispatcher.subscribe("ssao", () => this.redraw());
        this._api.settingsDispatcher.subscribe("vertexInterpolationMaterials", () => this.redraw());
        this._api.settingsDispatcher.subscribe("globalOpacity", () => this.redraw());
        this._api.settingsDispatcher.subscribe("environmentMapUrl", () => this.updateEnvMap());
        this._api.settingsDispatcher.subscribe("environmentMapToneMapping", () => this.updateEnvMapToneMapping());
        this._api.settingsDispatcher.subscribe("environmentMapExposure", () => this.updateEnvMapToneMapping());
        this._api.settingsDispatcher.subscribe("environmentMapColorSpace", () => this.updateEnvMapToneMapping());
        this.updateEnvMap();
    }

    private parseGPUModel(driverRendererString: string): void {
        this.isIntelGPU = driverRendererString.includes("Intel");
        this.isAdrenoGPU = driverRendererString.includes("Adreno");
    }

    private updateEnvMapToneMapping(): void {
        if (!this._api.settingsDispatcher.settings.environmentMapUrl) {
            this.renderer.toneMapping = NoToneMapping;
            this.renderer.outputColorSpace = LinearSRGBColorSpace;
            return;
        }

        // if enmap is enabled, force tone mapping and encoding for all materials
        const toneMapping = this._api.settingsDispatcher.settings.environmentMapToneMapping ?? NoToneMapping;
        if (toneMapping !== this.renderer.toneMapping)
            this.traverseMaterials((o, m) => m.needsUpdate = true);
        this.renderer.toneMapping = toneMapping;
        this.renderer.outputColorSpace = this._api.settingsDispatcher.settings.environmentMapColorSpace ?? LinearSRGBColorSpace;
        this.renderer.toneMappingExposure = this._api.settingsDispatcher.settings.environmentMapExposure ?? 1;

        this.redraw();
    }

    private updateEnvMap(): void {
        this.updateEnvMapToneMapping();
        if (!this._api.settingsDispatcher.settings.environmentMapUrl) {
            this.uniforms.envMap.value = undefined;
            return;
        }

        const loader = this._api.settingsDispatcher.settings.environmentMapUrl.toLowerCase().endsWith(".exr") ? new EXRLoader(undefined) : new RGBELoader(undefined);
        loader.load(this._api.settingsDispatcher.settings.environmentMapUrl, (hdrEquirect: DataTexture) => {
            const pmremGenerator = new PMREMGenerator(this.renderer);
            pmremGenerator.compileEquirectangularShader();
            this.uniforms.envMap.value = pmremGenerator.fromEquirectangular(hdrEquirect).texture;
            hdrEquirect.dispose();
            pmremGenerator.dispose();
            this.redraw();
        });
    }

    private initIsMobile(): void {
        // @ts-ignore
        if (this._isMobile === undefined) this._isMobile = isMobile(navigator);
    }

    isMobile(): boolean {
        this.initIsMobile();
        return this._isMobile.any;
    }

    isAppleMobile(): boolean {
        this.initIsMobile();
        return this._isMobile.apple.device;
    }

    isLinearFilteringFloatSupported(): boolean {
        return this.renderer.extensions.has("OES_texture_float_linear");
    }

    isWebgpu(): boolean {
        return this.renderer instanceof WebGPURenderer;
    }

    private optimizeAssumingFlatsHaveSameFirstAndLastData(): void {
        // optimize "flat" interpolation vertex variables
        // Fixes WebKit issues https://bugs.webkit.org/show_bug.cgi?id=289601 and https://bugs.webkit.org/show_bug.cgi?id=286297
        const epv = this.renderer.getContext().getExtension('WEBGL_provoking_vertex');
        if (epv) epv.provokingVertexWEBGL(epv.FIRST_VERTEX_CONVENTION_WEBGL);
    }

    redraw(renderMain: boolean = true): void {
        this.renderRequested = true;
        if (renderMain)
            this.renderMainRequested = true;
    }

    addBeforeRenderListener(listener: () => void): void {
        this.beforeRenderListeners.push(listener);
    }

    addAfterRenderListener(listener: () => void): void {
        this.afterRenderListeners.push(listener);
    }

    private removeListener(listeners: any[], listener: any): void {
        const index = listeners.indexOf(listener);
        if (index !== -1) listeners.splice(index, 1);
    }

    removeBeforeRenderListener(listener: () => void): void {
        this.removeListener(this.beforeRenderListeners, listener);
    }

    removeAfterRenderListener(listener: () => void): void {
        this.removeListener(this.afterRenderListeners, listener);
    }

    addAnimationFrameListener(listener: (timestamp: number) => void): void {
        this.animationFrameListeners.push(listener);
    }

    removeAnimationFrameListener(listener: (timestamp: number) => void): void {
        this.removeListener(this.animationFrameListeners, listener);
    }

    start(): void {
        if (this.renderer.setAnimationLoop) {
            this.renderer.setAnimationLoop((t: number) => this.animationLoop(t));
        } else {
            const animate = (t: number): void => {
                requestAnimationFrame(animate);
                this.animationLoop(t);
            };
            requestAnimationFrame(animate);
        }
    }

    private animationLoop(timestamp: number): void {
        this.updateCanvasSize();
        this.animationFrameListeners.forEach(l => l(timestamp));
        this.isCameraMoving = !this.prevCameraMatrixWorld.equals(this._api.camera.matrixWorld);
        this.prevCameraMatrixWorld.copy(this._api.camera.matrixWorld);

        if (this.enabled) {
            if (this.renderRequested || !!this._api.settingsDispatcher.settings.continuousRendering) {
                this.render(this.renderMainRequested || !!this._api.settingsDispatcher.settings.continuousRendering);
                if (this.renderMainRequested) this.lastMainRenderTime = timestamp;
                this.renderRequested = false;
                this.renderMainRequested = false;
            }
            // full render on render request idle (progressive rendering)
            else if (this.lastMainRenderTime && timestamp - this.lastMainRenderTime > 100) {
                this.render(true);
                this.lastMainRenderTime = undefined;
            }
        }
    }

    #renderPromiseResolve: () => void;
    waitForRender(): Promise<void> {
        return new Promise<void>((resolve: () => void) => this.#renderPromiseResolve = resolve);
    }

    private render(renderMain: boolean): void {
        this.beforeRenderListeners.forEach(l => l());
        this.composer.render(renderMain);
        this.afterRenderListeners.forEach(l => l());
        if (this.#renderPromiseResolve) {
            this.#renderPromiseResolve();
            this.#renderPromiseResolve = undefined;
        }
    }

    async screenshot(size?: Vector2, type?: ImageMimeType, quality?: number): Promise<Blob> {
        if (size) {
            // first change canvas aspect ratio, but keep the original resolution to avoid small resolution rendering artifacts
            const width = this.width > this.height ? this.width : this.height / size.y * size.x;
            const height = this.width > this.height ? this.width / size.x * size.y : this.height;
            this.setCanvasSize(width, height, devicePixelRatio);
        }
        this.composer.render();
        return await new Promise((resolve: BlobCallback) => {
            if (size) {
                // resize image to requested size (aspect ratio is already correct)
                const canvas2d = document.createElement("canvas") as HTMLCanvasElement;
                canvas2d.width = size.x;
                canvas2d.height = size.y;
                const ctx = canvas2d.getContext("2d") as CanvasRenderingContext2D;
                ctx.drawImage(this.renderer.domElement, 0, 0, size.x, size.y);
                canvas2d.toBlob(resolve, type, quality);

                this.setCanvasSize(this.width, this.height, devicePixelRatio);
                this.composer.render();
            }
            else {
                this.renderer.domElement.toBlob(resolve, type, quality);
            }
        });
    }

    readColorBuffer(): Uint8Array {
        const buffer = new Uint8Array(this.width * this.height * 4);
        this.readBuffer(this.composer.colorTexture, {type: UnsignedByteType, format: RGBAFormat, minFilter: NearestFilter, magFilter: NearestFilter}, buffer);
        return buffer;
    }

    readNormalBuffer(): Float32Array {
        const buffer = new Float32Array(this.width * this.height * 4);
        this.readBuffer(this.composer.normalTexture, {type: FloatType, format: RGBAFormat, minFilter: NearestFilter, magFilter: NearestFilter}, buffer);
        return buffer;
    }

    readDepthBuffer(): Float32Array {
        const buffer = new Float32Array(this.width * this.height);
        this.readBuffer(this.composer.depthTexture, {type: FloatType, format: RedFormat, minFilter: NearestFilter, magFilter: NearestFilter}, buffer);
        return buffer;
    }

    private readBuffer(texture: Texture, rtOptions: RenderTargetOptions, buffer: TypedArray): void {
        const planeScene = new Scene();
        const planeGeo = new PlaneGeometry(2, 2);
        const planeMat = new MeshBasicMaterial({map: texture});
        const plane = new Mesh(planeGeo, planeMat);
        planeScene.add(plane);
        const ortho = new OrthographicCamera(-1, 1, 1, -1, -1, 1);
        const planeRT = new WebGLRenderTarget(this.width, this.height, rtOptions);
        this.renderer.setRenderTarget(planeRT);
        this.renderer.render(planeScene, ortho);
        this.renderer.readRenderTargetPixels(planeRT, 0, 0, this.width, this.height, buffer);
    }

    updateCanvasSize(): void {
        if (!this.enabled || this.xr.isStarted) return;

        const rect = this._api.container.getBoundingClientRect();
        if (!this.lastContainerRect || rect.width !== this.lastContainerRect.width || rect.height !== this.lastContainerRect.height || devicePixelRatio !== this.lastPixelRatio) {
            this.lastContainerRect = rect;
            this.lastPixelRatio = devicePixelRatio;
            this.setCanvasSize(this.width, this.height, devicePixelRatio);
            this._camera.callListeners();
        }
    }

    private setCanvasSize(width: number, height: number, pixelRatio: number): void {
        this.renderer.setPixelRatio(pixelRatio);
        this.uniforms.pixelRatio.value = pixelRatio;
        this.uniforms.viewSize.value.x = width;
        this.uniforms.viewSize.value.y = height;

        this._camera.aspect = width / height;
        this.composer.setSize(width, height);
        this.renderer.setSize(Math.trunc(width / pixelRatio), Math.trunc(height / pixelRatio), false);
        this._camera.updateProjectionMatrix();
    }

    initializeRenderer(): PassComposer {
        this.enabled = false;

        const canvas = this._api.container.children[0] as HTMLCanvasElement;

        if (this._api.settingsDispatcher.settings.useWebgpu) {
            this.renderer = new WebGPURenderer({
                canvas: canvas,
            }) as any as WebGLRenderer;
        }
        else {
            const contextAttributes = {
                stencil: false,
                alpha: this._api.settingsDispatcher.settings.backgroundAlpha < 1,
                powerPreference: "high-performance",
                antialias: !!(this._api.settingsDispatcher.settings.msaa ||
                    this._api.settingsDispatcher.settings.xrCompatible), // force MSAA for xr devices only, FXAA will be used for others
            } as WebGLContextAttributes;
            let context = canvas.getContext("webgl2", contextAttributes) as WebGL2RenderingContext;
            if (!context) {
                contextAttributes.powerPreference = undefined; // linux + nvidia (525) + chrome (109) fail to create high-performance context
                context = canvas.getContext("webgl2", contextAttributes) as WebGL2RenderingContext;
                if (!context) throw new Error("Browser does not support WebGL2");
            }

            const params = {canvas: canvas, context: context} as WebGLRendererParameters;
            this.renderer = new WebGLRenderer(params);

            this.optimizeAssumingFlatsHaveSameFirstAndLastData();
            const gl = this.renderer.getContext();
            const driverRendererString = gl.getParameter(gl.getExtension("WEBGL_debug_renderer_info").UNMASKED_RENDERER_WEBGL);
            this.parseGPUModel(driverRendererString);
            // eslint-disable-next-line
            console.log(`Rendering: ${gl.getParameter(gl.VERSION)}, ${gl.getParameter(gl.SHADING_LANGUAGE_VERSION)}, ${gl.getParameter(gl.VENDOR)}, ${driverRendererString}`);
        }
        this.enabled = true;
        this.xr = new XRManager(this._api);
        return this.isWebgpu() ? new WebGPUPassComposer(this._api, this) as any : new WebGLPassComposer(this._api, this);
    }

    traverseMaterials(callback: (o: GeometryObject3D, m: Material) => void): void {
        this._api.scene.traverse(o => {
            const obj = o as GeometryObject3D;
            if (obj.material)
                iterate(obj.material, m => callback(obj, m));
        });
    }

    private get viewportScale(): number {
        // @ts-ignore
        return window.visualViewport ? window.visualViewport.scale : 1;
    }

    get width(): number {
        if (this.xr && this.xr.isStarted) return this.xr.textureWidth;
        return Math.trunc(this._api.container.clientWidth * devicePixelRatio * this.viewportScale);
    }

    get height(): number {
        if (this.xr && this.xr.isStarted) return this.xr.textureHeight;
        return Math.trunc(this._api.container.clientHeight * devicePixelRatio * this.viewportScale);
    }

    get clientWidth(): number {
        if (this.xr && this.xr.isStarted) return this.xr.textureWidth;
        return Math.trunc(this._api.container.clientWidth * this.viewportScale);
    }

    get clientHeight(): number {
        if (this.xr && this.xr.isStarted) return this.xr.textureHeight;
        return Math.trunc(this._api.container.clientHeight * this.viewportScale);
    }

    get msaaSamples(): number {
        // Intel GPUs are blacklisted as they are very slow with RenderTarget msaa (screen msaa is still enabled in webgl context)
        // https://issues.chromium.org/issues/40576371
        return this._api.settingsDispatcher.settings.msaa && !this.isIntelGPU ? 4 : 0;
    }
}
