import { LoadOptions } from "./common.js";
import { Model } from "./Model.js";

type LoadType = (ref: any, options: LoadOptions) => Promise<Model>;

export class Registry {
    public LUT: Map<(ref: any, options: LoadOptions) => boolean, LoadType>;

    constructor() {
        this.LUT = new Map();
    }

    register(
        identifier: (ref: any, options: LoadOptions) => boolean,
        load: LoadType
    ): void {
        this.LUT.set(identifier, load);
    }
}
