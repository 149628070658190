export class ApiContainer<T> {
    [name: string]: T | ((type: new (...params: any) => T) => T);

    get<TT>(type: new (...params: any) => TT): TT {
        for (const name in this) {
            if (this.hasOwnProperty(name) && this[name] instanceof type)
                return this[name] as unknown as TT;
        }
        return undefined;
    }
}

