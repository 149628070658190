import {MouseButton, ProjectionType} from "../common.js";
import {addIntersection, PointerInput} from "../InputHandler.js";
import {Subscription} from "rxjs";
import {Api} from "../Api.js";
import {filter, mergeMap} from "rxjs/operators";
import {IIntersection} from "../Picker/IIntersection.js";
import {Matrix4, Quaternion} from "three";
import {PublicCamera} from "../PublicCamera.js";
import {Tool} from "./Tool.js";

export class CameraShortcuts extends Tool {
    static get Name(): string { return "cameraShortcuts"; }

    private doubleTapSubscription: Subscription;
    private keySubscription: Subscription;

    constructor(private api: Api, private publicCamera: PublicCamera) {
        super();
        this.enabled = true;
    }

    get name(): string {
        return CameraShortcuts.Name;
    }

    get enabled(): boolean {
        return !!this.doubleTapSubscription;
    }

    set enabled(enabled: boolean) {
        if (this.doubleTapSubscription) {
            this.doubleTapSubscription.unsubscribe();
            this.doubleTapSubscription = undefined;
            this.keySubscription.unsubscribe();
            this.keySubscription = undefined;
        }
        if (!enabled) return;

        const doubleTap = this.api.inputHandler.createDoubleTapObservable({button: MouseButton.left, touchCount: 1}).pipe(
            filter((e: PointerInput) => e.originalEvent.altKey),
            mergeMap(addIntersection(this.api.picker))
        );
        this.doubleTapSubscription = doubleTap.subscribe(event => this.animateCamera(event.intersection));
        this._subscribeKeys();
    }

    private _subscribeKeys(): void {
        this.keySubscription = this.api.inputHandler.keyDown$.subscribe((e) => {
            if (e.code === "KeyF") this.publicCamera.fitToView({models: this.api.selection.size ? this.api.selection : undefined});
            if (e.code === "KeyO") this.api.camera.setProjectionType(this.api.camera.projectionType === ProjectionType.Perspective ?
                ProjectionType.Ortho : ProjectionType.Perspective);
        });
    }

    private async animateCamera(intersection: IIntersection): Promise<void> {
        if (!intersection || !intersection.point || !intersection.normal) return;

        const position = intersection.point.clone().add(intersection.normal.clone().multiplyScalar(intersection.point.distanceTo(this.api.camera.position)));
        const quat = new Quaternion().setFromRotationMatrix(new Matrix4().lookAt(position, intersection.point, this.api.camera.up));
        await this.api.camera.animate(position, quat);
        this.api.camera.setProjectionType(ProjectionType.Ortho);
    }
}
