import {Background} from "./Background.js";
import {EdgesAndSsaoRenderPass} from "./EdgesAndSsaoRenderPass.js";
import {SelectionEffectPass} from "./SelectionEffectPass.js";
import {Api} from "../Api.js";
import {
    DepthTexture,
    RenderTarget, Texture,
} from "three";
import {Pass} from "three/examples/jsm/postprocessing/Pass.js";
import {RenderingManager} from "./RenderingManager.js";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass.js";

export abstract class PassComposer {
    protected abstract renderTarget: RenderTarget;
    protected abstract background: Background;
    protected abstract edgesAndSsaoRenderPass: EdgesAndSsaoRenderPass;
    protected abstract selectionEffectPass: SelectionEffectPass;
    protected copyPassWithDepth: ShaderPass;
    protected copyPass: Pass;
    protected constructor(protected api: Api, protected renderingManager: RenderingManager) {}

    abstract get colorTexture(): Texture;
    abstract get normalTexture(): Texture;
    abstract get depthTexture(): DepthTexture;
    isCubeBackground(): boolean {
        return this.background.isCube();
    }
    abstract render(renderMain?: boolean): void;
    abstract setSize(width: number, height: number): void;
    abstract addPassBeforeMain(pass: Pass): void;
    abstract addPassBeforeSelection(pass: Pass): void;
    abstract addPassAfterSelection(pass: Pass): void;
    abstract addPassBeforeAntialiasing(pass: Pass): void;
    abstract addPassAfterAntialiasing(pass: Pass): void;
    abstract dispose(): void;
}
