import {BufferGroup, BufferRenderer} from "./BufferRenderer.js";
import {WebGLExtensions} from "three";
import {WebGLCapabilities} from "three";
import {WebGLInfo} from "three";

// This is analog of WebGLIndexedBufferRenderer from three.js, but with multi_draw extension support
export class IndexedBufferRenderer extends BufferRenderer {
    private type: number;
    private bytesPerElement: number;

    constructor(gl: WebGL2RenderingContext, extensions: WebGLExtensions, info: WebGLInfo, capabilities: WebGLCapabilities) {
        super(gl, extensions, info, capabilities);
    }

    setIndex(value: {type: number, bytesPerElement: number}): void {
        this.type = value.type;
        this.bytesPerElement = value.bytesPerElement;
    }

    protected override calculateGroup(rangeStart: number, rangeEnd: number, group: BufferGroup): BufferGroup {
        const g = super.calculateGroup(rangeStart, rangeEnd, group);
        g.start *= this.bytesPerElement;
        return g;
    }

    protected override draw(start: number, count: number): void {
        this.gl.drawElements(this.mode, count, this.type, start);
    }

    protected override multiDraw(start: Int32Array, count: Int32Array, length: number): void {
        this.multiDrawExt.multiDrawElementsWEBGL(this.mode, count, 0, this.type, start, 0, length);
    }

    protected override drawInstanced(start: number, count: number, instanceCount: number): void {
        if (this.capabilities.isWebGL2)
            this.gl.drawElementsInstanced(this.mode, count, this.type, start, instanceCount);
        else
            this.extensions.get("ANGLE_instanced_arrays").drawElementsInstancedANGLE(this.mode, count, this.type, start, instanceCount);
    }

    protected override multiDrawInstanced(start: Int32Array, count: Int32Array, instanceCounts: Int32Array, length: number): void {
        this.multiDrawExt.multiDrawElementsInstancedWEBGL(this.mode, count, 0, this.type, start, 0, instanceCounts, 0, length);
    }
}