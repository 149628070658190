import {Api} from "../Api.js";
import {RenderingManager} from "./RenderingManager.js";
import {Material, RenderTarget, ShaderMaterial, WebGLRenderer, WebGLRenderTarget} from "three";
import {Web3DMeshMaterial} from "./Web3DMeshMaterial.js";
import {EdgesAndSsaoRenderPass} from "./EdgesAndSsaoRenderPass.js";

// language=GLSL
const vertexShader = `
    varying vec2 vUv;
    varying vec2 vOffsetUvs[4];
    uniform vec2 texelSize;

    void main() {
        vUv = uv;
        vOffsetUvs[0] = vec2(texelSize.x, 0);
        vOffsetUvs[1] = vec2(-texelSize.x, 0);
        vOffsetUvs[2] = vec2(0, texelSize.y);
        vOffsetUvs[3] = vec2(0, -texelSize.y);
        gl_Position = vec4(position.xy, 0.0, 1.0);
    }
`;

// language=GLSL
const fragmentShaderCommon = `
    varying vec2 vUv;
    varying vec2 vOffsetUvs[4];
    uniform highp sampler2D normalBuffer;
    uniform highp sampler2D depthBuffer;
    uniform vec2 cameraNearFar;
    uniform mat4 uProjectionInverse;

    // Camera relative position
    vec3 computePosition(vec2 coord, float depthSample) {
        // normalized device coordinates
        vec4 ndc = vec4((vec3(coord, depthSample) - 0.5) * 2.0, 1.0);
        vec4 clip = uProjectionInverse * ndc;
        return clip.xyz / clip.w;
    }

    vec3 getNormal(vec4 normalSample) {
        // treat background as surface with normal towards camera
        return normalSample.xyz == vec3(0) ? vec3(0.0, 0.0, 1.0) : normalSample.xyz;
    }
`;

export class WebGLEdgesAndSsaoRenderPass extends EdgesAndSsaoRenderPass {

    constructor(api: Api, renderingManager: RenderingManager) {
        super(api, renderingManager);
    }

    createEdgesMaterial(): ShaderMaterial {
        const mat = new ShaderMaterial({
            uniforms: {
                inputBuffer: { value: this.colorTexture },
                normalBuffer: { value: this.normalTexture },
                idAndCutsBuffer: { value: this.idAndCutsTexture },
                depthBuffer: { value: this.depthTexture },
                ssaoBuffer: { value: this.ssaoRenderTarget.texture },
                cameraNearFar: { value: this.cameraNearFar },
                texelSize: { value: this.texelSize },
                uProjectionInverse: { value: this.api.camera.projectionMatrixInverse },
                cutColor: {value: this.api.settingsDispatcher.settings.clippingHighlightColor},
                clippingHighlight: {value: this.api.settingsDispatcher.settings.clippingHighlight}
            },
            vertexShader: vertexShader,
            // language=GLSL
            fragmentShader: `
                ${fragmentShaderCommon}

                uniform sampler2D inputBuffer;
                uniform highp sampler2D idAndCutsBuffer;
                uniform sampler2D ssaoBuffer;
                uniform vec3 cutColor;
                uniform bool clippingHighlight;

                float distancePointToPlane(vec3 planeNormal, float planeConstant, vec3 pointPos) {
                    return abs(dot(planeNormal, pointPos) + planeConstant);
                }

                void main() {
                    vec4 color = texture2D(inputBuffer, vUv);
                    vec4 normalSample = texture2D(normalBuffer, vUv);
                    vec3 normal = getNormal(normalSample);
                    vec4 idAndCutsSample = texture2D(idAndCutsBuffer, vUv);
                    float id = idAndCutsSample.r;
                    float depthSample = texture2D(depthBuffer, vUv).x;
                    float cutFactor = idAndCutsSample.b;
                    vec3 position = computePosition(vUv, depthSample);
                    float planeConstant = -dot(position, normal);
                    bool isEdge = false;
                    bool isBackground = depthSample == 1.0;
                    bool renderEdges = idAndCutsSample.g > 0.0 || isBackground;

                    for (int i = 0; i < vOffsetUvs.length(); i++) {
                        vec2 uv = vUv + vOffsetUvs[i];
                        vec4 offsetNormalSample = texture2D(normalBuffer, uv);
                        vec3 offsetNormal = getNormal(offsetNormalSample);
                        vec3 offsetPosition = computePosition(uv, texture2D(depthBuffer, uv).x);
                        vec4 offsetIdAndCutsSample = texture2D(idAndCutsBuffer, uv);
                        float offsetId = offsetIdAndCutsSample.r;
                        cutFactor = max(cutFactor, offsetIdAndCutsSample.b);

                        float normalDot = abs(dot(normal, offsetNormal));
                        // Calculating delta distance to plane, extended from picked pixel, works fine for Flat rendering 
                        // does not work well for Gouraud rendering, delta is increased by difference between interpolated normal and non-interpolated depth
                        // this gives false positive edge TODO: maybe use derivative to calculate non-interpolated normal value (only for depthDelta calculation)? normalFromDepth = normalize(cross(dFdx(position), dFdy(position))) 
                        float depthDelta = distancePointToPlane(normal, planeConstant, offsetPosition);
                        isEdge = isEdge || 
                            abs(id - offsetId) > 0.99 || // edges between touching entities 
                            normalDot < 0.9 ||           // edges on corners
                            depthDelta > 0.002 &&        // edges between parallel planes on different levels
                            abs(depthDelta / position.z) > 0.0005; // depth precision fix, removes noise
                    }

                    float occlusion = texture2D(ssaoBuffer, vUv).a;
                    color.rgb = color.rgb * (1.0 - occlusion);
                    
                    float fadeArtifacts = isBackground ? 1.0 : clamp((0.99987 - depthSample) * 10000.0, 0.0, 1.0); // fade long distance precision artifacs
                    gl_FragColor = mix(color, vec4(color.rgb * 0.7, max(color.a, 0.4)), isEdge && renderEdges ? fadeArtifacts : 0.0);

                    if (isEdge && clippingHighlight)
                        gl_FragColor = mix(gl_FragColor, vec4(cutColor, 1.0), cutFactor);
                    
                    gl_FragDepthEXT = depthSample;
                }
            `,
            transparent: true,
            depthTest: true,
            depthWrite: true,
            premultipliedAlpha: true,
        });
        this.api.settingsDispatcher.subscribe("clippingHighlight", () => {
            mat.uniforms.clippingHighlight.value = this.api.settingsDispatcher.settings.clippingHighlight;
            this.api.renderingManager.redraw();
        });
        this.api.settingsDispatcher.subscribe("clippingHighlightColor", () => {
            mat.uniforms.cutColor.value = this.api.settingsDispatcher.settings.clippingHighlightColor;
            this.api.renderingManager.redraw();
        });
        return mat;
    }

    createSsaoMaterial(simple: boolean): ShaderMaterial {
        return new ShaderMaterial({
            uniforms: {
                normalBuffer: { value: this.normalTexture },
                depthBuffer: { value: this.depthTexture },
                seed: { value: 0 },
                noiseBuffer: { value: this.noiseTexture },
                cameraNearFar: { value: this.cameraNearFar },
                texelSize: { value: this.texelSize },
                uProjectionInverse: { value: this.api.camera.projectionMatrixInverse }
            },
            defines: {
                SIMPLE: simple
            },
            vertexShader: vertexShader,
            // language=GLSL
            fragmentShader: `
                #define SIN45 0.707107

                ${fragmentShaderCommon}
                
                uniform float seed;
                uniform sampler2D noiseBuffer;

                float getOcclusion(vec3 position, vec3 normal, vec2 uv) {
                    float uBias = 0.04;
                    vec2 uAttenuation = vec2(1.0, 1.0);

                    vec3 offsetPosition = computePosition(uv, texture2D(depthBuffer, uv).x);
                    vec3 positionVec = offsetPosition - position;
                    float intensity = max(dot(normal, normalize(positionVec)) - uBias, 0.0);
                    float attenuation = 1.0 / (uAttenuation.x + uAttenuation.y * length(positionVec));
                    return intensity * attenuation;
                }

                void main() {
                    vec3 normal = getNormal(texture2D(normalBuffer, vUv));
                    float depthSample = texture2D(depthBuffer, vUv).x;
                    if (depthSample == 1.0) // background                     
                        discard;
                    
                    vec3 position = computePosition(vUv, depthSample);
                    vec2 rand = normalize(texelFetch(noiseBuffer, ivec2(mod(gl_FragCoord.xy + vec2(seed), vec2(100))), 0).xy);
                    
                    float occlusionRadius = 32.0;
                    float occlusion = 0.0;

                    int offsCount = vOffsetUvs.length();
                    #ifdef SIMPLE
                        offsCount = 2;
                    #endif
                    for (int i = 0; i < offsCount; i++) {
                        vec2 k1 = reflect(vOffsetUvs[i], rand);
                        vec2 k2 = vec2(k1.x * SIN45 - k1.y * SIN45, k1.x * SIN45 + k1.y * SIN45);
                        k1 *= occlusionRadius;
                        k2 *= occlusionRadius;
                        
                        occlusion += getOcclusion(position, normal, vUv + k1);
                        occlusion += getOcclusion(position, normal, vUv + k2 * 0.75);
                        occlusion += getOcclusion(position, normal, vUv + k1 * 0.5);
                        occlusion += getOcclusion(position, normal, vUv + k2 * 0.25);
                    }

                    #ifdef SIMPLE
                        occlusion *= 0.8;
                    #endif
                    occlusion = clamp(occlusion / float(4 * offsCount), 0.0, 1.0);
                    gl_FragColor = vec4(occlusion);
                }
            `,
            depthTest: false,
            depthWrite: false,
        });
    }

    protected renderWithEdges(renderer: WebGLRenderer, renderTarget: RenderTarget, renderSsao: boolean, shouldRenderMaterial: (m: Material) => boolean): void {
        let shouldRenderAnything = false;
        // TODO: pass shouldRenderMaterial to web3d/src/Rendering/WebGLRenderer instead of traversing, should be faster
        this.renderingManager.traverseMaterials((o, m) => {
            const srm = shouldRenderMaterial(m);
            shouldRenderAnything = shouldRenderAnything || srm;
            m.visible = !!(m as Web3DMeshMaterial).isWVMeshMaterial && srm;
        });
        if (shouldRenderAnything) { // early optimization
            // @ts-ignore
            renderer.setRenderTarget(this.multiRenderTarget);
            renderer.setClearAlpha(0);
            renderer.clear();
            renderer.render(this.api.scene, this.api.camera);

            this.cameraNearFar.set(this.api.camera.near, this.api.camera.far);

            this.renderSsao(renderer, renderSsao);
            this.edgesPass.render(renderer, renderTarget);

            this.renderingManager.traverseMaterials((o, m) => m.visible = !(m as Web3DMeshMaterial).isWVMeshMaterial && shouldRenderMaterial(m));
            renderer.setRenderTarget(renderTarget as WebGLRenderTarget);
            renderer.render(this.api.scene, this.api.camera);
        }
        this.renderingManager.traverseMaterials((o, m) => m.visible = true);
    }

    private renderSsao(renderer: WebGLRenderer, renderSsao: boolean): void {
        if (!renderSsao || !this.api.settingsDispatcher.settings.ssao) {
            // just clear the buffer
            this.edgesPass.getFullscreenMaterial().uniforms.ssaoBuffer.value = undefined;
        } else {
            const simple = !this.renderingManager.fullRender && this.api.settingsDispatcher.settings.progressiveRendering &&
                performance.now() - this.renderingManager.renderer.renderStartTime > 10; // render full ssao if we have time for it
            const rt = simple ? this.ssaoSimpleRenderTarget : this.ssaoRenderTarget;
            const pass = simple ? this.ssaoSimplePass : this.ssaoPass;

            renderer.setRenderTarget(rt as WebGLRenderTarget);
            renderer.setClearAlpha(0);
            renderer.clearColor();

            // make noise dynamic with time value change only if camera is moved
            if (!this.prevCameraMatrixWorld.equals(this.api.camera.matrixWorld))
                pass.getFullscreenMaterial().uniforms.seed.value = performance.now() % 100;
            this.prevCameraMatrixWorld.copy(this.api.camera.matrixWorld);

            pass.render(renderer, rt);
            this.edgesPass.getFullscreenMaterial().uniforms.ssaoBuffer.value = rt.texture;
        }
    }
}
