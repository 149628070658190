import {PointerInput} from "../InputHandler.js";
import {Api} from "../Api.js";
import {
    DoubleSide,
    Group,
    MeshBasicMaterial,
    Object3D,
    RingGeometry,
    Scene,
    Vector2,
    Vector3
} from "three";
import {Subscription} from "rxjs";
import {IIntersection} from "./IIntersection.js";
import {SnapType, Web3DEventName} from "../common.js";
import {LineMaterial} from "three/examples/jsm/lines/LineMaterial.js";
import {MeshLine} from "../CustomObjects/MeshLine.js";
import {
    createMeshPointIconMaterial,
    createScreenStaticSizeMesh,
    loadPointIconTexture
} from "../Helpers/utils.js";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass.js";
import {MeshLineGeometry} from "../CustomObjects/MeshLineGeometry.js";
import {MeshPoints} from "../CustomObjects/MeshPoints.js";
import {MeshPointsGeometry} from "../CustomObjects/MeshPointsGeometry.js";
import {Vector3Const} from "../Helpers/common-utils.js";

export class Cursor3D {
    private _moveSubscription: Subscription;
    private intervalId: any;
    private faceCursor: Object3D;
    private pointCursor: MeshPoints;
    private centerCursor: MeshPoints;
    private lineCursor: MeshPoints;
    private lineHighlight: MeshLine;
    private cursor: Object3D;
    private faceMesh: Object3D;
    private renderPassBeforeAA: RenderPass;
    private renderPass: RenderPass;
    private isNavigating: boolean;

    snapTypes: SnapType[] = [SnapType.CENTER_POINT, SnapType.CENTER_LINE, SnapType.POINT, SnapType.LINE, SnapType.FACE];
    intersection: IIntersection;

    private normal = new Vector3();

    private createCursors(): void {
        const pointsGeometry = new MeshPointsGeometry();
        pointsGeometry.setPositions(new Float32Array([0, 0, 0]));

        // Surface
        const markerMaterial = createMeshPointIconMaterial(this._api, loadPointIconTexture(this._api.staticRootUrl + "images/snap_face.png"), 16);
        this.faceCursor = new Group();
        const points = new MeshPoints(pointsGeometry, markerMaterial, this._api.camera, this._api.container);
        this.faceCursor.add(points);
        const material = new MeshBasicMaterial({color: 0xffffff, depthTest: false, depthWrite: false, transparent: true, opacity: 0.3, side: DoubleSide});
        const ringGeometry = new RingGeometry(1.75, 2, 32);
        ringGeometry.translate(0, 0, 0.01);
        ringGeometry.rotateZ(Math.PI / 4);
        this.faceMesh = createScreenStaticSizeMesh(ringGeometry, material, this._api);
        this.faceMesh.visible = false;

        // Point
        this.pointCursor = new MeshPoints(pointsGeometry,
            createMeshPointIconMaterial(this._api, loadPointIconTexture(this._api.staticRootUrl + "images/snap_point.png"), 16), this._api.camera, this._api.container);

        // Center point
        this.centerCursor = new MeshPoints(pointsGeometry,
            createMeshPointIconMaterial(this._api, loadPointIconTexture(this._api.staticRootUrl + "images/snap_midpoint.png"), 16), this._api.camera, this._api.container);

        // Line
        this.lineCursor = new MeshPoints(pointsGeometry,
            createMeshPointIconMaterial(this._api, loadPointIconTexture(this._api.staticRootUrl + "images/snap_edge.png"), 16), this._api.camera, this._api.container);
        this.lineHighlight = new MeshLine(new MeshLineGeometry(), new LineMaterial({linewidth: 3, color: 0xffffff, opacity: 0.3, depthTest: false, depthWrite: false, transparent: true}));
    }

    private createRenderPass(): void {
        this.renderPass = new RenderPass(new Scene(), this._api.camera);
        this.renderPass.clear = false;
        this._api.renderingManager.composer.addPassAfterAntialiasing(this.renderPass);
        this.renderPassBeforeAA = new RenderPass(new Scene(), this._api.camera);
        this.renderPassBeforeAA.clear = false;
        this._api.renderingManager.composer.addPassBeforeAntialiasing(this.renderPassBeforeAA);
    }

    constructor(private _api: Api) {
        this.createRenderPass();
        this.createCursors();
        this.cursor = this.faceCursor;
        this.initNavigationIntegration();
    }

    private initNavigationIntegration(): void {
        this._api.eventDispatcher.subscribe(Web3DEventName.NavigationStart, () => {
            this.cursor.visible = false;
            this.faceMesh.visible = false;
            this.lineHighlight.visible = false;
            this.isNavigating = true;
        });
        this._api.eventDispatcher.subscribe(Web3DEventName.NavigationEnd, () => {
            this.isNavigating = false;
        });
    }

    public subscribe(onMove?: () => void): void {
        let lastEvent: PointerInput;
        let lastCalculatedEvent: PointerInput;
        let lastEventTime: number = 0;

        this._moveSubscription = this._api.inputHandler.pointerMove$
            .subscribe(async e => {
                if (!this.isNavigating) {
                    lastEvent = e;
                    lastEventTime = performance.now();
                    // avoid flooding trimbim worker message queue
                    if (!this._api.picker.pickerBusy) {
                        lastCalculatedEvent = e;
                        await this.calculateIntersection(e);
                        if (onMove) onMove();
                    }
                }
            });

        this.intervalId = setInterval(async () => {
            // if picker was busy during last move event, recalculate
            if (!this.isNavigating && lastCalculatedEvent !== lastEvent && performance.now() > lastEventTime + 200) {
                lastCalculatedEvent = lastEvent;
                await this.calculateIntersection(lastEvent);
                if (onMove) onMove();
            }
        }, 200);

        this.faceCursor.visible = false;
        this.renderPass.scene.add(this.faceCursor);

        this.pointCursor.visible = false;
        this.renderPass.scene.add(this.pointCursor);

        this.centerCursor.visible = false;
        this.renderPass.scene.add(this.centerCursor);

        this.lineCursor.visible = false;
        this.renderPass.scene.add(this.lineCursor);

        this.renderPassBeforeAA.scene.add(this.lineHighlight);
        this.lineHighlight.visible = false;
        this.renderPassBeforeAA.scene.add(this.faceMesh);
        this.faceMesh.visible = false;

        this._api.renderingManager.redraw();
    }

    public unsubscribe(): void {
        if (!this._moveSubscription) return;

        this._moveSubscription.unsubscribe();
        this._moveSubscription = undefined;
        clearInterval(this.intervalId);
        this.renderPass.scene.remove(this.faceCursor);
        this.renderPass.scene.remove(this.pointCursor);
        this.renderPass.scene.remove(this.centerCursor);
        this.renderPass.scene.remove(this.lineCursor);
        this.renderPassBeforeAA.scene.remove(this.lineHighlight);
        this.renderPassBeforeAA.scene.remove(this.faceMesh);

        this._api.renderingManager.redraw();
    }

    async calculateIntersection(event: PointerInput): Promise<PointerInput> {
        event.intersection = await this._api.picker.pickSnapped(new Vector2(event.x, event.y), this.snapTypes);
        this.intersection = event.intersection;
        const intersection = this.intersection;

        this.cursor.visible = false;
        this.faceMesh.visible = false;
        this.lineHighlight.visible = false;
        if (intersection) {
            switch(intersection.snapType) {
                case SnapType.CENTER_POINT:
                case SnapType.CENTER_LINE:
                case SnapType.CONTROL_POINT:
                case SnapType.MIDPOINT:
                case SnapType.PERPENDICUAR_POINT:
                case SnapType.INTERSECTION_POINT:
                    this.cursor = this.centerCursor;
                    break;
                case SnapType.LINE:
                    this.cursor = this.lineCursor;
                    break;
                case SnapType.FACE:
                    this.cursor = this.faceCursor;
                    break;
                case SnapType.POINT:
                    this.cursor = this.pointCursor;
                    break;
            }

            this.cursor.position.copy(intersection.point);

            this.faceMesh.visible = !this.isNavigating && !!intersection.normal && this.cursor === this.faceCursor;
            if (this.faceMesh.visible) {
                this.normal.copy(this._api.camera.position).sub(intersection.point);
                const dot = this.normal.dot(intersection.normal);
                this.normal.copy(intersection.normal);
                if (dot < 0) this.normal.negate();
                this.faceMesh.quaternion.setFromUnitVectors(Vector3Const.down, this.normal);
                this.faceMesh.position.copy(intersection.point);
            }

            this.lineHighlight.visible = !!intersection.snapLineStart && !this.isNavigating;
            if (intersection.snapLineStart) {
                this.lineHighlight.position.copy(intersection.snapLineStart);
                this.lineHighlight.update([new Vector3(), intersection.snapLineEnd.clone().sub(intersection.snapLineStart)]);
            }

            this.cursor.updateMatrix();
            this.cursor.visible = !this.isNavigating;
        }

        this._api.renderingManager.redraw(false);

        return event;
    }
}
