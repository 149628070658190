import { defer, of, interval, animationFrameScheduler } from "rxjs";

import { map, takeWhile, concat } from "rxjs/operators";

export const msElapsed = (scheduler: any) =>
    defer(() => {
        const start = scheduler.now();
        return interval(0, scheduler).pipe(map(() => scheduler.now() - start));
    });

export const duration = (ms: number, scheduler = animationFrameScheduler as any) =>
    msElapsed(scheduler).pipe(
        map((ems: number) => ems / ms),
        takeWhile(t => t <= 1),
        concat(of(1))
    );

export const easeLinear = (t: number) => t;
export const easeInQuad = (t: number) => t * t;
export const easeOutQuad = (t: number) => 1 - (1 - t) * (1 - t);
export const easeInOutQuad = (t: number) => t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;

export const singleTween = (ms: number, easing: (t: number) => number, start: number, end: number) =>
    duration(ms).pipe(
        map(easing),
        map((t: number) => start + (end - start) * t)
    );
