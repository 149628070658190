import {Vector3} from "three";
import { MouseButton } from "../common.js";
import { InputHandler, PointerInput } from "../InputHandler.js";
import { Web3DCamera } from "../Rendering/Web3DCamera.js";
import { AbstractOrbit } from "./Orbit.js";
import { tap, pairwise } from "rxjs/operators";
import { Observable, Subscription, merge, animationFrameScheduler } from "rxjs";
import { msElapsed } from "../Animation.js";
import {Vector3Const} from "../Helpers/common-utils.js";

interface KeyDesc {
    axis: string;
    sign: number;
}

export class Fly extends AbstractOrbit {
    static get Name(): string { return "fly"; }

    protected _keyHandle: Subscription;
    protected _keyObservable: Observable<KeyboardEvent>;
    private _moveHandle: Subscription;
    private _moveObservable: Observable<any>;
    protected _dir = new Vector3();
    protected _fly = new Vector3();

    protected keys: Map<string, KeyDesc>;
    acceleration = 0.000005;
    protected _acceleration = this.acceleration;
    protected _maxSpeed = 0;
    protected _minSpeed = 0.002;
    protected _speed = this._minSpeed;

    get name(): string {
        return Fly.Name;
    }

    constructor(inputs: InputHandler, camera: Web3DCamera) {
        super(inputs, camera);
        this.keys = new Map([
            ["KeyW", {axis: "z", sign: -1}],
            ["KeyS", {axis: "z", sign: 1}],
            ["KeyA", {axis: "x", sign: -1}],
            ["KeyD", {axis: "x", sign: 1}],
            ["KeyQ", {axis: "y", sign: -1}],
            ["KeyE", {axis: "y", sign: 1}],
        ]);
        this.mouseButton = MouseButton.right;
        this.touchCount = 3;
        this._keyObservable = merge(inputs.keyDown$, inputs.keyUp$).pipe(tap(e => this.processKey(e)));
        this._moveObservable = msElapsed(animationFrameScheduler).pipe(
            pairwise(),
            tap(t => this.move(t[1] - t[0]))
        );
        this.enabled = true;
    }

    override set enabled(enabled: boolean) {
        super.enabled = enabled;
        if (this._keyHandle) this._keyHandle.unsubscribe();
        if (this._moveHandle) this._moveHandle.unsubscribe();
        if (!enabled) return;

        this._keyHandle = this._keyObservable.subscribe();
        this._moveHandle = this._moveObservable.subscribe();
    }

    protected move(delta: number): void {
        if (this._dir.lengthSq() === 0 || !this._rotationPoint) return;

        this._speed = Math.min(this._speed + delta * this._acceleration, this._maxSpeed);
        this._fly.copy(this._dir).multiplyScalar(delta * this._speed);
        this._fly.applyQuaternion(this._camera.quaternion);
        this._camera.position.add(this._fly);
        this._camera.callListeners();
    }

    protected processKey(event: KeyboardEvent): void {
        const setAcceleration = (shiftDown: boolean) => {
            this._maxSpeed = shiftDown ? this.acceleration * 4000 * 5 : this.acceleration * 4000;
            this._acceleration = shiftDown ? this.acceleration * 20 : this.acceleration;
        };
        if (this.keys.has(event.code)) {
            const desc = this.keys.get(event.code);
            if (event.type === "keydown") {
                (this._dir as any)[desc.axis] = desc.sign;
            }
            else {
                if ((this._dir as any)[desc.axis] === desc.sign)
                    (this._dir as any)[desc.axis] = 0;
            }
            setAcceleration(event.shiftKey);
            if (this._dir.equals(Vector3Const.zero)) this._speed = this._minSpeed;
        }
        else if (event.code === "ShiftLeft") {
            setAcceleration(event.type === "keydown");
        }
    }

    protected override async moveCallback(event: PointerInput): Promise<void> {
        // on macOS unfocused window might receive mouse events, but not keyboard events. This confuses user, so let's disable all events
        if (!document.hasFocus()) return;

        return super.moveCallback(event);
    }

    protected async pick(event: PointerInput): Promise<PointerInput> {
        return event;
    }

    protected override async calculateRotationPoint(event: PointerInput): Promise<void> {
        this._rotationPoint = this._camera.position;
        await super.calculateRotationPoint(event);
    }
}
