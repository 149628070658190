import { Pass } from "three/examples/jsm/postprocessing/Pass.js";
import {
    ShaderMaterial,
    WebGLRenderer,
    WebGLRenderTarget,
    Scene,
    OrthographicCamera,
    Camera,
    Mesh,
    BufferGeometry,
    BufferAttribute,
    RenderTarget
} from "three";

export class EffectPass extends Pass {
    private screen: Mesh;
    protected scene: Scene;
    protected camera: Camera;

    constructor(material: ShaderMaterial) {
        super();
        this.scene = new Scene();
        this.camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);
        this.setFullscreenMaterial(material);
    }

    getFullscreenMaterial(): ShaderMaterial {
        return (this.screen !== null) ? this.screen.material as ShaderMaterial : null;
    }

    setFullscreenMaterial(material: ShaderMaterial): void {
        let screen = this.screen;
        if (screen) {
            screen.material = material;
        } else {
            screen = new Mesh(getFullscreenTriangle(), material);
            screen.frustumCulled = false;

            if(!this.scene)
                this.scene = new Scene();

            this.scene.add(screen);
            this.screen = screen;
        }
    }

    override render(renderer: WebGLRenderer, writeBuffer: RenderTarget): void {
        renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer as WebGLRenderTarget);
        renderer.render(this.scene, this.camera);
    }

    override dispose(): void {
        this.screen.geometry.dispose();
    }
}

let geometry: BufferGeometry;
function getFullscreenTriangle(): BufferGeometry {
    if (!geometry) {
        const vertices = new Float32Array([-1, -1, 0, 3, -1, 0, -1, 3, 0]);
        const uvs = new Float32Array([0, 0, 2, 0, 0, 2]);
        geometry = new BufferGeometry();
        geometry.setAttribute("position", new BufferAttribute(vertices, 3));
        geometry.setAttribute("uv", new BufferAttribute(uvs, 2));
    }
    return geometry;
}

