import { Pan } from "./Pan.js";
import { Zoom } from "./Zoom.js";
import { Orbit } from "./Orbit.js";
import { Fly } from "./Fly.js";
import { XRNavigation } from "./XRNavigation.js";
import { SelectionTool } from "./SelectionTool.js";
import { Web3DEventName } from "../common.js";
import { Api } from "../Api.js";
import { AreaSelectionTool } from "./AreaSelectionTool.js";
import { Web3DCamera } from "../Rendering/Web3DCamera.js";
import {debounceTime, filter, tap} from "rxjs/operators";
import { ApiContainer } from "../ApiContainer.js";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import { PickingTool } from "./PickingTool.js";
import { GamepadNavigation } from "./GamepadNavigation.js";
import {CameraShortcuts} from "./CameraShortcuts.js";
import {PublicCamera} from "../PublicCamera.js";
import {PanoramaControls} from "./PanoramaControls.js";
import {Key} from "ts-key-enum";
import {Tool} from "./Tool.js";
import {Walk} from "./Walk.js";
import {FPSNavigation} from "./FPSNavigation.js";
import {ModelTransformTool} from "./ModelTransformTool.js";

export class ToolManager {
    tools = new ApiContainer<Tool>();
    private _activeTool: Tool;
    private _disabledTools: Tool[] = [];
    private cancelObservable: Observable<KeyboardEvent> = new Observable<KeyboardEvent>();
    private cancelSub: Subscription = new Subscription();

    constructor(private _api: Api, publicCamera: PublicCamera) {
        // Navigation tools
        this.addTool(new Orbit(this._api));
        this.addTool(new Fly(this._api.inputHandler, this._api.camera));
        this.addTool(new Walk(this._api.inputHandler, this._api.camera));
        this.addTool(new FPSNavigation(this._api));
        this.addTool(new Pan(this._api));
        this.addTool(new Zoom(this._api));
        this.addTool(new PanoramaControls(this._api));
        this.addTool(new CameraShortcuts(this._api, publicCamera));
        this.addTool(new XRNavigation(this._api));
        this.addTool(new GamepadNavigation(this._api));

        // Interaction tools
        this.addTool(new SelectionTool(this._api));
        this.addTool(new AreaSelectionTool(this._api));
        this.addTool(new PickingTool(this._api.inputHandler, this._api.cursor, this._api.eventDispatcher));
        this.addTool(new ModelTransformTool(this._api));
        this.activateDefaultTool();

        this._startEmittingNavigationEvents();
        this._subscribeToDragging();
        this._subscribeCancel();
    }

    private _subscribeCancel(): void {
        this.cancelObservable = this._api.inputHandler.keyDown$.pipe(
            filter(e => e.code === Key.Escape),
            tap(e => {
                if (this._activeTool) {
                    e.preventDefault();
                    this._activeTool.cancel();
                }
            })
        );
        this.cancelSub = this.cancelObservable.subscribe();
    }

    private _startEmittingNavigationEvents(): void {
        let navStarted = false;
        const cameraSubject = new BehaviorSubject<Web3DCamera>(this._api.camera);
        this._api.camera.subscribe(() => cameraSubject.next(this._api.camera));

        cameraSubject.pipe(
            tap(() => {
                if (!navStarted) this._api.eventDispatcher.navigation(true);
                navStarted = true;
            }),
            debounceTime(100),
            tap(() => {
                this._api.eventDispatcher.navigation(false);
                navStarted = false;
            })
        ).subscribe();
    }

    private _subscribeToDragging(): void {
        this._api.eventDispatcher.subscribe(Web3DEventName.DragStart, e => this._disableConflictingTools(e));
        this._api.eventDispatcher.subscribe(Web3DEventName.DragEnd, () => this.enableTools());
    }

    private _disableConflictingTools(e: CustomEvent<Tool>): void {
        for (const toolName in this.tools) {
            if (!this.tools.hasOwnProperty(toolName)) continue;
            const tool = this.tools[toolName] as Tool;
            if (tool === e.detail) continue;

            // disable conflicting tools
            if (tool.enabled) {
                tool.enabled = false;
                this._disabledTools.push(tool);
            }
        }
    }

    disableTools(): void {
        for (const toolName in this.tools) {
            if (!this.tools.hasOwnProperty(toolName)) continue;
            const tool = this.tools[toolName] as Tool;
            if (tool.enabled) {
                tool.enabled = false;
                this._disabledTools.push(tool);
            }
        }
    }

    enableTools(): void {
        for (const tool of this._disabledTools)
            tool.enabled = true;
        this._disabledTools.length = 0;
    }

    addTool(tool: Tool): void {
        if (this.tools.hasOwnProperty(tool.name)) throw new Error(`Tool ${tool.name} already exists`);
        this.tools[tool.name] = tool;
    }

    activateDefaultTool(): void {
        this.activeTool = "selection";
    }

    set activeTool(toolName: string) {
        if (this._activeTool) {
            this._activeTool.enabled = false;
            this._activeTool.onCancelled = undefined;
        }
        this._activeTool = this.tools[toolName] as Tool;
        if (!this._activeTool) throw new Error("Tool not found " + toolName);
        this._activeTool.enabled = true;
        this._activeTool.onCancelled = () => this.activateDefaultTool();
        this._api.eventDispatcher.dispatch(new CustomEvent(Web3DEventName.ActiveToolChanged));
    }

    get activeTool(): string {
        return this._activeTool.name;
    }
}
