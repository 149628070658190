import {Box3, Euler, Matrix4, Quaternion, Vector3} from "three";
import {Web3DCamera} from "./Rendering/Web3DCamera.js";
import {ProjectionType, Settings, ViewPreset} from "./common.js";
import {Models} from "./Models.js";
import {SettingsDispatcher} from "./SettingsDispatcher.js";

export { easeLinear, easeInOutQuad, easeInQuad, easeOutQuad } from "./Animation.js";

export interface Fit {
    // modelId -> entityIds
    models?: Map<string, number[]>;
    box?: Box3;
    viewPreset?: ViewPreset;
    tightness?: number;
}

/** @deprecated use Quaternion instead */
export interface TrimbleRotation {
    pitch: number;
    yaw: number;
}

export class PublicCamera {
    #rotation: TrimbleRotation = {pitch: 0, yaw: 0};
    #direction = new Vector3();
    #tmpVec3 = new Vector3();
    #tmpQuat= new Quaternion();
    #tmpEuler= new Euler();

    #camera: Web3DCamera;
    #models: Models;
    #settingsHandler: SettingsDispatcher<Settings>;

    constructor(camera: Web3DCamera, models: Models, settingsHandler: SettingsDispatcher<Settings>) {
        this.#camera = camera;
        this.#models = models;
        this.#settingsHandler = settingsHandler;
    }
    get models(): Models {
        return this.#models;
    }

    get position(): Vector3 {
        return this.#camera.position;
    }

    set position(p: Vector3) {
        this.#camera.position.copy(p);
        this.#camera.isInitial();
        this.#camera.callListeners();
    }

    get quaternion(): Quaternion {
        return this.#camera.quaternion;
    }

    set quaternion(q: Quaternion) {
        this.#camera.quaternion.copy(q);
        this.#camera.isInitial();
        this.#camera.callListeners();
    }

    get projectionMatrix(): Matrix4 {
        return this.#camera.projectionMatrix;
    }

    get matrixWorld(): Matrix4 {
        return this.#camera.matrixWorld;
    }

    get nearMin(): number {
        return this.#camera.nearMin;
    }

    set nearMin(nearMin: number) {
        this.#camera.nearMin = nearMin;
    }

    /**
     * @deprecated, use quaternion instead
     */
    get rotation(): TrimbleRotation {
        const forward = this.#tmpVec3.set(0, 0, -1).applyQuaternion(this.#camera.quaternion);
        this.#rotation.pitch = Math.PI - Math.atan2(Math.sqrt(forward.x*forward.x + forward.y*forward.y), forward.z);

        if (Math.abs(forward.z) > 0.9999) this.#tmpVec3.set(0, 1, 0).applyQuaternion(this.#camera.quaternion); // up
        this.#rotation.yaw = -Math.atan2(this.#tmpVec3.x, this.#tmpVec3.y);
        return this.#rotation;
    }

    /**
     * @deprecated, use quaternion instead
     */
    set rotation(r: TrimbleRotation) {
        this.quaternion = this.rotationToQuaternion(r);
        this.#camera.callListeners();
    }

    private rotationToQuaternion(r: TrimbleRotation): Quaternion {
        let yaw = r.yaw;
        if (Math.abs(r.pitch - Math.PI) < 0.001) yaw += Math.PI;
        this.#tmpEuler.set(r.pitch, 0, yaw, "YZX");
        return this.#tmpQuat.setFromEuler(this.#tmpEuler);
    }

    get direction(): Vector3 {
        return this.#camera.getWorldDirection(this.#direction);
    }

    get projectionType(): ProjectionType {
        return this.#camera.getProjectionType();
    }

    set projectionType(t: ProjectionType) {
        this.#camera.setProjectionType(t);
    }

    async setProjectionType(t: ProjectionType): Promise<void> {
        await this.#camera.setProjectionType(t);
    }

    get aspect(): number {
        return this.#camera.aspect;
    }

    // Angle of perspective view in degrees of wider aspect ratio (might be horizontal or vertical)
    get fieldOfView(): number {
        return this.#camera.fov;
    }

    set fieldOfView(fov: number) {
        this.#camera.fov = fov;
        this.#camera.callListeners();
    }

    // Orthographic view size in meters of wider aspect ratio (might be horizontal or vertical)
    get orthoSize(): number {
        return this.#camera.orthoSize;
    }

    set orthoSize(orthoSize: number) {
        this.#camera.orthoSize = orthoSize;
        this.#camera.callListeners();
    }

    async animate(position?: Vector3, quaternion?: Quaternion, rotation?: TrimbleRotation, animationTime?: number, easeFunc?: (t: number) => number): Promise<void> {
        if (rotation) quaternion = this.rotationToQuaternion(rotation);
        await this.#camera.animate(position, quaternion, animationTime, easeFunc);
    }

    /**
     * Animate camera orbitally around a point by spherical interpolation. The target camera orientation is such that
     * the camera looks at the center point, while also rolled accordingly to retain world orientation.
     * @param position The target position for the camera
     * @param center The center point around which to orbit
     * @param animationTime The time of the animation
     * @param easeFunc The easing function of the animation
     */
    async animateOrbit(position: Vector3, center: Vector3, animationTime?: number, easeFunc?: (t: number) => number): Promise<void> {
        await this.#camera.animateOrbit(position, center, animationTime, easeFunc);
    }

    async animateOrthoSize(orthoSize: number, animationTime?: number, easeFunc?: (t: number) => number): Promise<void> {
        await this.#camera.animateOrthoSize(orthoSize, animationTime, easeFunc);
    }

    /**
     * Animate camera to fit content into view
     * @param fit modelId if of string type, otherwise Fit interface describing the fit content and camera parameters
     */
    async fitToView(fit?: Fit | string): Promise<void> {
        if (typeof fit === "string")
            fit = {models: new Map([[fit, undefined]])};

        if  (fit === undefined || fit.models === undefined) {
            await this.#camera.fitToView(fit && fit.box ? fit.box : this.#models.worldBoundingBox.value, this.#settingsHandler.settings.animationTime, fit ? fit.viewPreset : undefined, fit ? fit.tightness : undefined);
            return;
        }

        const box = new Box3();
        for (const [modelId, entityIds] of fit.models) {
            const model = this.#models.get(modelId);
            if (!model) throw new Error(`No model found named ${modelId}`);
            box.union(await model.getBoundingBox(entityIds));
        }
        await this.#camera.fitToView(box, this.#settingsHandler.settings.animationTime, fit.viewPreset, fit.tightness) ;
    }
}
