import {Api} from "../Api.js";
import {Vector3} from "three";
import {TransformControls} from "./TransformControls.js";
import {Tool} from "./Tool.js";
import {Model} from "../Model.js";
import {Observable, Subscription} from "rxjs";
import {addIntersection, PointerInput} from "../InputHandler.js";
import {MouseButton} from "../common.js";
import {mergeMap} from "rxjs/operators";

export class ModelTransformTool extends Tool {
    private transformControls: TransformControls;
    tapsObservable: Observable<PointerInput>;
    tapsSubscription: Subscription;
    transformControlsDetachListener: () => void;

    get name(): string {
        return "modelTransform";
    }

    constructor(private _api: Api) {
        super();
        this.tapsObservable = this._api.inputHandler.createTapObservable({button: MouseButton.left, touchCount: 1})
            .pipe(mergeMap(addIntersection(this._api.inputHandler.picker)));
        this.transformControlsDetachListener = () => {
            if (!this.transformControls || !this.transformControls.object) return;
            // detach transform controls if object was removed by camera culling
            if (!this.transformControls.object.parent) {
                this.transformControls.detach();
                this._api.scene.remove(this.transformControls);
            }
        };
    }

    setMove(): void {
        if (this.transformControls)
            this.transformControls.setMode("translate");
    }

    setRotate(): void {
        if (this.transformControls)
            this.transformControls.setMode("rotate");
    }

    setScale(): void {
        if (this.transformControls)
            this.transformControls.setMode("scale");
    }

    setLocal(): void {
        if (this.transformControls)
            this.transformControls.setSpace("local");
    }

    setWorld(): void {
        if (this.transformControls)
            this.transformControls.setSpace("world");
    }

    get enabled(): boolean {
        return !!this.transformControls;
    }

    set enabled(enabled: boolean) {
        enabled ? this.enable() : this.disable();
    }

    private enable(): void {
        let model: Model;

        this.transformControls = new TransformControls(this._api);
        this.transformControls.addEventListener("change", () => this._api.renderingManager.redraw());
        this.transformControls.addEventListener('dragging-changed', (event) => {
            // @ts-ignore
            if (event.value) {
                this._api.eventDispatcher.dragStart(this);
            }
            else {
                this._api.eventDispatcher.dragEnd(this);
            }
        });

        this.transformControls.addEventListener("objectChange", (event) => {
            model.transformCallback();
        });

        this.tapsSubscription = this.tapsObservable.subscribe(e => {
            if (e.intersection && e.intersection.model
                && e.intersection.model.getModelBoundingBox().getSize(new Vector3()).length() < 10000) { // Do not transform large models
                model = e.intersection.model;
                this.transformControls.attach(model.root);
                if (!this.transformControls.parent) this._api.scene.add(this.transformControls);
            }
            else {
                this.transformControls.detach();
                this._api.scene.remove(this.transformControls);
            }
        });

        this._api.camera.subscribe(this.transformControlsDetachListener);
    }

    private disable(): void {
        if (!this.transformControls) return;

        this.transformControls.detach();
        this._api.scene.remove(this.transformControls);
        this.transformControls.dispose();
        this.transformControls = undefined;
        this._api.camera.unsubscribe(this.transformControlsDetachListener);

        if (this.tapsSubscription) {
            this.tapsSubscription.unsubscribe();
            this.tapsSubscription = undefined;
        }
    }
}
