import { BehaviorSubject } from "rxjs";
import { Model, SelectableModel } from "./Model.js";
import { Box3, Scene, Object3D } from "three";
import { Selection } from "./Selection.js";

export class Models {
    public worldBoundingBox: BehaviorSubject<Box3>;
    #models: Map<Model, Box3>;
    #modelContainer: Object3D;

    constructor(scene: Scene, private selection: Selection) {
        this.worldBoundingBox = new BehaviorSubject(new Box3());
        this.#models = new Map();
        this.#modelContainer = new Object3D();
        scene.add(this.#modelContainer);
        this.setupSelection(selection);
    }

    private setupSelection(selection: Selection): void {
        selection.subscribe((origin) => {
            for (const [m] of this.#models) {
                const model = m as Model & SelectableModel;
                if (!model._setSelection || origin === model) continue;

                const ids = selection.get(model.modelId);
                if (!ids || ids.length === 0)
                    model._clearSelection();
                else
                    model._setSelection(ids);
            }
        });
    }

    add(model: Model, addToScene: boolean = true): void {
        if (this.get(model.modelId))
            throw new Error("A model with the same id is already loaded. " + model.modelId);

        const recalculate = () => {
            this.#models.set(model, model.getModelBoundingBox());
            this.recalculateWorldBoundingBox();
        };
        model.subscribeToBoundingBox(recalculate);
        model.subscribeToTransform(recalculate);
        this.#models.set(model, model.getModelBoundingBox());
        if (addToScene)
            this.#modelContainer.add(model.root);
    }

    async remove(modelId: string): Promise<void> {
        const model = this.get(modelId);
        if (!model) throw new Error(`Model ${modelId} not found`);

        this.#models.delete(model);
        this.recalculateWorldBoundingBox();
        this.#modelContainer.remove(model.root);
        if (this.selection.has(modelId))
            this.selection.delete(modelId, model);
        await model.dispose();
    }

    get(modelId: string): Model {
        return Array.from(this.#models.keys()).find(m => m.modelId === modelId);
    }

    getModels<TT extends Model>(type?: new (...params: any) => TT): TT[] {
        const array = Array.from(this.#models.keys()) as TT[];
        return type === undefined ? array : array.filter(m => m instanceof type);
    }

    getBoundingBoxesIterable(): IterableIterator<Box3> {
        return this.#models.values();
    }

    private recalculateWorldBoundingBox(): void {
        this.worldBoundingBox.value.makeEmpty();
        this.#models.forEach(box => this.worldBoundingBox.value.union(box));
        this.worldBoundingBox.next(this.worldBoundingBox.value);
    }
}
