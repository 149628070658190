import {Api} from "../Api.js";
import {Vector2, Vector3} from "three";
import {Fly} from "./Fly.js";
import {MouseButton} from "../common.js";
import {DirectionToSpherical} from "../Helpers/utils.js";
import {Collision, Vector3Const} from "../Helpers/common-utils.js";
import {PointerInput} from "../InputHandler.js";
import {Observable, Subscription} from "rxjs";
import {CollidableModel, Model} from "../Model.js";

enum Control {
    MoveForward,
    MoveBack,
    MoveLeft,
    MoveRight,
    RotateUp,
    RotateDown,
    RotateLeft,
    RotateRight,
    ElevatorUp,
    ElevatorDown,
    RunModifier,
    Jump,
    Ghost,
    Crouch,
}

enum Direction {
    Forward,
    Right,
    Up,
}

// TODO: Merge with Walk tool and call it Walk?
// TODO: Make abstract class FPSCamera, s.t. Fly extends FPSCamera and FPSNavigation extends FPSCamera?
// Currently, it is reusing some of the Fly logic but overriding most, and the relationship is not very clear.
export class FPSNavigation extends Fly {
    collisionEnabled = true;
    multiJumpEnabled = true;
    teleportEnabled = true;
    crosshairEnabled = true;
    autoGhostEnabled = false;
    preventInfiniteFalling = true;
    readonly joystickInput = new Vector2();
    crosshairSize = 20;
    mouseSensitivity = 0.5;
    keyRotationSensitivity = 300;
    scrollMoveSpeed = 0.5;
    doubleTouchDragSpeed = 0.02;
    autoGhostThresholdCoefficient = 0.5;
    autoGhostActivationDelay = 400;

    // User's body proportions, in metres:
    eyeHeight = 1.6;
    bodyHeight = 1.7;
    bodyWidth = 0.6;

    // Speeds, in m/s:
    walkingSpeed = 2.5;
    runningSpeed = 5.0;
    jumpSpeed = 5.5;
    elevatorSpeed = 3.0;
    thresholdSpeed  = 0.05;

    // Accelerations, in m/s^2:
    g = 9.81;


    private readonly initialized: boolean = false;
    private _enabled = false;

    private mouseCoords = new Vector2();
    private activeControls = new Map<Control, boolean>();
    private mouseFrameDelta = new Vector2();
    private velocity = new Vector3();
    private _previousMovementSpeed = -1;
    private previousMovementSpeedSmooth = -1;
    private autoGhostStart = 0;
    private autoGhostActive = false;
    private hasGroundContact = false;
    private jumpRequested = false;
    private isOverVoid = false;
    private ghostModeActive = false;
    private frameListeners = new Array<(delta: number) => void>();
    private crosshair: HTMLElement;

    private doubleTouchDragObservable: Observable<PointerInput>;
    private doubleTouchDragHandle: Subscription;
    private doubleTouchDragCoords = new Vector2();

    private collisionId = 0;
    private collisionPending = false;
    private pendingDisplacement = new Vector3();

    /**
     * Disables the cross-origin isolation requirement. Not recommended, as it slows down navigation for a lot of
     * complex models.
     * @deprecated This flag should not be used and will be removed.
     */
    allowAsyncCollisions = false;

    debug = false; // TODO: Remove this before production

    constructor(private api: Api) {
        super(api.inputHandler, api.camera);
        this.mouseButton = MouseButton.left;
        this.touchCount = 1;
        this.initialized = true;

        this.doubleTouchDragObservable = this.inputs.createDragObservable({touchCount: 2}, this.onDoubleTouchDown, this.onDoubleTouchMove, e => {});
    }

    static override get Name(): string { return "fpsNavigation"; }
    override get name(): string { return FPSNavigation.Name; }

    override get enabled(): boolean {
        return this._enabled;
    }

    override set enabled(enabled: boolean) {
        if (!this.initialized) return; // Cannot enable tool before it has been initialized (parent class Fly enables itself upon construction)
        if (enabled === this._enabled) return;
        this._enabled = enabled;

        if (!this.allowAsyncCollisions && !crossOriginIsolated) {
            throw new Error("Website must be in a cross-origin isolated state for efficient collisions to work. See the crossOriginIsolated property: https://developer.mozilla.org/en-US/docs/Web/API/Window/crossOriginIsolated");
        }

        super.enabled = enabled;
        if (enabled) {
            document.addEventListener("pointerlockchange", this.onLockChange);
            document.addEventListener('keydown', this.captureKeyRepeats);
            this.api.container.addEventListener('mousemove', this.onMouseMove);
            this.api.container.addEventListener('wheel', this.onScroll);
            this.doubleTouchDragHandle = this.doubleTouchDragObservable.subscribe();
            this.api.renderingManager.addAnimationFrameListener(this.updateRotation);
            this.calculateRotationPoint(undefined);
            requestAnimationFrame(this.updateMovement); // Start movement loop
        } else {
            document.removeEventListener("pointerlockchange", this.onLockChange);
            document.removeEventListener('keydown', this.captureKeyRepeats);
            this.api.container.removeEventListener('mousemove', this.onMouseMove);
            this.api.container.removeEventListener('wheel', this.onScroll);
            this.doubleTouchDragHandle.unsubscribe();
            this.doubleTouchDragHandle = null;
            this.api.renderingManager.removeAnimationFrameListener(this.updateRotation);
            this.velocity.set(0, 0, 0);
            this.previousMovementSpeed = -1; // Force update
            this.collisionPending = false;
            return;
        }
    }

    private get previousMovementSpeed(): number { return this._previousMovementSpeed; }

    private set previousMovementSpeed(value: number) {
        const w = 10; // Window size for smoothening
        this._previousMovementSpeed = value;
        this.previousMovementSpeedSmooth = (w - 1) / w * this.previousMovementSpeedSmooth + 1 / w * value;
    }

    lock(): void {
        this.api.container.requestPointerLock();
    }

    addFrameListener(f: (delta: number) => void): void {
        this.frameListeners.push(f);
    }

    isFallingTooFar(): boolean {
        return this.velocity.z < -50;
    }

    centerVerticalRotation = (() => {
        const v = new Vector3();

        return (): void => {
            this.api.camera.lookAt(this.getGroundLookingDirection(v).add(this.api.camera.position));
            this.api.camera.callListeners();
        };
    })();

    private onLockChange = () => {
        if (document.pointerLockElement) {
            void this.calculateRotationPoint(undefined);
            if (this.crosshairEnabled) this.initializeCrosshair();
            this.api.container.focus();
        } else {
            if (this.crosshair) {
                this.crosshair.remove();
                this.crosshair = undefined;
            }
        }
    }

    private onMouseMove = (() => {
        return (event: MouseEvent) => {
            if (document.pointerLockElement) {
                // Disregard infeasibly large movements as outliers to prevent camera spasms (seems to occur in some browsers)
                if (Math.abs(event.movementX) > 400 || Math.abs(event.movementY) > 400) return;

                this.mouseFrameDelta.x += event.movementX;
                this.mouseFrameDelta.y += event.movementY;
            } else {
                this.mouseCoords.set(event.clientX, event.clientY);
            }
        };
    })();

    private getMouseFrameDelta = (() => {
        const res = new Vector2();
        return (): Vector2 => {
            res.copy(this.mouseFrameDelta);
            this.mouseFrameDelta.set(0,0);
            return res;
        };
    })();

    override async move(delta: number): Promise<void> { /* Do nothing */ }

    override processKey(event: KeyboardEvent): void {
        if (event.type !== "keydown" && event.type !== "keyup") return;
        const isDown = event.type === "keydown";

        let captured = true;

        switch (event.code) {
            case "KeyW": case "ArrowUp":
                this.activeControls.set(Control.MoveForward, isDown); break;
            case "KeyS": case "ArrowDown":
                this.activeControls.set(Control.MoveBack, isDown); break;
            case "KeyA":
                this.activeControls.set(Control.MoveLeft, isDown); break;
            case "KeyD":
                this.activeControls.set(Control.MoveRight, isDown); break;
            case "KeyQ": case "ArrowLeft":
                this.activeControls.set(Control.RotateLeft, isDown); break;
            case "KeyE": case "ArrowRight":
                this.activeControls.set(Control.RotateRight, isDown); break;
            case "KeyX":
                this.activeControls.set(Control.RotateUp, isDown); break;
            case "KeyZ":
                this.activeControls.set(Control.RotateDown, isDown); break;
            case "KeyR":
                this.activeControls.set(Control.ElevatorUp, isDown); break;
            case "KeyF":
                this.activeControls.set(Control.ElevatorDown, isDown); break;
            case "ShiftLeft": case "ShiftRight":
                this.activeControls.set(Control.RunModifier, isDown);
                captured = false; // Allow shift to be used in application-side shortcuts
                break;
            case "Space":
                this.activeControls.set(Control.Jump, isDown);
                if (isDown) this.jumpRequested = true;
                break;
            case "KeyG":
                this.activeControls.set(Control.Ghost, isDown); break;
            case "KeyC":
                if (isDown) this.centerVerticalRotation();
                break;
            case "KeyT":
                if (isDown) void this.teleport();
                break;
            case "KeyV":
                // Crouch is toggled:
                if (isDown) {
                    this.activeControls.set(Control.Crouch, !this.activeControls.get(Control.Crouch));
                    if (!this.activeControls.get(Control.Crouch))
                        this.api.camera.position.z += this.eyeHeight / 2; // Position correction
                    this.hasGroundContact = false; // Force physics update
                }
                break;
            default:
                captured = false;
        }

        // Capture keys that are in use by walk mode:
        if (captured) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    /**
     * Capture key repeats. Repeat events are not captured by the parent class' key event handler, so they pass through
     * and cause browser default behaviour such as scrolling down the page when space bar or arrow keys are held down.
     */
    private captureKeyRepeats(event: KeyboardEvent): void {
        if (event.repeat) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private isGhost(): boolean {
        return !this.collisionEnabled ||
            !!this.activeControls.get(Control.Ghost) ||
            !!this.activeControls.get(Control.ElevatorUp) ||
            !!this.activeControls.get(Control.ElevatorDown) ||
            this.autoGhostActive ||
            this.isOverVoid;
    }

    private getLookingDirection(target: Vector3): Vector3 {
        return target.set(0, 0, -1).applyQuaternion(this.api.camera.quaternion);
    }

    private getGroundLookingDirection(target: Vector3): Vector3 {
        this.getLookingDirection(target);
        const length = target.length();
        target.z = 0;
        return target.normalize().multiplyScalar(length);
    }

    private getNavigationVector(target: Vector3): Vector3 {
        target.set(0, 0, 0);
        if (this.activeControls.get(Control.MoveForward)) target.y += 1;
        if (this.activeControls.get(Control.MoveBack)) target.y -= 1;
        if (this.activeControls.get(Control.MoveLeft)) target.x -= 1;
        if (this.activeControls.get(Control.MoveRight)) target.x += 1;
        target.normalize();

        // Joystick vector is in continuous range [-1, 1]^2, rather than discrete like keyboard keys,
        // so can make top speed higher:
        // Joystick side-to-side movement currently applies rotation instead of movement:
        target.y += 2 * this.joystickInput.y;

        return target;
    }

    private getKeyRotationAngle = (() => {
        const angle = new Vector2();

        return (): Vector2 => {
            angle.set(0, 0);

            if (this.activeControls.get(Control.RotateLeft)) angle.x -= 1;
            if (this.activeControls.get(Control.RotateRight)) angle.x += 1;
            if (this.activeControls.get(Control.RotateUp)) angle.y -= 0.5;
            if (this.activeControls.get(Control.RotateDown)) angle.y += 0.5;

            if (this.activeControls.get(Control.RunModifier)) angle.multiplyScalar(3);
            angle.multiplyScalar(this.keyRotationSensitivity);
            return angle;
        };
    })();

    private updateRotation = (() => {
        const euclidean = new Vector2();
        const spherical = new Vector2();
        let prevTimestamp = 0;

        return (timestamp: number): void => {
            const delta = timestamp - prevTimestamp;
            prevTimestamp = timestamp;
            if (!this._enabled || timestamp === 0) return;

            // From mouse:
            euclidean.copy(this.getMouseFrameDelta()).multiplyScalar(this.mouseSensitivity);

            // From keys:
            euclidean.addScaledVector(this.getKeyRotationAngle(), delta * 0.001);

            // From joystick (side to side only)
            euclidean.x += this.joystickInput.x * this.keyRotationSensitivity * delta * 0.001;

            if (euclidean.x === 0 && euclidean.y === 0) return;

            void this.calculateRotationPoint(undefined);
            DirectionToSpherical(this._originalDirection, this._originalUp, spherical);
            this.translateCamera(euclidean, spherical);
            this.api.camera.callListeners();
        };
    })();

    private updateMovement = (() => {
        let prevTimestamp = 0;

        return (timestamp: number): void => {
            if (!this._enabled) return;
            let delta = timestamp - prevTimestamp;
            if (delta > 100) delta = 100;   // Lag spikes should not shoot the user far away or through walls

            if (this.collisionPending) this.pollCollision(delta);

            if (!this.collisionPending) {
                this.updateVelocity(delta);
                this.updatePosition(delta);
                for (const f of this.frameListeners) f(timestamp);
                prevTimestamp = timestamp;
            }

            requestAnimationFrame(this.updateMovement);
        };
    })();

    private forEachCollideableModel(fn: (model: Model & CollidableModel) => void): void {
        for (const model of this.api.models.getModels())
            if (model && (model as Model & CollidableModel).isCollidable) fn(model as Model & CollidableModel);
    }

    private pollCollision = (() => {
        const actualMovement = new Vector3();

        const collision = {
            collided: false,
            normal: new Vector3(),
            depth: 0,
        } as Collision;

        return (delta: number): void => {
            let ready = true;
            this.forEachCollideableModel(model => {
                const requestId = model.pollCollision(collision);
                if (requestId !== this.collisionId) ready = false;
            });
            if (!ready) return; // At least one collision is still pending, keep waiting

            if (this.debug) {
                performance.mark('collision-end');
                performance.measure('collision-marker',  'collision-start', 'collision-end');
            }

            // Collisions are calculated independently in parallel and reactions are applied one by one.
            // TThis is an approximation, and not mathematically correct, if multiple models collide simultaneously.
            actualMovement.copy(this.pendingDisplacement);
            this.forEachCollideableModel(model => {
                model.pollCollision(collision);
                if (collision.collided) {
                    actualMovement.addScaledVector(collision.normal, collision.depth);
                    this.hasGroundContact = collision.normal.z > 0;
                    if (this.hasGroundContact) this.velocity.z = 0;
                    else this.velocity.addScaledVector(collision.normal, -this.velocity.dot(collision.normal)); // Ceiling bounce
                }
            });
            this.previousMovementSpeed = actualMovement.length() / (0.001 * delta);
            this.api.camera.position.add(actualMovement);
            this.api.camera.callListeners();

            this.collisionPending = false;
        };
    })();

    private updateVelocity = (() => {
        const horizontalVelocity = new Vector3();
        const groundLookingDir = new Vector3();

        return (delta: number): void => {
            const playerSpeed = this.activeControls.get(Control.RunModifier) ? this.runningSpeed : this.walkingSpeed;
            this.getNavigationVector(horizontalVelocity);
            const controllerSpeed = horizontalVelocity.lengthSq();

            if (controllerSpeed === 0) {
                this.autoGhostActive = false;
                this.autoGhostStart = 0;
            }

            // Early return if no movement:
            if (controllerSpeed === 0 &&                        // No controller movement
                this.previousMovementSpeed >= 0 &&              // Negative value forces update
                this.previousMovementSpeed < this.thresholdSpeed && // Movement during previous frame was negligible
                this.hasGroundContact &&                        // User is not in the air (e.g. at top of jump)
                !this.activeControls.get(Control.Jump) &&       // User is not jumping
                !this.isGhost()                                 // Not in ghost mode
            ) {
                this.velocity.set(0, 0, 0);
                return;
            }

            this.detectAutoGhost(controllerSpeed);

            this.getGroundLookingDirection(groundLookingDir);
            const groundLookingAngle = Math.atan2(groundLookingDir.y, groundLookingDir.x) - Math.PI/2;
            horizontalVelocity.applyAxisAngle(Vector3Const.up, groundLookingAngle).multiplyScalar(playerSpeed);

            let verticalSpeed = this.velocity.z;
            if (this.shouldJump()) {
                verticalSpeed = this.jumpSpeed;
                this.hasGroundContact = false;
            }
            verticalSpeed -= this.g * 0.001 * delta;

            if (this.isGhost()) {
                verticalSpeed = 0;
                if (this.activeControls.get(Control.ElevatorUp)) verticalSpeed += this.elevatorSpeed;
                if (this.activeControls.get(Control.ElevatorDown)) verticalSpeed -= this.elevatorSpeed;
                if (this.activeControls.get(Control.RunModifier)) verticalSpeed *= 3;
                this.ghostModeActive = true;
            } else if (this.ghostModeActive) {
                // Ghost mode was just turned off, dampen vertical speed, to prevent elevator bouncing effect:
                verticalSpeed = Math.sign(verticalSpeed);
                this.ghostModeActive = false;
            }

            this.velocity.set(horizontalVelocity.x, horizontalVelocity.y, verticalSpeed);
        };
    })();

    private updatePosition = (() => {
        const position = new Vector3();
        const displacement = new Vector3();
        const cameraOffset = new Vector3();

        return (delta: number): void => {
            if (this.velocity.lengthSq() === 0) return;

            this.checkInfiniteFalling();

            const dims = this.getBodyDimensions();
            cameraOffset.set(0, 0, dims.eyeHeight);
            position.copy(this.api.camera.position).sub(cameraOffset);
            displacement.copy(this.velocity).multiplyScalar(0.001 * delta);

            this.collisionId++;
            this.pendingDisplacement.copy(displacement);
            if (!this.isGhost()) this.forEachCollideableModel(model => {
                this.collisionPending = true;
                (model as any).requestCollision(this.collisionId, position, displacement, dims.height, dims.width);
            });

            if (this.debug && this.collisionPending) performance.mark('collision-start');

            if (!this.collisionPending) { // No collision was requested above
                this.hasGroundContact = false;
                this.previousMovementSpeed = displacement.length() / (0.001 * delta);
                this.api.camera.position.add(displacement);
                this.api.camera.callListeners();
            }
        };
    })();

    private checkInfiniteFalling = (() => {
        let promisePending = false;

        return (): void => {
            if (!this.preventInfiniteFalling || promisePending) return;
            if (this.isOverVoid || this.velocity.z < -3) {
                promisePending = true;
                this.queryIsOverVoid().then(r => {
                    if (r && !this.isOverVoid) this.api.camera.position.z += 0.5;
                    this.isOverVoid = r;
                    promisePending = false;
                });
            }
        };
    })();

    private queryIsOverVoid = (() => {
        const position = new Vector3();
        const promises: Array<Promise<boolean>> = [];

        return async (): Promise<boolean> => {
            const dims = this.getBodyDimensions();
            position.copy(this.api.camera.position);
            position.z -= dims.eyeHeight - 0.5;

            promises.length = 0;
            this.forEachCollideableModel(m => {
                promises.push(m.collideRay(position, Vector3Const.down));
            });

            if (promises.length === 0) return Promise.resolve(true);
            return !(await Promise.all(promises)).reduce((a, b) => a || b);
        };
    })();

    private shouldJump(): boolean {
        const requested = this.jumpRequested;
        this.jumpRequested = false;
        if (requested && this.multiJumpEnabled) return true;
        return this.hasGroundContact && this.activeControls.get(Control.Jump);
    }

    private pan = (() => {
        const v = new Vector3();

        return (direction: Direction, distance: number): void => {
            if (direction === Direction.Forward) this.getGroundLookingDirection(v);
            else if (direction === Direction.Right) this.getGroundLookingDirection(v).cross(Vector3Const.up);
            else v.copy(Vector3Const.up);

            this.api.camera.position.add(v.multiplyScalar(distance));
            this.api.camera.callListeners();
        };
    })();

    private onScroll = (() => {
        return (e: WheelEvent): void => {
            this.pan(Direction.Forward, -0.01 * this.scrollMoveSpeed * e.deltaY);
        };
    })();

    private onDoubleTouchDown = (() => {
        return (e: PointerInput): PointerInput => {
            this.doubleTouchDragCoords.set(e.screenX, e.screenY);
            return e;
        };
    })();

    private onDoubleTouchMove = (() => {
        const d = new Vector2();

        return (e: PointerInput): void => {
            d.set(e.screenX, e.screenY).sub(this.doubleTouchDragCoords);
            this.doubleTouchDragCoords.set(e.screenX, e.screenY);

            let dir = Direction.Right;
            let sign = Math.sign(d.x);
            if (Math.abs(d.y) > Math.abs(d.x)) { // Lock to Y axis
                dir = Direction.Up;
                sign = -Math.sign(d.y);
            }

            this.pan(dir, sign * d.length() * this.doubleTouchDragSpeed);
        };
    })();

    private async teleport(): Promise<void> {
        if (!this.teleportEnabled) return;
        const clientSize = new Vector2(this.api.renderingManager.clientWidth, this.api.renderingManager.clientHeight);
        const screenCoords = document.pointerLockElement ?
            clientSize.multiplyScalar(0.5) : // Use screen center
            this.mouseCoords;                       // Use cursor location
        const intersection = await this.api.inputHandler.picker.pickSnapped(screenCoords, [0]);
        if (intersection && Vector3Const.up.dot(intersection.normal) > 0.5) {
            const dims = this.getBodyDimensions();
            this.api.camera.position.copy(intersection.point);
            this.api.camera.position.z += dims.eyeHeight;
            this.api.camera.callListeners();
        }
    }

    private getBodyDimensions = (() => {
        const res = { height: 0, width: 0, eyeHeight: 0 };

        return (): {height: number, width: number, eyeHeight: number} => {
            res.height = this.bodyHeight;
            res.width = this.bodyWidth;
            res.eyeHeight = this.eyeHeight;
            if (this.activeControls.get(Control.Crouch)) {
                res.height /= 2;
                res.width = Math.min(res.width, res.height); // Capsule cannot be too short compared to its width: The shortest possible capsule is a sphere
                res.eyeHeight /= 2;
            }
            return res;
        };
    })();

    private detectAutoGhost(controllerSpeed: number): void {
        const thresholdSpeed = this.autoGhostThresholdCoefficient *
            (this.activeControls.get(Control.RunModifier) ? this.runningSpeed : this.walkingSpeed);

        if (this.autoGhostEnabled &&
            controllerSpeed > 0.5 &&
            this.previousMovementSpeedSmooth < thresholdSpeed &&
            !this.isGhost()
        ) {
            const now = performance.now();
            if (!this.autoGhostStart) this.autoGhostStart = now;
            else {
                const delay = now - this.autoGhostStart;
                if (delay > this.autoGhostActivationDelay) {
                    this.autoGhostActive = true;
                    this.autoGhostStart = 0;
                }
            }
        } else this.autoGhostStart = 0;
    }

    private initializeCrosshair(): void {
        if (this.crosshair) return;
        this.crosshair = document.createElement('div');
        this.crosshair.innerHTML = `
            <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                <rect x="35%" width="30%" height="100%" fill="white" />
                <rect y="35%" width="100%" height="30%" fill="white" />
                <rect x="45%" y="10%" width="10%" height="80%" fill="black" />
                <rect x="10%" y="45%" width="80%" height="10%" fill="black" />
            </svg>
        `;
        this.crosshair.style.cssText = `
            position: absolute;
            width: ${ this.crosshairSize }px;
            height: ${ this.crosshairSize }px;
            top: calc(50% - 0.5 * ${ this.crosshairSize }px);
            left: calc(50% - 0.5 * ${ this.crosshairSize }px);
            opacity: 0.5;
            z-index: 10;
        `;
        this.api.container.append(this.crosshair);
    }

}
