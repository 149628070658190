export const CopyWithDepthShader = {
    name: 'CopyShader',

    uniforms: {
        'tDiffuse': { value: null as any },
        'depthTexture': { value: null as any },
    },

    // language=GLSL
    vertexShader: `
		varying vec2 vUv;

		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
		}`,

    // language=GLSL
    fragmentShader: `
		uniform sampler2D tDiffuse;
		uniform sampler2D depthTexture;
		varying vec2 vUv;

		void main() {
			gl_FragColor = texture(tDiffuse, vUv);
            gl_FragDepth = texture(depthTexture, vUv).r;
		}`
};