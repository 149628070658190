import { Api } from "./Api.js";
import { version } from "./Version.js";
import {QualityPreset} from "./common.js";

export abstract class Web3DPlugin {
    get version(): string {
        return version;
    }

    abstract get name(): string;
    abstract set api(api: Api);
}

export interface QualityAwarePlugin {
    applyQualityPreset(quality: QualityPreset): void;
}
