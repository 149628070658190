import {WebGLExtensions} from "three";
import {WebGLCapabilities} from "three";
import {WebGLInfo} from "three";

export interface BufferGroup {
    start: number;
    count: number;
}

// This is analog of WebGLBufferRenderer from three.js, but with multi_draw extension support
export class BufferRenderer {
    protected mode: GLenum;
    protected multiDrawExt: any;
    protected rangeFactor: number;
    private group: BufferGroup = {start: 0, count: 0};
    private starts = new Int32Array(10);
    private counts = new Int32Array(10);
    private instanceCounts = new Int32Array(10);

    constructor(protected gl: WebGL2RenderingContext, protected extensions: WebGLExtensions, protected info: WebGLInfo, protected capabilities: WebGLCapabilities) {
        this.multiDrawExt = gl.getExtension('WEBGL_multi_draw');
    }

    setMode(mode: GLenum): void {
        this.mode = mode;
    }

    setRangeFactor(rangeFactor: number): void {
        this.rangeFactor = rangeFactor;
    }

    protected draw(start: number, count: number): void {
        this.gl.drawArrays(this.mode, start, count);
    }

    protected multiDraw(starts: Int32Array, counts: Int32Array, length: number): void {
        this.multiDrawExt.multiDrawArraysWEBGL(this.mode, starts, 0, counts, 0, length);
    }

    protected drawInstanced(start: number, count: number, instanceCount: number): void {
        if (this.capabilities.isWebGL2)
            this.gl.drawArraysInstanced(this.mode, start, count, instanceCount);
        else
            this.extensions.get("ANGLE_instanced_arrays").drawArraysInstancedANGLE(this.mode, start, count, instanceCount);
    }

    protected multiDrawInstanced(start: Int32Array, count: Int32Array, instanceCounts: Int32Array, length: number): void {
        this.multiDrawExt.multiDrawArraysInstancedWEBGL(this.mode, start, 0, count, 0, instanceCounts, 0, length);
    }

    protected calculateGroup(rangeStart: number, rangeEnd: number, group: BufferGroup): BufferGroup {
    	const groupStart = group !== null ? group.start * this.rangeFactor : 0;
    	const groupCount = group !== null ? group.count * this.rangeFactor : Infinity;

    	this.group.start = Math.max(rangeStart, groupStart);
    	const drawEnd = Math.min(rangeEnd, groupStart + groupCount) - 1;
        this.group.count = Math.max(0, drawEnd - this.group.start + 1);
        return this.group;
    }

    private fillStartsCountsArrays(rangeStart: number, rangeEnd: number, group: BufferGroup[]): number {
        if (group.length > this.starts.length)
            this.starts = new Int32Array(group.length);
        if (group.length > this.counts.length)
            this.counts = new Int32Array(group.length);

        let totalCount = 0;
        for (let i = 0; i < group.length; i++) {
            const g = this.calculateGroup(rangeStart, rangeEnd, group[i]);
            this.starts[i] = g.start;
            this.counts[i] = g.count;
            totalCount += g.count;
        }
        return totalCount;
    }

    render(rangeStart: number, rangeEnd: number, group: BufferGroup | BufferGroup[]): void {
        if (Array.isArray(group)) {
            if (this.multiDrawExt) {
                const totalCount = this.fillStartsCountsArrays(rangeStart, rangeEnd, group);
                this.multiDraw(this.starts, this.counts, group.length);
                this.info.update(totalCount, this.mode, 1);
            }
            else {
                for (let i = 0; i < group.length; i++) {
                    const g = this.calculateGroup(rangeStart, rangeEnd, group[i]);
                    this.draw(g.start, g.count);
                    this.info.update(g.count, this.mode, 1);
                }
            }
        }
        else {
            const g = this.calculateGroup(rangeStart, rangeEnd, group);
            this.draw(g.start, g.count);
            this.info.update(g.count, this.mode, 1);
        }
    }

    renderInstances(rangeStart: number, rangeEnd: number, group: BufferGroup | BufferGroup[], instanceCount: number): void {
        if ( instanceCount === 0 ) return;

        if (Array.isArray(group)) {
            if (this.multiDrawExt) {
                const totalCount = this.fillStartsCountsArrays(rangeStart, rangeEnd, group);
                if (group.length > this.instanceCounts.length)
                    this.instanceCounts = new Int32Array(group.length);
                this.instanceCounts.fill(instanceCount);
                this.multiDrawInstanced(this.starts, this.counts, this.instanceCounts, group.length);
                this.info.update(totalCount, this.mode, instanceCount);
            }
            else {
                for (let i = 0; i < group.length; i++) {
                    const g = this.calculateGroup(rangeStart, rangeEnd, group[i]);
                    this.drawInstanced(g.start, g.count, instanceCount);
                    this.info.update(g.count, this.mode, instanceCount);
                }
            }
        }
        else {
            const g = this.calculateGroup(rangeStart, rangeEnd, group);
            this.drawInstanced(g.start, g.count, instanceCount);
            this.info.update(g.count, this.mode, instanceCount);
        }
    }
}