import { Vector2, Raycaster, Frustum } from "three";

export class Caster extends Raycaster {
    screenPosition: Vector2;
    frustum: Frustum;
    snapDistance: number;

    constructor() {
        super();
    }
}
