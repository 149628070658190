import {Texture} from "three";
import {
    MeshBasicNodeMaterial,
    wgslFn,
    uv,
    texture
} from "../../Three.WebGPU.js";

export class OutlineEffectNodeMaterial extends MeshBasicNodeMaterial {

    constructor(private maskTexture: Texture) {
        super({
            depthWrite: false,
            depthTest: false
        });

        this.updatePixelRatio();
    }

    updatePixelRatio(): void {
        const offset = Math.min(Math.round(2.5 * devicePixelRatio), 7);
        const offsetDiag = Math.min(Math.round(1.6 * devicePixelRatio), 7);

        // language=wgsl
        const colorNodeFunction = `
            fn calculateColor(uv: vec2<f32>) -> vec4<f32> {   
                var vUv: vec2<f32> = vec2(uv.x, 1.0 - uv.y);    
                var c0: vec2<f32> = textureSample(maskTexture, maskTexture_sampler, vUv, vec2(${offset}, 0)).rg;
                var c1: vec2<f32> = textureSample(maskTexture, maskTexture_sampler, vUv, vec2(-${offset}, 0)).rg;
                var c2: vec2<f32> = textureSample(maskTexture, maskTexture_sampler, vUv, vec2(0, ${offset})).rg;
                var c3: vec2<f32> = textureSample(maskTexture, maskTexture_sampler, vUv, vec2(0, -${offset})).rg;
                
                var c4: vec2<f32> = textureSample(maskTexture, maskTexture_sampler, vUv, vec2(${offsetDiag}, ${offsetDiag})).rg;
                var c5: vec2<f32> = textureSample(maskTexture, maskTexture_sampler, vUv, vec2(-${offsetDiag}, -${offsetDiag})).rg;
                var c6: vec2<f32> = textureSample(maskTexture, maskTexture_sampler, vUv, vec2(${offsetDiag}, -${offsetDiag})).rg;
                var c7: vec2<f32> = textureSample(maskTexture, maskTexture_sampler, vUv, vec2(-${offsetDiag}, ${offsetDiag})).rg;
                
                var d0: f32 = (c0.x - c1.x) * 0.5;
                var d1: f32 = (c2.x - c3.x) * 0.5;
                var d2: f32 = (c4.x - c5.x) * 0.5;
                var d3: f32 = (c6.x - c7.x) * 0.5;
                var d: f32 = length(vec2(length(vec2(d0, d1)), length(vec2(d2, d3))));
                var visibilityFactor: f32 = min(min(min(c0.y,c1.y), min(c2.y,c3.y)), min(min(c4.y,c5.y), min(c6.y,c7.y)));
                
                if (1.0 - visibilityFactor > 0.001) { return vec4(d, 0.0, 1.0, 1.0); }
                else { return vec4(0.0, d, 1.0, 1.0); }
            }
        `;

        this.colorNode = wgslFn(colorNodeFunction, [texture(this.maskTexture).label('maskTexture')])({ uv: uv() });
        this.needsUpdate = true;
    }
}
