
/**
 * Simple incremental checksum builder.
 * Currently based crc32, but could be replaced.
 * https://en.wikipedia.org/wiki/Cyclic_redundancy_check
 * https://stackoverflow.com/questions/27939882/fast-crc-algorithm
 */
export class Checksum {
    private static readonly POLY = 0x82f63b78;
    private static readonly MAX_INT32 = 2147483647;
    private static readonly MIN_INT32 = -2147483648;

    private intPlaceholder = new Int32Array(1);
    private floatPlaceholder = new Float32Array(this.intPlaceholder.buffer);
    private bytes = new Uint8Array(this.intPlaceholder.buffer);
    private crc = ~0;

    /**
     * Add a primitive to the checksum.
     * If the primitive is of an unsupported type during runtime, it is ignored
     * @param data The primitive to be added
     * @returns Itself, to allow chaining
     */
    add(data: number | boolean | string | object): Checksum {
        switch (typeof data) {
            case "number":
                if (
                    Number.isInteger(data) &&
                    data < Checksum.MAX_INT32 &&
                    data > Checksum.MIN_INT32
                )       // Can it be accurately represented by an int32?
                    this.intPlaceholder[0] = data;
                else    // If not, represent by a float32
                    this.floatPlaceholder[0] = data;
                this.compute();
                break;
            case "boolean":
                this.intPlaceholder[0] = (data ? 1 : 0);
                this.compute();
                break;
            case "string":
                for (let i=0; i<data.length; ++i) {
                    this.intPlaceholder[0] = data.codePointAt(i);
                    this.compute();
                }
                break;
            case "object":
                for (const p in data)
                    if (data.hasOwnProperty(p)) this.add((data as any)[p]);
                break;
        }
        return this;
    }

    /**
     * Gives the checksum of all data added since last clear.
     * @returns A 32-bit checksum as a number
     */
    get(): number {
        return ~this.crc;
    }

    /**
     * Resets the builder to initial state.
     * @returns Itself, to allow chaining
     */
    clear(): Checksum {
        this.crc = ~0;
        return this;
    }

    /**
     * Increments the checksum with the data currently stored in the placeholder.
     */
    private compute(): void {
        for (let i=0; i<4; ++i) {
            this.crc ^= this.bytes[i];
            for (let k = 0; k < 8; ++k)
                this.crc = this.crc & 1 ? (this.crc >> 1) ^ Checksum.POLY : this.crc >> 1;
        }
    }

}
