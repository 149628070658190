import {Model, SelectableModel} from "./Model.js";
import {IIntersection} from "./Picker/IIntersection.js";
import {Caster} from "./Picker/Caster.js";
import {ClipPlane} from "./ClipPlane.js";
import {DraggableClipPlane} from "./DraggableClipPlane.js";
import {Box3, Color, Plane, Quaternion, Scene, Texture, Vector2, Vector3} from "three";
import {Api} from "./Api.js";
import {ClipPlaneEventName, Settings} from "./common.js";
import {loadPointIconTexture} from "./Helpers/utils.js";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass.js";
import {ClipBox} from "./ClipBox.js";
import {ClipBoxTool} from "./Tools/ClipBoxTool.js";

export class ClipPlaneModel extends Model implements SelectableModel {
    static get ModelId(): string { return "clipPlane"; }
    readonly isSelectable = true;
    private readonly _scissor: Texture;
    private readonly _scissorBg: Texture;
    private _handleRenderPass: RenderPass;
    private handleOriginalWorldPositions: Map<number, Vector3> = new Map();
    private handleScreenPositions: Map<number, Vector2> = new Map();
    private readonly _clipPlanes: ClipPlane[]; // all planes that clip the scene
    constructor(
        private _api: Api,
        private settings: Settings
    ) {
        super(ClipPlaneModel.ModelId);

        this._clipPlanes = _api.renderingManager.clippingPlanes as any as ClipPlane[];
        this.createClipBoxPLanes();
        this.clipBox = new ClipBox(this._api);
        this.createLimitingPoints();

        this._scissor = loadPointIconTexture(this._api.staticRootUrl + "images/scissors.png");
        this._scissorBg = loadPointIconTexture(this._api.staticRootUrl + "images/icon_bg.png");

        this._handleRenderPass = new RenderPass(new Scene(), this._api.camera);
        this._handleRenderPass.clear = false;
        this._api.renderingManager.composer.addPassAfterSelection(this._handleRenderPass);
    }

    getClipPlanes(): DraggableClipPlane[] {
        return this._clipPlanes as DraggableClipPlane[];
    }

    getDraggableClipPlanes(): DraggableClipPlane[] {
        const draggableClipPlanes = [];
        for (const plane of this._clipPlanes)
            if (plane instanceof DraggableClipPlane)
                draggableClipPlanes.push(plane);
        return draggableClipPlanes;
    }

    areaPick(caster: Caster): Promise<IIntersection> {
        const clipPlanes: ClipPlane[] = [];
        this.root.children.forEach(cp => {
            if (caster.frustum.intersectsObject((cp as DraggableClipPlane).handle)) clipPlanes.push(cp as ClipPlane);
        });

        if (clipPlanes.length === 0) return;

        return Promise.resolve({
            model: this,
            object: clipPlanes[0],
            id: clipPlanes[0].id,
            childrenIds: clipPlanes.map(cp => cp.id),
            caster: caster
        });
    }

    async pick(caster: Caster): Promise<IIntersection> {
        // handles have priority over box
        for (const cp of this.root.children as DraggableClipPlane[]) {
            if (!(cp instanceof DraggableClipPlane && cp.controlsVisible))
                continue;
            const intersection = caster.intersectObject(cp.handle)[0];
            if (intersection) {
                const result: IIntersection = { object: cp, id: cp.id, caster: caster, model: this, pickPriority: 1 };
                return Promise.resolve(Object.assign(intersection, result));
            }
        }

        // if no handle picked try picking box
        const intersection = caster.intersectObject(this.clipBox, false)[0];
        if (intersection) {
            const result: IIntersection = { object: this.clipBox, id: this.clipBox.id, caster: caster, model: this, pickPriority: 1 };
            return Promise.resolve(Object.assign(intersection, result));
        }
    }

    _clearSelection(): void {
        this.root.children.forEach(c => {
            if (c instanceof DraggableClipPlane)
                c.deselect();
            if (c instanceof ClipBox) {
                const tool = this._api.toolManager.tools.clipBox as ClipBoxTool;
                tool.stopEditing();
                this.clipBox.deselect();
            }
        });
    }

    _setSelection(ids: number[]): void {
        this._clearSelection();

        ids.forEach(id => {
            const obj = this.root.getObjectById(id);
            if (obj instanceof DraggableClipPlane)
                (this.root.getObjectById(id) as DraggableClipPlane).select();
            if (obj instanceof ClipBox) {
                this.clipBox.select();
                const tool = this._api.toolManager.tools.clipBox as ClipBoxTool;
                tool.startEditing();
            }
        });
    }

    addDraggableClipPlane(normal: Vector3, position: Vector3, color: Color, origin?: any): DraggableClipPlane {
        const clipPlane = new DraggableClipPlane(
            position, normal,
            this._api.models.worldBoundingBox,
            this._scissor,
            this._scissorBg,
            this._api.container,
            this._api.camera,
            color || this.settings.clipPlaneColor,
            this._api,
            this._api.renderingManager,
            this._handleRenderPass.scene
        );

        clipPlane.origin = origin;

        this._clipPlanes.push(clipPlane);
        this.root.add(clipPlane);
        this._api.renderingManager.renderer.localClippingEnabled = true;
        this._api.camera.callListeners(); // update clip planes in WorkerCamera
        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Added, {detail: clipPlane}));
        this.handleOriginalWorldPositions.set(clipPlane.handle.id, clipPlane.position);

        return clipPlane;
    }

    clearDraggablePlanes(origin?: any): void {
        for (const plane of this.getDraggableClipPlanes())
            this._remove(plane as DraggableClipPlane, origin);
    }

    remove(plane: Plane, origin?: any): void {
        const clipPlane = this._clipPlanes.find(cp => cp.plane.equals(plane)) as DraggableClipPlane;
        this._remove(clipPlane, origin);
    }

    removeById(id: number, origin?: any): void {
        const clipPlane = this._clipPlanes.find(p => p.id === id) as DraggableClipPlane;
        this._remove(clipPlane, origin);
    }

    removeSelected(origin?: any): void {
        for (const cp of this.getDraggableClipPlanes())
            if (cp.selected) this._remove(cp, origin);
    }

    private _remove(clipPlane: DraggableClipPlane, origin?: any): void {
        if (clipPlane) {
            clipPlane.origin = origin;
            this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Removed, {detail: clipPlane}));
            this._clipPlanes.splice(this._clipPlanes.indexOf(clipPlane), 1);
            this.root.remove(clipPlane);
            clipPlane.dispose();
            this.handleOriginalWorldPositions.delete(clipPlane.handle.id);
            this.handleScreenPositions.delete(clipPlane.handle.id);
            this._api.selection.subtract(this.modelId, [clipPlane.id], this);

            if (this._clipPlanes.length === 0)
                this._api.renderingManager.renderer.localClippingEnabled = false;

            this._api.camera.callListeners();
        }
    }

    /******
     * ClipBox
     */

    clipBox: ClipBox;
    private readonly _clipBoxPlanes: ClipPlane[] = [];
    private _limitingPoints: Vector3[] = []; // No clip plane should be entirely outside this box.

    addClipBox(position: Vector3, quaternion: Quaternion, box: Box3, origin?: any): ClipBox {
        this.clipBox.update(position, quaternion, box);
        this._updateClipBoxPlanes();
        this._addClipBoxPlanes();

        if (!this.clipBox.parent)
            this.root.add(this.clipBox);

        this.clipBox.origin = origin;

        this._api.renderingManager.renderer.localClippingEnabled = true;
        this._api.camera.callListeners(); // update clip planes in WorkerCamera
        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Added, { detail: this.clipBox }));

        return this.clipBox;

    }

    removeClipBox(origin?: any): void {
        this.clipBox.origin = origin;
        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Removed, {detail: this.clipBox}));
        if (this.clipBox.parent)
            this.root.remove(this.clipBox);

        this._removeClipBoxPlanes();
        this._api.camera.callListeners();
        this._api.renderingManager.redraw();
    }

    highlightBoxByIndex(index: number): void {
        this.clipBox.highlightByIndex(index);
    }

    moveFacesToWorldPosition(faceIndices: number[], worldPositions: Vector3[]): void {
        if (!(faceIndices && worldPositions && faceIndices.length === worldPositions.length)) {
            console.warn("Invalid number of face indices or world positions");
            return;
        }

        for (let i = 0; i < faceIndices.length; i++) {
            if (this.isPointPositionValid(faceIndices[i], worldPositions[i])) {
                const index = faceIndices[i];
                const plane = this._clipBoxPlanes[index].plane;
                plane.setFromNormalAndCoplanarPoint(plane.normal.clone(), worldPositions[i].clone());
             } else
                console.warn("Point is outside limiting box");
        }

        const [box, position] = this.calculateBoxParametersFromPlanes();
        this.clipBox.updateFromCenterAndBox(position, box);
        this.updateBoundingBox();
        this._api.camera.callListeners();
        this._api.renderingManager.redraw();
    }
    updateLimitingPointsFromBoundingBox(box: Box3): void {
        const e = 0.2 * (Math.min(box.max.x - box.min.x, box.max.y - box.min.y, box.max.z - box.min.z));
        this._limitingPoints[0].set(box.min.x - e, box.min.y - e, box.min.z - e);
        this._limitingPoints[1].set(box.max.x + e, box.min.y - e, box.min.z - e);
        this._limitingPoints[2].set(box.min.x - e, box.max.y + e, box.min.z - e);
        this._limitingPoints[3].set(box.min.x - e, box.min.y - e, box.max.z + e);
        this._limitingPoints[4].set(box.max.x + e, box.max.y + e, box.min.z - e);
        this._limitingPoints[5].set(box.min.x - e, box.max.y + e, box.max.z + e);
        this._limitingPoints[6].set(box.max.x + e, box.min.y - e, box.max.z + e);
        this._limitingPoints[7].set(box.max.x + e, box.max.y + e, box.max.z + e);
    }

    calculateBoxParametersFromPlanes(): [Box3, Vector3] {
        const minX = this._clipBoxPlanes[1].constant * -this._clipBoxPlanes[0].normal.clone().length();
        const minY = this._clipBoxPlanes[3].constant * -this._clipBoxPlanes[2].normal.clone().length();
        const minZ = this._clipBoxPlanes[5].constant * -this._clipBoxPlanes[4].normal.clone().length();

        const maxX = this._clipBoxPlanes[0].constant * this._clipBoxPlanes[1].normal.clone().length();
        const maxY = this._clipBoxPlanes[2].constant * this._clipBoxPlanes[3].normal.clone().length();
        const maxZ = this._clipBoxPlanes[4].constant * this._clipBoxPlanes[5].normal.clone().length();

        const centre = new Vector3((maxX + minX) / 2, (maxY + minY) / 2, (maxZ + minZ) / 2);
        centre.applyQuaternion(this.clipBox.quaternion);

        return [
            new Box3(
                new Vector3(-0.5 * (maxX -minX), -0.5 * (maxY - minY), -0.5 * (maxZ - minZ)),
                new Vector3(0.5 * (maxX -minX), 0.5 * (maxY - minY), 0.5 * (maxZ - minZ))
            ),
            centre
        ];
    }

    updateBoundingBox(): void {
        this.boundingBox.value.copy(this.clipBox.box);
        this.boundingBox.value.applyMatrix4(this.clipBox.matrixWorld);
        this.boundingBox.next(this.boundingBox.value);
    }

    copyBoxPlaneNormal(index: number): Vector3 {
        return this._clipBoxPlanes[index].normal.clone();
    }

    copyPlaneConstant(index: number): number {
        return this._clipBoxPlanes[index].constant;
    }

    private createClipBoxPLanes(): void {
        const xAxis = new Vector3(1,0,0);
        const yAxis = new Vector3(0,1,0);
        const zAxis = new Vector3(0,0,1);

        this._clipBoxPlanes.push(new ClipPlane(xAxis.clone().negate(), new Vector3()));
        this._clipBoxPlanes.push(new ClipPlane(xAxis, new Vector3()));
        this._clipBoxPlanes.push(new ClipPlane(yAxis.clone().negate(), new Vector3()));
        this._clipBoxPlanes.push(new ClipPlane(yAxis, new Vector3()));
        this._clipBoxPlanes.push(new ClipPlane(zAxis.clone().negate(), new Vector3()));
        this._clipBoxPlanes.push(new ClipPlane(zAxis, new Vector3()));
    }

    private _addClipBoxPlanes(): void {
        this._removeClipBoxPlanes();
        for (const cbp of this._clipBoxPlanes)
            this._clipPlanes.push(cbp as ClipPlane);
    }

    private _removeClipBoxPlanes(): void {
        for (let i = this._clipPlanes.length - 1; i > -1 ; i--) {
            if (this._clipBoxPlanes.includes(this._clipPlanes[i]))
                this._clipPlanes.splice(i, 1);
        }
        if (this._clipPlanes.length === 0) {
            this._api.renderingManager.renderer.localClippingEnabled = false;
        }
        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Removed, {detail: this.clipBox}));
    }

    private _updateClipBoxPlanes(): void {
        const xAxis = new Vector3(1,0,0);
        const yAxis = new Vector3(0,1,0);
        const zAxis = new Vector3(0,0,1);

        const worldPos = this.clipBox.position;
        const box = this.clipBox.getBox();
        this.clipBox.matrixWorld.extractBasis(xAxis, yAxis, zAxis);

        // +X plane
        this._clipBoxPlanes[1].plane.setFromNormalAndCoplanarPoint(xAxis, worldPos.clone().add(xAxis.clone().multiplyScalar(box.min.x)));
        // -X plane
        this._clipBoxPlanes[0].plane.setFromNormalAndCoplanarPoint(xAxis.clone().negate(), worldPos.clone().add(xAxis.clone().multiplyScalar(box.max.x)));
        // +Y plane
        this._clipBoxPlanes[3].plane.setFromNormalAndCoplanarPoint(yAxis, worldPos.clone().add(yAxis.clone().multiplyScalar(box.min.y)));
        // -Y plane
        this._clipBoxPlanes[2].plane.setFromNormalAndCoplanarPoint(yAxis.clone().negate(), worldPos.clone().add(yAxis.clone().multiplyScalar(box.max.y)));
        // +Z plane
        this._clipBoxPlanes[5].plane.setFromNormalAndCoplanarPoint(zAxis, worldPos.clone().add(zAxis.clone().multiplyScalar(box.min.z)));
        // -Z plane
        this._clipBoxPlanes[4].plane.setFromNormalAndCoplanarPoint(zAxis.clone().negate(), worldPos.clone().add(zAxis.clone().multiplyScalar(box.max.z)));
    }

    private createLimitingPoints(): void {
        for (let i = 0; i < 8; i++) {
            this._limitingPoints.push(new Vector3());
        }
    }

    private isPointPositionValid(faceIndex: number, point: Vector3): boolean {
        return this.isPointOnPlaneWithinLimits(faceIndex, point) && this.isPointOnPlaneFarEnoughFromOppositePlane(faceIndex, point);
    }

    private isPointOnPlaneWithinLimits(faceIndex: number, point: Vector3): boolean {
        const plane = new Plane(); // TODO: refactor to reuse one plane
        plane.setFromNormalAndCoplanarPoint(this._clipBoxPlanes[faceIndex].plane.normal.clone(), point);

        let allOnSameSide = true;
        const side = plane.distanceToPoint(this._limitingPoints[0]);
        let shortestDistance = side;
        let closestCorner = this._limitingPoints[0];
        for (let j = 1; j < this._limitingPoints.length; j++) {
            const distance = plane.distanceToPoint(this._limitingPoints[j]);
            if (distance * side < 0) {
                allOnSameSide = false;
                break;
            }
            if (Math.abs(distance) < Math.abs(shortestDistance)) {
                shortestDistance = distance;
                closestCorner = this._limitingPoints[j];
            }
        }

        return !allOnSameSide;
    }

    private isPointOnPlaneFarEnoughFromOppositePlane(faceIndex: number, point: Vector3): boolean {
        const e = 0.1; // minimum distance between planes
        if (faceIndex % 2 === 0) {
            const oppositeFaceIndex = faceIndex + 1;
            return this._clipBoxPlanes[oppositeFaceIndex].plane.distanceToPoint(point) > e;
        } else {
            const oppositeFaceIndex = (faceIndex - 1) % 6;
            return this._clipBoxPlanes[oppositeFaceIndex].plane.distanceToPoint(point) > e;
        }
    }

}
