import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass.js";
import {FXAAShader} from "./FXAAShader.js";
import {ShaderMaterial, WebGLRenderer, WebGLRenderTarget} from "three";

export class FXAAPass extends ShaderPass {
    constructor() {
        super(FXAAShader);
    }

    override render(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget, deltaTime: number, maskActive: boolean): void {
        super.render(renderer, writeBuffer, readBuffer, deltaTime, maskActive);
    }

    override setSize(width: number, height: number): void {
        super.setSize(width, height);
        this.material.uniforms.resolution.value.x = 1 / width;
        this.material.uniforms.resolution.value.y = 1 / height;
    }
}
