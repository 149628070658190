import {Intersection, Mesh, Sphere, Vector3} from "three";
import {Caster} from "../Picker/Caster.js";
import {Web3DCamera} from "../Rendering/Web3DCamera.js";
import {Web3DMeshPointsMaterial} from "../Rendering/Web3DMeshPointsMaterial.js";
import {MeshPointsGeometry} from "./MeshPointsGeometry.js";

export class MeshPoints extends Mesh {
    protected sphere = new Sphere();
    private point = new Vector3();
    declare material: Web3DMeshPointsMaterial | Web3DMeshPointsMaterial[];
    declare geometry: MeshPointsGeometry;

    constructor(geometry: MeshPointsGeometry, material: Web3DMeshPointsMaterial | Web3DMeshPointsMaterial[], private _camera: Web3DCamera, private _container: HTMLElement) {
        super(geometry, material);
    }

    override raycast(raycaster: Caster, intersects: Intersection[]): void {
        const geometry = this.geometry;
        const matrixWorld = this.matrixWorld;

        // Checking boundingSphere distance to ray
        if (geometry.boundingSphere === null) geometry.computeBoundingSphere();
        this.sphere.copy(geometry.boundingSphere);
        this.sphere.applyMatrix4(matrixWorld);
        this.sphere.radius += this._getSnapWorldRadius(this.sphere.center.distanceTo(raycaster.ray.origin), raycaster.snapDistance);
        if (raycaster.ray.intersectsSphere(this.sphere) === false) return;

        const posAttribute = this.geometry.attributes.instancePosition;
        const raycast = (i: number) => {
            this.point.fromArray(posAttribute.array, i * 3).applyMatrix4(matrixWorld);
            this._raycastPoint(this.point, i, raycaster, intersects);
        };
        if (this.geometry.groups) {
            for (const g of this.geometry.groups as any[]) {
                for (let i = g.instanceOffset; i < g.instanceCount; i++)
                    raycast(i);
            }
        }
        else {
            for (let i = 0; i < posAttribute.count; i++)
                raycast(i);
        }
    }

    protected _getSnapWorldRadius(cameraDistance: number, snapDistance: number): number {
        const firstMaterial = Array.isArray(this.material) ? this.material[0] : this.material;
        if (firstMaterial.sizeAttenuation) return firstMaterial.size;
        return this._camera.getViewWorldSize(cameraDistance) * (firstMaterial.size + (snapDistance || 0)) / this._container.clientWidth;
    }

    protected _raycastPoint(point: Vector3, index: number, raycaster: Caster, intersects: Intersection[]): void {
        const cameraDistance = point.distanceTo(raycaster.ray.origin);
        const snapRadius = this._getSnapWorldRadius(cameraDistance, raycaster.snapDistance);
        const rayPointDistanceSq = raycaster.ray.distanceSqToPoint(point);
        if (rayPointDistanceSq < snapRadius * snapRadius) {
            intersects.push({
                distance: cameraDistance,
                distanceToRay: Math.sqrt(rayPointDistanceSq),
                point: point.clone(),
                index: index,
                face: null,
                object: this,
            });
        }
    }
}