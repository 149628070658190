import {WebGLBackground} from "./WebGLBackground.js";
import {WebGLEdgesAndSsaoRenderPass} from "./WebGLEdgesAndSsaoRenderPass.js";
import {DepthPeelingRenderPass} from "./DepthPeelingRenderPass.js";
import {FXAAPass} from "./FXAAPass.js";
import {Api} from "../Api.js";
import {
    DepthTexture,
    FloatType,
    WebGLRenderTarget,
} from "three";
import {Pass} from "three/examples/jsm/postprocessing/Pass.js";
import {RenderingManager} from "./RenderingManager.js";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass.js";
import {DebugRenderPass} from "./DebugRenderPass.js";
import {WebGLSelectionEffectPass} from "./WebGLSelectionEffectPass.js";
import {PassComposer} from "./PassComposer.js";
import {CopyWithDepthShader} from "./CopyWithDepthShader.js";
import {CopyShader} from "three/examples/jsm/shaders/CopyShader.js";

export class WebGLPassComposer extends PassComposer {
    declare renderTarget: WebGLRenderTarget;
    edgesAndSsaoRenderPass: WebGLEdgesAndSsaoRenderPass;
    selectionEffectPass: WebGLSelectionEffectPass;
    background: WebGLBackground;

    private mainRenderTarget: WebGLRenderTarget;
    private depthPeelingRenderPass: DepthPeelingRenderPass;
    private debugPass: DebugRenderPass;
    private fxaaPass: FXAAPass;
    private copyPassWithDepth: ShaderPass;
    private copyPass: Pass;
    private antialiasAuto: boolean;

    constructor(api: Api, renderingManager: RenderingManager) {
        super(api, renderingManager);

        this.updateFxaa();
        this.renderTarget = new WebGLRenderTarget(this.renderingManager.width, this.renderingManager.height, {
            stencilBuffer: false,
            samples: this.api.settingsDispatcher.settings.msaa ? 4 : 0,
            depthTexture: new DepthTexture(undefined, undefined, FloatType)
        }) as WebGLRenderTarget;
        this.mainRenderTarget = new WebGLRenderTarget(this.renderingManager.width, this.renderingManager.height, {
            stencilBuffer: false,
            samples: this.api.settingsDispatcher.settings.msaa ? 4 : 0,
            depthTexture: new DepthTexture(undefined, undefined, FloatType)
        }) as WebGLRenderTarget;

        this.background = new WebGLBackground(this.renderingManager, this.api.settingsDispatcher, this.api.camera);
        this.edgesAndSsaoRenderPass = new WebGLEdgesAndSsaoRenderPass(this.api, this.renderingManager);
        this.depthPeelingRenderPass = new DepthPeelingRenderPass(this.renderingManager, this.api.settingsDispatcher, this.api.scene, this.api.camera, this.edgesAndSsaoRenderPass);
        this.debugPass = new DebugRenderPass(this.api);
        this.selectionEffectPass = new WebGLSelectionEffectPass(this.api.camera, this.api.settingsDispatcher.settings);
        this.api.selectionEffectPass = this.selectionEffectPass as any as WebGLSelectionEffectPass;
        this.fxaaPass = new FXAAPass();
        this.copyPassWithDepth = new ShaderPass(CopyWithDepthShader);
        this.copyPass = new ShaderPass(CopyShader);
    }

    protected updateFxaa(): void {
        this.antialiasAuto = this.api.settingsDispatcher.settings.fxaa === undefined && this.api.settingsDispatcher.settings.antialias === undefined;
        this.antialias = this.antialiasAuto
            ? !this.api.settingsDispatcher.settings.msaa && devicePixelRatio <= 1 && !this.renderingManager.isMobile()
            : this.api.settingsDispatcher.settings.fxaa || this.api.settingsDispatcher.settings.antialias;
        if (this.api.settingsDispatcher.settings.msaa && this.antialias) console.warn("Enabling MSAA and FXAA at the same time is a bad idea, choose one antialiasing algorithm");
        if (this.api.renderingManager)
            this.api.renderingManager.redraw();
    }

    render(renderMain: boolean = true): void {
        const renderer = this.renderingManager.renderer;
        renderer.autoClear = false;
        renderer.autoClearColor = false;
        renderer.autoClearDepth = false;
        renderer.renderTimeLimit = this.renderingManager.fullRender ||
        !this.api.settingsDispatcher.settings.progressiveRendering ||
        this.api.settingsDispatcher.settings.continuousRendering ||
        this.api.renderingManager.xr.isStarted ? undefined : 30;
        renderer.renderStartTime = performance.now();

        if (!this.api.renderingManager.xr.isStarted) {
            if (renderMain) {
                renderer.setClearColor(0x000000, 0);
                renderer.setRenderTarget(this.mainRenderTarget);
                this.background.render();
                this.renderPasses(this.beforeMainPasses, this.mainRenderTarget, false);
                this.depthPeelingRenderPass.render(renderer, this.mainRenderTarget);
                this.debugPass.render(this.mainRenderTarget);
                this.renderPasses(this.beforeSelectionPasses, this.mainRenderTarget, false);
                this.selectionEffectPass.render(renderer, this.mainRenderTarget);
            }
            this.blit(this.mainRenderTarget, this.renderTarget);
            this.renderPasses(this.afterSelectionPasses, this.renderTarget, false);
            this.renderPasses(this.beforeAntialiasingPasses, this.renderTarget, false);
            renderer.setRenderTarget(this.renderTarget);  // threejs magic ?
            this.renderFxaa();
            this.renderPasses(this.afterAntialiasingPasses, this.renderTarget, true);
        }
        else {
            // TODO: Post effects are not supported in VR
            this.background.render();
            const rt = renderer.getRenderTarget();
            this.renderPasses(this.beforeMainPasses, rt, false);
            this.renderingManager.renderer.render(this.api.scene, this.api.camera);
            this.renderPasses(this.beforeSelectionPasses, rt, false);
            this.renderPasses(this.afterSelectionPasses, rt, false);
        }
        renderer.renderTimeLimit = undefined;
    }

    private blit(src: WebGLRenderTarget, dst: WebGLRenderTarget): void {
        const renderer = this.renderingManager.renderer;
        renderer.setRenderTarget(dst);
        if (this.api.settingsDispatcher.settings.msaa) {
            // Blit doesn't work with multisampled buffers, do copy pass instead (slower)
            renderer.clearDepth();
            this.copyPassWithDepth.uniforms.depthTexture.value = src.depthTexture;
            this.copyPassWithDepth.render(renderer, dst, src, undefined, undefined);
        }
        else {
            this.renderingManager.renderer.blit(src, dst);
        }
    }

    private renderFxaa(): void {
        (this.antialias || this.antialiasAuto && !this.api.settingsDispatcher.settings.msaa && performance.now() - this.renderingManager.renderer.renderStartTime < 13 ? this.fxaaPass : this.copyPass)
            .render(this.renderingManager.renderer, null, this.renderTarget, undefined, undefined);
    }

    private renderPasses(passes: Pass[], renderTarget: WebGLRenderTarget, renderToScreen: boolean): void {
        for (const p of passes) {
            p.renderToScreen = renderToScreen;
            p.render(this.renderingManager.renderer, p.needsSwap ? renderTarget : null, p.needsSwap ? null : renderTarget, undefined, undefined);
        }
    }

    override setSize(width: number, height: number): void {
        this.updateFxaa();
        super.setSize(width, height);
        this.mainRenderTarget.setSize(width, height);
        this.depthPeelingRenderPass.setSize(width, height);
        this.fxaaPass.setSize(width, height);
    }

    override dispose(): void {
        super.dispose();
        this.depthPeelingRenderPass.dispose();
        this.debugPass.dispose();
        this.fxaaPass.dispose();
        this.copyPass.dispose();
        this.copyPassWithDepth.dispose();
        this.mainRenderTarget.dispose();
    }
}
