import {
    DataTexture,
    DepthTexture, FloatType,
    HalfFloatType, LinearFilter,
    Material, PixelFormat,
    RGBAFormat,
    ShaderMaterial, UnsignedByteType,
    Vector2,
    WebGLRenderer,
    Matrix4,
    WebGLRenderTarget,
    RenderTarget,
    LinearSRGBColorSpace, RGFormat, NearestFilter
} from "three";
import {EffectPass} from "./EffectPass.js";
import {RenderingManager} from "./RenderingManager.js";
import {Texture} from "three";
import {TextureDataType} from "three";
import {disposeImage} from "../Helpers/common-utils.js";
import {Api} from "../Api.js";
import {type WebGPURenderer} from "../Three.WebGPU.js";

export abstract class EdgesAndSsaoRenderPass {
    protected multiRenderTarget: WebGLRenderTarget;
    protected ssaoRenderTarget: RenderTarget;
    protected ssaoSimpleRenderTarget: RenderTarget;
    protected edgesPass: EffectPass;
    protected ssaoPass: EffectPass;
    protected ssaoSimplePass: EffectPass;
    protected noiseTexture: Texture;
    protected texelSize = new Vector2();
    protected cameraNearFar = new Vector2();
    protected prevCameraMatrixWorld = new Matrix4();

    colorTexture: Texture;
    normalTexture: Texture;
    flagsAndCutsTexture: Texture;
    depthTexture: DepthTexture;

    protected constructor(protected api: Api, protected renderingManager: RenderingManager) {
        this.init();
        api.settingsDispatcher.subscribe("renderEdges", () => this.init());
    }

    get renderEdges(): boolean {
        return this.api.settingsDispatcher.settings.renderEdges &&
            !this.renderingManager.xr.isStarted;
    }

    protected abstract createEdgesMaterial(): Material;
    protected abstract createSsaoMaterial(simple: boolean): Material;
    protected abstract renderWithEdges(renderer: WebGLRenderer | WebGPURenderer, renderTarget: RenderTarget, renderSsao: boolean, shouldRenderMaterial: (m: Material) => boolean): void;

    protected init(): void {
        if (this.renderEdges && !this.multiRenderTarget) {
            if (this.api.settingsDispatcher.settings.msaa)
                console.warn("RenderEdges look bad with MSAA, use FXAA instead");
            this.colorTexture = this.createTexture(UnsignedByteType, RGBAFormat, "color");
            this.normalTexture = this.createTexture(FloatType, RGBAFormat, "normal");
            this.flagsAndCutsTexture = this.createTexture(HalfFloatType, RGFormat, "flagsAndCuts");
            this.depthTexture = new DepthTexture(undefined, undefined, FloatType);
            this.multiRenderTarget = new WebGLRenderTarget(undefined, undefined, {samples: this.api.settingsDispatcher.settings.msaa ? 4 : 0, minFilter: NearestFilter, magFilter: NearestFilter});
            this.multiRenderTarget.textures = [this.colorTexture, this.normalTexture, this.flagsAndCutsTexture];
            this.multiRenderTarget.depthTexture = this.depthTexture;
            this.noiseTexture = this.createNoiseTexture();
            this.ssaoRenderTarget = new RenderTarget(1, 1, {
                minFilter: LinearFilter,
                magFilter: LinearFilter
            });
            this.ssaoSimpleRenderTarget = this.ssaoRenderTarget.clone();
            this.ssaoPass = new EffectPass(this.createSsaoMaterial(false) as ShaderMaterial);
            this.ssaoSimplePass = new EffectPass(this.createSsaoMaterial(true) as ShaderMaterial);
            this.edgesPass = new EffectPass(this.createEdgesMaterial() as ShaderMaterial);
            this.setSize(this.renderingManager.width, this.renderingManager.height);
        }
    }

    render(renderer: WebGLRenderer | WebGPURenderer, renderTarget: RenderTarget, renderSsao: boolean, shouldRenderMaterial: (m: Material) => boolean): void {
        if (this.renderEdges)
            this.renderWithEdges(renderer, renderTarget, renderSsao, shouldRenderMaterial);
        else
            this.renderWithoutEdges(renderer, renderTarget, shouldRenderMaterial);
    }

    protected renderWithoutEdges(renderer: WebGLRenderer | WebGPURenderer, renderTarget: RenderTarget, shouldRenderMaterial: (m: Material) => boolean): void {
        let shouldRenderAnything = false;
        this.renderingManager.traverseMaterials((o, m) => {
            const srm = shouldRenderMaterial(m);
            shouldRenderAnything = shouldRenderAnything || srm;
            return m.visible = srm;
        });
        if (!shouldRenderAnything) return; // early optimization

        // @ts-ignore
        renderer.setRenderTarget(renderTarget);
        renderer.render(this.api.scene, this.api.camera);
    }

    protected createTexture(type: TextureDataType, format: PixelFormat, name = ""): Texture {
        const texture = new Texture(
            {width: this.renderingManager.width, height: this.renderingManager.height} as any,
            undefined,
            undefined,
            undefined,
            NearestFilter,
            NearestFilter,
            format,
            type,
            undefined,
            LinearSRGBColorSpace    // NB: Apparently it is crucial to define this explicitly for the current WebGPU Three.js implementation
        );
        texture.isRenderTargetTexture = true;
        texture.name = name;
        return texture;
    }

    protected createNoiseTexture(): Texture {
        const size = 100;
        const noiseData = new Uint8Array(size * size * 4);
        for (let i = 0; i < noiseData.length; ++i)
            noiseData[i] = Math.random() * 255;
        const t = new DataTexture(noiseData, size, size, RGBAFormat);
        t.onUpdate = disposeImage;
        t.needsUpdate = true;
        return t;
    }

    setSize(width: number, height: number): void {
        if (this.multiRenderTarget) {
            this.multiRenderTarget.setSize(width, height);
            // pixelRatio might be different from devicePixelRation when rendering a screenshot
            const screenWidth = width / this.renderingManager.renderer.getPixelRatio();
            const screenHeight = height / this.renderingManager.renderer.getPixelRatio();
            this.texelSize.set(1 / screenWidth, 1 / screenHeight);

            // SSAO simple render target is smaller for optimization
            this.ssaoRenderTarget.setSize(width, height);
            this.ssaoSimpleRenderTarget.setSize(screenWidth * 0.7, screenHeight * 0.7);
        }
    }

    dispose(): void {
        if (this.multiRenderTarget) this.multiRenderTarget.dispose();
        if (this.ssaoRenderTarget) this.ssaoRenderTarget.dispose();
        if (this.ssaoSimpleRenderTarget) this.ssaoSimpleRenderTarget.dispose();
        if (this.edgesPass) this.edgesPass.dispose();
        if (this.ssaoPass) this.ssaoPass.dispose();
        if (this.noiseTexture) this.noiseTexture.dispose();
        if (this.colorTexture) this.colorTexture.dispose();
        if (this.normalTexture) this.normalTexture.dispose();
        if (this.flagsAndCutsTexture) this.flagsAndCutsTexture.dispose();
        if (this.depthTexture) this.depthTexture.dispose();
    }
}
