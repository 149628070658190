import {ErrorEventDetail, PickEventDetail, SelectionEventDetail, Web3DEventName} from "./common.js";
import {Tool} from "./Tools/Tool.js";

export class EventDispatcher {
    #element: HTMLElement;

    constructor(element: HTMLElement) {
        this.#element = element;
    }

    error(modelId: string, errorMessage: string, stackTrace: string): void {
        const event = new CustomEvent<ErrorEventDetail>(Web3DEventName.Error, {
            detail: {
                modelId: modelId,
                errorMessage: errorMessage,
                stackTrace: stackTrace
            },
            bubbles: true,
            composed: true
        });
        this.#element.dispatchEvent(event);
    }

    selected(detail: SelectionEventDetail): void {
        const event = new CustomEvent<SelectionEventDetail>(Web3DEventName.Selection, {
            detail: detail,
            bubbles: true,
            composed: true
        });
        this.#element.dispatchEvent(event);
    }

    picked(detail: PickEventDetail): void {
        const event = new CustomEvent<PickEventDetail>(detail.multiClick === undefined ? Web3DEventName.Pick : Web3DEventName.PickMultiClick, {
            detail: detail,
            bubbles: true,
            composed: true
        });
        this.#element.dispatchEvent(event);
    }

    navigation(start: boolean): void {
        const event = new CustomEvent(start ? Web3DEventName.NavigationStart : Web3DEventName.NavigationEnd, {
            bubbles: true,
            composed: true
        });
        this.#element.dispatchEvent(event);
    }

    dragStart(tool?: Tool): void {
        const event = new CustomEvent(Web3DEventName.DragStart, {
            detail: tool,
            bubbles: true,
            composed: true,
        });
        this.#element.dispatchEvent(event);
    }

    dragEnd(tool?: Tool): void {
        const event = new CustomEvent(Web3DEventName.DragEnd, {
            detail: tool,
            bubbles: true,
            composed: true
        });
        this.#element.dispatchEvent(event);
    }

    dispatch(event: CustomEvent): void {
        this.#element.dispatchEvent(event);
    }

    subscribe(eventName: Web3DEventName, listener: (e: CustomEvent) => void): void {
        this.#element.addEventListener(eventName, listener as EventListener);
    }
}
