import {
    Box3,
    Camera,
    Euler,
    Frustum,
    Matrix4,
    Quaternion,
    Vector2,
    Vector3,
    MathUtils,
    Plane,
    WebGPUCoordinateSystem,
    WebGLCoordinateSystem
} from "three";
import {ProjectionType, ViewPreset} from "../common.js";
import {SphericalToDirection} from "../Helpers/utils.js";
import {Api} from "../Api.js";
import {easeInOutQuad, singleTween} from "../Animation.js";
import {getPerspectiveViewWorldSize, Vector3Const} from "../Helpers/common-utils.js";
import {NO_PICK_LAYER} from "../Picker/Picker.js";
import {Subscription} from "rxjs";

export class Web3DCamera extends Camera {
    projectionType: ProjectionType = ProjectionType.Perspective;

    nearMin: number = 0.05; // smaller numbers make depth buffer imprecise, results z-fighting
    nearMinForGlobeModel: number = 10;

    near = this.nearMin;
    far = 10000;

    aspect = 1;

    // Angle of perspective view in degrees of wider aspect ratio (might be horizontal or vertical)
    fov = 60;

    // Orthographic view size in meters of wider aspect ratio (might be horizontal or vertical)
    orthoSize = 1;

    private fitToViewMaxAspect: number = 30;
    #initial = true;

    // for compatibility with threejs cameras
    get zoom(): number { return 1; }
    set zoom(v: number) {}

    get isPerspectiveCamera(): boolean {
        return this.projectionType === ProjectionType.Perspective;
    }

    get isOrthographicCamera(): boolean {
        return this.projectionType === ProjectionType.Ortho;
    }

    constructor(private _api: Api) {
        super();
        this.coordinateSystem = this._api.settingsDispatcher.settings.useWebgpu ?
            WebGPUCoordinateSystem :
            WebGLCoordinateSystem;
        this.updateProjectionMatrix();
        this.layers.enable(NO_PICK_LAYER);
    }

    updateProjectionMatrix(): void {
        if (this.isPerspectiveCamera)
            this.updatePerspectiveProjectionMatrix();
        else
            this.updateOrthographicProjectionMatrix();
    }

    private updatePerspectiveProjectionMatrix(): void {
        const wider = this.near * Math.tan(MathUtils.DEG2RAD * 0.5 * this.fov);

        const top = this.aspect > 1 ? wider / this.aspect : wider;
        const height = 2 * top;
        const width = this.aspect * height;
        const left = -0.5 * width;

        this.projectionMatrix.makePerspective(left, left + width, top, top - height, this.near, this.far, this.coordinateSystem);
        this.projectionMatrixInverse.copy(this.projectionMatrix).invert();
    }

    private updateOrthographicProjectionMatrix(): void {
        const dx = this.aspect > 1 ? this.orthoSize : this.orthoSize * this.aspect;
        const dy = dx / this.aspect;

        this.projectionMatrix.makeOrthographic(-dx, dx, dy, -dy, this.near, this.far);
        this.projectionMatrixInverse.copy(this.projectionMatrix).invert();
    }

    getProjectionCompensatingScale(distance: number): number {
        const res = Math.max(this._api.container.clientWidth, this._api.container.clientHeight);
        return this.getViewWorldSize(distance) * 30 / res;
    }

    getViewWorldSize(cameraDistance: number): number {
        return Web3DCamera.getViewWorldSize(this, cameraDistance);
    }

    static getViewWorldSize(camera: Web3DCamera, cameraDistance: number): number {
        if (camera.isPerspectiveCamera) {
            return getPerspectiveViewWorldSize(camera.fov, cameraDistance);
        }
        else if (camera.isOrthographicCamera) {
            return camera.orthoSize;
        }
    }

    public getProjectionType(): ProjectionType {
        return this.projectionType;
    }

    public async setProjectionType(projectionType: ProjectionType, focusPoint?: Vector3): Promise<void> {
        if (!focusPoint) focusPoint = await this._getViewFocusPoint();
        if (projectionType === ProjectionType.Perspective && this.isOrthographicCamera)
            this._switchToPerspective(focusPoint);
        else if (projectionType === ProjectionType.Ortho && this.isPerspectiveCamera)
            this._switchToOrthographic(focusPoint);
    }

    private async _getViewFocusPoint(): Promise<Vector3> {
        const canvas = this._api.renderingManager.renderer.domElement;
        const rect = canvas.getBoundingClientRect();
        const point = await this._api.picker.pickForNavigation(new Vector2(rect.x + rect.width / 2, rect.y + rect.height / 2));
        return point ? point : this._api.models.worldBoundingBox.value.getCenter(new Vector3());
    }

    private _switchToOrthographic(focusPoint: Vector3): void {
        let dist = focusPoint.distanceTo(this.position);
        if (dist === 0) dist = 1;
        const orthoSize = getPerspectiveViewWorldSize(this.fov, dist);
        this.projectionType = ProjectionType.Ortho;
        this.orthoSize = orthoSize;
        this.updateProjectionMatrix();
        this.callListeners();
    }

    private _switchToPerspective(focusPoint: Vector3): void {
        this.projectionType = ProjectionType.Perspective;
        this.updateProjectionMatrix();

        const dist = this.orthoSize / getPerspectiveViewWorldSize(this.fov, 1);
        this.position.sub(focusPoint).normalize().multiplyScalar(dist).add(focusPoint);
        this.callListeners();
    }

    // Animates linearly
    async animate(position?: Vector3, quaternion?: Quaternion, animationTime: number = this._api.settingsDispatcher.settings.animationTime, easeFunc: (t: number) => number = easeInOutQuad): Promise<void> {
        if (animationTime === 0) {
            if (position) this.position.copy(position);
            if (quaternion) this.quaternion.copy(quaternion);
            this.callListeners();
        } else {
            if (position && quaternion)
                await Promise.all([this.animatePosition(position, animationTime, easeFunc), this.animateRotation(quaternion, animationTime, easeFunc)]);
            else if (position)
                await this.animatePosition(position, animationTime, easeFunc);
            else if (quaternion)
                await this.animateRotation(quaternion, animationTime, easeFunc);
        }
    }

    // Animates orbital rotation around a center
    async animateOrbit(position: Vector3, center: Vector3, animationTime: number = this._api.settingsDispatcher.settings.animationTime, easeFunc: (t: number) => number = easeInOutQuad): Promise<void> {
        const quaternion = new Quaternion();

        const dp = position.clone().sub(center);
        if (Math.abs(dp.x) < 0.0001 && Math.abs(dp.y) < 0.0001) {                                   // Special case: Camera looks straight up or down
            if (dp.z >= 0) { /* Default quaternion */ }                                             // Top view
            else quaternion.setFromEuler(new Euler(0, Math.PI, Math.PI));                        // Bottom view
        } else quaternion.setFromRotationMatrix(new Matrix4().lookAt(position, center, this.up));   // Normal cases, look at center, roll based on camera up

        if (animationTime === 0) {
            this.position.copy(position);
            this.quaternion.copy(quaternion);
            this.callListeners();
        } else {
            await this.animateOrbitCombined(position, center, quaternion, animationTime, easeFunc);
        }
    }

    private cameraMover: Subscription;
    private cameraMoverComplete: () => void;
    private cameraRotator: Subscription;
    private cameraRotatorComplete: () => void;
    private cameraOrthoZoomer: Subscription;
    private cameraOrthoZoomerComplete: () => void;

    animatePosition(position: Vector3, animationTime: number = this._api.settingsDispatcher.settings.animationTime, easeFunc: (t: number) => number = easeInOutQuad): Promise<void> {
        this.stopMoveAnimation();
        const startPosition = this.position.clone();
        const lastPosition = startPosition.clone();

        return new Promise((resolved, rejected) => {
            this.cameraMoverComplete = () => {
                this.callListeners();
                resolved();
            };
            this.cameraMover = singleTween(animationTime, easeFunc, 0, 1)
                .subscribe((t: number) => {
                        // stop animation if camera was moved by "external forces"
                        if (!this.position.equals(lastPosition)) {
                            this.stopMoveAnimation();
                            return;
                        }
                        this.position.lerpVectors(startPosition, position, t);
                        this.callListeners();
                        lastPosition.copy(this.position);
                    },
                    rejected,
                    this.cameraMoverComplete
                );
        });
    }

    /**
     * Slerps quaternion qi from q1 to q2 based on t, and performs the same rotation on the vector r1. The rotated
     * vector is stored into ri.
     */
    static slerpQuaternionsWithPosition = (() => {
        const rQ = new Quaternion();  // Rotation quaternion from this.quaternion to quaternion
        const q1Neg = new Quaternion();

        return (q1: Quaternion, q2: Quaternion, r1: Vector3, t: number, qi: Quaternion, ri: Vector3) => {

            // Slerp the quaternion:
            qi.slerpQuaternions(q1, q2, t);

            q1Neg.copy(q1).conjugate();

            // Find the quaternion that rotates from start quaternion to slerped quaternion:
            rQ.multiplyQuaternions(qi, q1Neg);

            // Rotate r and apply back:
            ri.copy(r1).applyQuaternion(rQ);
        };
    })();

    animateOrbitCombined(position: Vector3, center: Vector3, quaternion: Quaternion, animationTime: number = this._api.settingsDispatcher.settings.animationTime, easeFunc: (t: number) => number = easeInOutQuad): Promise<void> {
        this.stopRotationAnimation();

        const startPos = this.position.clone();
        const lastPos = startPos.clone();
        const r1 = startPos.clone().sub(center).normalize();    // The normalized vector from center to start camera position
        const ri = new Vector3();                               // The vector from center to current camera position
        const radius1 = startPos.distanceTo(center);
        const radius2 = position.distanceTo(center);
        const startQ = this.quaternion.clone();
        const lastQ = startQ.clone();

        const posCorrection = (() => {
            Web3DCamera.slerpQuaternionsWithPosition(startQ, quaternion, r1, 1, new Quaternion(), ri);
            ri.multiplyScalar(radius2);
            const rotPos = center.clone().add(ri);  // The new position after the rotation is applied
            return position.clone().sub(rotPos);    // The correction to rotPos required to reach the 'position' parameter
        })();
        const dPosCorrection = new Vector3();

        return new Promise((resolved, rejected) => {
            this.cameraRotatorComplete = () => resolved();
            this.cameraRotator = singleTween(animationTime, easeFunc, 0, 1)
                .subscribe((t: number) => {
                        // stop animation if camera was moved by "external forces"
                        if (!this.position.equals(lastPos)) {
                            this.stopMoveAnimation();
                            return;
                        }
                        // stop animation if camera was rotated by "external forces"
                        if (!this.quaternion.equals(lastQ)) {
                            this.stopRotationAnimation();
                            return;
                        }

                        Web3DCamera.slerpQuaternionsWithPosition(startQ, quaternion, r1, t, this.quaternion, ri);

                        // Interpolate radius ("camera zoom"):
                        ri.multiplyScalar(MathUtils.lerp(radius1, radius2, t));

                        // Rotated position = center + ri:
                        this.position.copy(center).add(ri);

                        // Lerp the remaining position difference ("center camera"):
                        dPosCorrection.copy(posCorrection).multiplyScalar(t);
                        this.position.add(dPosCorrection);

                        this.callListeners();
                        lastPos.copy(this.position);
                        lastQ.copy(this.quaternion);
                    },
                    rejected,
                    this.cameraRotatorComplete
                );
        });
    }

    animateRotation(quaternion: Quaternion, animationTime: number = this._api.settingsDispatcher.settings.animationTime, easeFunc: (t: number) => number = easeInOutQuad): Promise<void> {
        this.stopRotationAnimation();
        const start = this.quaternion.clone();
        const last = start.clone();

        return new Promise((resolved, rejected) => {
            this.cameraRotatorComplete = () => resolved();
            this.cameraRotator = singleTween(animationTime, easeFunc, 0, 1)
                .subscribe((t: number) => {
                        // stop animation if camera was rotated by "external forces"
                        if (!this.quaternion.equals(last)) {
                            this.stopRotationAnimation();
                            return;
                        }
                        this.quaternion.slerpQuaternions(start, quaternion, t);
                        this.callListeners();
                        last.copy(this.quaternion);
                    },
                    rejected,
                    this.cameraRotatorComplete
                );
        });
    }

    async animateOrthoSize(size: number, animationTime: number = this._api.settingsDispatcher.settings.animationTime, easeFunc: (t: number) => number = easeInOutQuad): Promise<void> {
        const start = this.orthoSize;
        let last = this.orthoSize;

        return new Promise((resolved, rejected) => {
            this.cameraOrthoZoomerComplete = () => resolved();
            this.cameraOrthoZoomer = singleTween(animationTime, easeFunc, 0, 1)
                .subscribe((t: number) => {
                        // stop animation if camera zoom was changed by "external forces"
                        if (this.orthoSize !== last) {
                            this.stopOrthoZoomAnimation();
                            return;
                        }
                        this.orthoSize = MathUtils.lerp(start, size, t);
                        this.callListeners();
                        last = this.orthoSize;
                    },
                    rejected,
                    this.cameraOrthoZoomerComplete
                );
        });
    }

    private stopMoveAnimation(): void {
        if (this.cameraMover) {
            this.cameraMover.unsubscribe();
            this.cameraMover = null;
            this.cameraMoverComplete();
        }
    }

    private stopRotationAnimation(): void {
        if (this.cameraRotator) {
            this.cameraRotator.unsubscribe();
            this.cameraRotator = null;
            this.cameraRotatorComplete();
        }
    }

    private stopOrthoZoomAnimation(): void {
        if (this.cameraOrthoZoomer) {
            this.cameraOrthoZoomer.unsubscribe();
            this.cameraOrthoZoomer = null;
            this.cameraOrthoZoomerComplete();
        }
    }

    isInitial(): boolean {
        const initial = this.#initial;
        this.#initial = false;
        return initial;
    }

    public async fitToView(boundingbox: Box3, animationTime: number, preset?: ViewPreset, tightness: number = 1): Promise<void> {
        this._api.analytics.send({hitType: "event", eventCategory: "Navigation", eventAction: "fitToView", eventLabel: preset});
        let dist = 10;
        let orthoSize = 10;
        let center = Vector3Const.zero;

        if (!boundingbox.isEmpty()) {
            this._api.renderingManager.updateCanvasSize();
            const size = boundingbox.max.clone().sub(boundingbox.min);
            const length = size.length();

            let aspectFactor;
            if (this.aspect && !isNaN(this.aspect))
                aspectFactor = this.aspect > 1 ? this.aspect :  1 / this.aspect;

            // When camera aspect is undefined or very thin, aspectFactor = 1 will be used to avoid making models appear too small to see.
            // This is required by Trimble Connect for loading models when the canvas has been made very thin to make space for UI panel on mobile devices.
            if (!aspectFactor || aspectFactor > this.fitToViewMaxAspect)
                aspectFactor = 1;

            dist = (length * aspectFactor) / (tightness * 2) / getPerspectiveViewWorldSize(this.fov, 1);
            orthoSize = (length * aspectFactor) / (tightness * 2);
            center = boundingbox.getCenter(new Vector3());
       }

        const firstLoad = this.isInitial();
        if (!preset && firstLoad) preset = ViewPreset.Axon;
        animationTime = firstLoad ? 0 : animationTime;


        if (this.projectionType === ProjectionType.Ortho)
            this.animateOrthoSize(orthoSize, animationTime);

        if (preset) {
            const position =
                preset === ViewPreset.Axon ? new Vector3(dist * 0.6767155423319646, -dist * 0.6767155423319646, dist * 0.2900209467136988) :
                preset === ViewPreset.Top ? new Vector3(0, 0, dist) :
                preset === ViewPreset.Bottom ? new Vector3(0, -0.001, -dist) : // Nudge added to y coordinate to make orbit controls know the direction to move from here (to avoid a camera jump)
                preset === ViewPreset.Left ? new Vector3(-dist, 0, 0) :
                preset === ViewPreset.Right ? new Vector3(dist, 0, 0) :
                preset === ViewPreset.Front ? new Vector3(0, -dist, 0) :
                preset === ViewPreset.Back ? new Vector3(0, dist, 0) : null;

            // Presets use orbital animation around the center:
            await this.animateOrbit(position.add(center), center, animationTime);
        }
        else {
            const position = this.getWorldDirection(new Vector3()).multiplyScalar(-dist);
            await this.animatePosition(position.add(center), animationTime);
        }
    }

    rotateSpherical = (() => {
        const PI_2 = Math.PI / 2;

        const sphericalUp = new Vector2();
        const right = new Vector3();
        const up = new Vector3();
        const forward = new Vector3();
        const lookAt = new Vector3();
        const scaled_forward = new Vector3();
        const scaled_right = new Vector3();
        const scaled_up = new Vector3();
        const position = new Vector3();

        return (rotationPoint: Vector3, offset: Vector3, sphericalDirection: Vector2) => {
            sphericalUp.set(sphericalDirection.x - PI_2, sphericalDirection.y);

            SphericalToDirection(sphericalDirection, forward);
            SphericalToDirection(sphericalUp, up);
            right.crossVectors(forward, up);

            scaled_forward.copy(forward).multiplyScalar(offset.y);
            scaled_right.copy(right).multiplyScalar(offset.x);
            scaled_up.copy(up).multiplyScalar(offset.z);

            this.position.copy(
                position
                    .copy(rotationPoint)
                    .sub(scaled_forward)
                    .sub(scaled_right)
                    .sub(scaled_up)
            );
            this.lookAt(lookAt.copy(this.position).add(forward));
        };
    })();

    private _frustum = new Frustum();
    private _matrix = new Matrix4();

    // Takes XR camera into account, works in both screen and XR modes
    get frustum(): Frustum {
        this.updateMatrixWorld(true);
        this._matrix.multiplyMatrices(this.projectionMatrix, this.matrixWorldInverse);
        this._frustum.setFromProjectionMatrix(this._matrix);
        return this._frustum;
    }

    // Takes XR camera into account, works in both screen and XR modes
    get fovXR(): number {
        const cam = this._api.renderingManager.xr.toVRCamera(this);
        if (cam === this)
            return this.fov;

        // ArrayCamera has a union projection matrix of both eyes
        const proj = cam.projectionMatrix;
        const aspect = proj.elements[5] / proj.elements[0];
        const fov = 2.0 * Math.atan(1.0 / proj.elements[5]) * MathUtils.RAD2DEG;
        if (Number.isNaN(fov) || Number.isNaN(aspect)) // proj matrix is not ready yet
            return this.fov;
        return aspect > 1 ? fov * aspect : fov;
    }

    fitNearAndFarPlanes = (() => {
        const point = new Vector3();
        const camDir = new Vector3();
        const plane = new Plane();
        return (aabb: Box3) => {
            if (!aabb || aabb.isEmpty()) {
                this.near = this.nearMin;
                return;
            }

            camDir.copy(Vector3Const.threejsCameraForward).applyQuaternion(this._api.camera.quaternion);
            plane.setFromNormalAndCoplanarPoint(camDir, this._api.camera.position);
            point.set(
                camDir.x > 0.0 ? aabb.max.x : aabb.min.x,
                camDir.y > 0.0 ? aabb.max.y : aabb.min.y,
                camDir.z > 0.0 ? aabb.max.z : aabb.min.z
            );
            const far = plane.distanceToPoint(point);
            // Far plane is not limited to fit large, city-sized models
            this.far = far * 1.05;

            point.set(
                camDir.x <= 0.0 ? aabb.max.x : aabb.min.x,
                camDir.y <= 0.0 ? aabb.max.y : aabb.min.y,
                camDir.z <= 0.0 ? aabb.max.z : aabb.min.z
            );

            const near = plane.distanceToPoint(point);
            this.near = Math.max(near * 0.95, this.nearMin);

            this.updateProjectionMatrix();
        };
    })();

    getApproxPixelSize = (() => {
        let perspectiveViewWorldSize: number;
        let prevCameraFov: number;

        return (size: number, distance: number): number => {
            let viewWorldSize;

            if (this.isOrthographicCamera) {
                viewWorldSize = this.orthoSize;
            }
            else {
                if (this.fov !== prevCameraFov) {
                    perspectiveViewWorldSize = getPerspectiveViewWorldSize(this.fov, 1);
                    prevCameraFov = this.fov;
                }
                viewWorldSize = perspectiveViewWorldSize * distance;
            }

            const screenSize = size / viewWorldSize;
            return screenSize * Math.max(this._api.renderingManager.width, this._api.renderingManager.height);
        };
    })();

    private listeners: Array<() => void> = [];

    callListeners(): void {
        this.listeners.forEach(l => l());
    }

    subscribe(listener: () => void): void {
        this.listeners.push(listener);
    }

    unsubscribe(listener: () => void): void {
        this.listeners.splice(this.listeners.indexOf(listener), 1);
    }
}
