import {Tool} from "./Tool.js";
import {ClipPlaneEventName, MouseButton, Settings, SnappingTool, SnapType} from "../common.js";
import {Ray, Raycaster, Vector3, Box3, Plane, Color} from "three";
import {ClipPlaneModel} from "../ClipPlaneModel.js";
import {Cursor3D} from "../Picker/Cursor3D.js";
import {
    addIntersection,
    allowInput,
    PointerObservableOptions,
    controlsReleased,
    InputHandler,
    PointerInput, ScrollEvent} from "../InputHandler.js";
import {Api} from "../Api.js";
import { finalize, Subscription } from "rxjs";
import { debounceTime, filter, mergeMap, switchMap, takeUntil, tap} from "rxjs/operators";
import {Key} from "ts-key-enum";
import {screenPositionToRayTracePoint} from "../Picker/Picker.js";
import {DraggableClipPlane} from "../DraggableClipPlane.js";

export class ClipPlaneTool extends Tool implements SnappingTool {
    static get Name(): string { return "clipPlane"; }

    private model: ClipPlaneModel;
    private _tapsHandle: Subscription;
    private _rayCaster: Raycaster;
    private _translatingClipPlane: DraggableClipPlane;
    private _cursor: Cursor3D;
    private _snapTypes: SnapType[] = [SnapType.FACE];
    private observableOptions: PointerObservableOptions;
    private _controlsVisible: boolean = true;

    get allowedSnapTypes(): SnapType[] {
        return [SnapType.FACE, SnapType.LINE];
    }

    get snapTypes(): SnapType[] {
        return this._snapTypes;
    }

    set snapTypes(value: SnapType[]) {
        this._snapTypes = value;
        if (this.enabled) this._cursor.snapTypes = value;
    }

    get name(): string {
        return ClipPlaneTool.Name;
    }

    set intersectingClipPlanes(value: boolean) {
        this.settings.intersectingClipPlanes = value;
    }

    get intersectingClipPlanes(): boolean {
        return this.settings.intersectingClipPlanes;
    }

    set enabled(enabled: boolean) {
        if (this._tapsHandle) {
            this._tapsHandle.unsubscribe();
            this._tapsHandle = null;
            this._cursor.unsubscribe();
        }
        if (!enabled) return;

        this._cursor = this._api.cursor;
        this._cursor.snapTypes = this.snapTypes;
        this._cursor.subscribe();

        const taps = this._inputs.createSnappedTapObservable(this._cursor, this.observableOptions);
        this._tapsHandle = taps.subscribe(event => {
            this._addClipPlane(event);
        });
    }

    get enabled(): boolean {
        return !!this._tapsHandle;
    }

    constructor(
        private _inputs: InputHandler,
        private _api: Api,
        private settings: Settings
    ) {
        super();
        this.settings.clipPlaneCuts = false;
        this.settings.intersectingClipPlanes = false;

        this._rayCaster = new Raycaster();

        this.observableOptions = {
            button: MouseButton.left,
            touchCount: 1
        };


        this.startKeyListener();
        this.setupWheelTranslation();
    }

    private setupWheelTranslation(): void {
        const tool = this;
        let wheelStarted: boolean = false;
        this._api.inputHandler.wheel$.pipe(
            mergeMap(async (event: ScrollEvent) => {
                if (!wheelStarted && (event.originalEvent as WheelEvent).shiftKey) {
                    this._api.eventDispatcher.dragStart(tool);
                    wheelStarted = true;
                }
                return event;
            }),
            mergeMap(async (e: ScrollEvent) => {
                if (wheelStarted) {
                    for (const cp of this.getClipPlanes()) {
                        if (cp.selected) {
                            const speed = -e.speed * cp.plane.distanceToPoint(this._api.camera.position) * 0.04;
                            const ray = new Ray(
                                cp.travelRay.direction.clone().multiplyScalar(speed).add(cp.position),
                                cp.travelRay.direction.clone().cross(this._api.camera.getWorldDirection(new Vector3())));
                            await cp.translate(ray);
                        }
                    }
                    this._api.camera.callListeners();
                }
            }),
            debounceTime(200),
            tap(() => {
                if (wheelStarted) {
                    this._api.eventDispatcher.dragEnd(tool);
                    for (const cp of this.getClipPlanes()) {
                        if (cp.selected) {
                            cp.origin = this;
                            this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Modified, {detail: cp}));
                        }
                    }
                }
                wheelStarted = false;
            })
        ).subscribe();
    }

    get controlsVisible(): boolean {
        return this._controlsVisible;
    }

    set controlsVisible(visible: boolean) {
        this._controlsVisible = visible;
        for (const cp of this.getClipPlanes())
            cp.controlsVisible = visible;
    }

    private startKeyListener(): void {
        this._api.inputHandler.keyDown$.subscribe((e: KeyboardEvent) => {
            if (e.code === Key.Delete || e.code === Key.Backspace)
                this.removeSelected();

            if (e.code === Key.Tab) {
                this.selectNext();
                e.preventDefault();
            }
        });
    }

    private selectNext(): void {
        const planes = this.getClipPlanes();
        for (let i = 0; i < planes.length; i++) {
            if (!planes[(i + 1) % planes.length].selected && planes[i].selected) {
                planes[(i + 1) % planes.length].select();
                planes[i].deselect();
                break;
            }
        }
    }

    private removeSelected(): void {
        this.getModel().removeSelected(this);
    }

    getClipPlanes(): DraggableClipPlane[] {
        return this.getModel().getDraggableClipPlanes();
    }

    private getModel(): ClipPlaneModel {
        if (this.model) return this.model;

        this.model = this._api.models.get(ClipPlaneModel.ModelId) as ClipPlaneModel;

        if (!this.model) {
            this.model = new ClipPlaneModel(this._api, this.settings);
            this._api.models.add(this.model);
        }

        this.subscribeToDoubleClick();
        this.subscribeToMovePlanes();

        return this.model;
    }

    private subscribeToDoubleClick(): void {
        const doubleTap = this._inputs.createDoubleTapObservable(this.observableOptions).pipe(
            filter(this._isClipPlane)
        );
        doubleTap.subscribe(event => this.getClipPlane(event).invert());
    }

    private subscribeToMovePlanes(): void {
        const button = MouseButton.left;
        const touchCount = 1;

        let dragStarted = false;
        const moveCallback = async (event: PointerInput) => {
            if (!dragStarted) {
                this._api.eventDispatcher.dragStart(this);
                dragStarted = true;
            }
            await this._translateClipPlane(event);
        };

        const upCallback = () => {
            this._limitClipPlaneToWorldBoundingBox();
            this._inputs.cursor = "";
            if (!dragStarted) return;

            dragStarted = false;
            this._api.eventDispatcher.dragEnd(this);
            if (this._translatingClipPlane) {
                this._translatingClipPlane.origin = this;
                this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Modified, {detail: this._translatingClipPlane}));
                this._translatingClipPlane = undefined;
            }
        };

        const options = {button, touchCount};
        this._inputs.pointerDown$
            .pipe(
                filter(allowInput(options)),
                mergeMap(addIntersection(this._inputs.picker, [this.model])),
                filter(this._isClipPlane),
                tap(e => this._startTranslating(e)),
                switchMap(() =>
                    this._inputs.pointerMove$.pipe(
                        mergeMap(moveCallback),
                        takeUntil(controlsReleased(this._inputs, options)),
                        finalize(upCallback)
                    )
                )
            ).subscribe();
    }

    private getClipPlane(event: PointerInput): DraggableClipPlane {
        if (this._translatingClipPlane) this._translatingClipPlane = undefined;

        if (!event.intersection || !event.intersection.object) return;

        const m = event.intersection.object;
        return m instanceof DraggableClipPlane ? m as DraggableClipPlane : undefined;
    }

    public add(position: Vector3, normal: Vector3, color?: Color, origin?: any): DraggableClipPlane {
        const clipPlane = this.getModel().addDraggableClipPlane(normal, position, color, origin);
        clipPlane.controlsVisible = this._controlsVisible;
        return clipPlane;
    }

    public remove(plane: Plane, origin?: any): void {
        this.getModel().remove(plane, origin);
    }

    public removeById(id: number, origin?: any): void {
        this.getModel().removeById(id, origin);
    }

    private _isClipPlane = (event: PointerInput) => {
        const cp = this.getClipPlane(event);
        return !!cp && !cp.isRotating();
    }

    private _addClipPlane(event: PointerInput): PointerInput {
        let position: Vector3;
        let normal: Vector3;
        if (this._cursor.intersection.snapLineStart) {
            position = this._cursor.intersection.point.clone();
            normal = this._cursor.intersection.snapLineEnd.clone().sub(this._cursor.intersection.snapLineStart).normalize();
            if (normal.dot(this._api.camera.position.clone().sub(position)) > 0) normal.negate();
        }
        else if (this._cursor.intersection.normal) {
            position = this._cursor.intersection.point.clone().add(this._cursor.intersection.normal.clone().multiplyScalar(0.01));
            normal = this._cursor.intersection.normal.clone().negate();
        }

        const cp = this.add(position, normal, undefined, this);
        cp.intersection = this._cursor.intersection;
        return event;
    }

    clear(origin?: any): void {
        this.getModel().clearDraggablePlanes(origin);
    }

    private _startTranslating(event: PointerInput): PointerInput {
        this._api.inputHandler.cursor = "grabbing";
        const clipPlane = this.getClipPlane(event);

        if (clipPlane)
            this._translatingClipPlane = clipPlane;
        return event;
    }

    private async _translateClipPlane(event: PointerInput): Promise<PointerInput> {
        this._api.inputHandler.cursor = "grabbing";
        const point = screenPositionToRayTracePoint(event, this._api.container);
        this._rayCaster.setFromCamera(point, this._api.camera);
        await this._translatingClipPlane.translate(this._rayCaster.ray);
        this._api.camera.callListeners();
        return event;
    }

    private _getBox3Corners(box: Box3, corners: Vector3[] = []): void {
        corners[0].set(box.min.x, box.min.y, box.min.z);
        corners[1].set(box.max.x, box.min.y, box.min.z);
        corners[2].set(box.min.x, box.max.y, box.min.z);
        corners[3].set(box.min.x, box.min.y, box.max.z);
        corners[4].set(box.max.x, box.max.y, box.min.z);
        corners[5].set(box.min.x, box.max.y, box.max.z);
        corners[6].set(box.max.x, box.min.y, box.max.z);
        corners[7].set(box.max.x, box.max.y, box.max.z);
    }

    private _limitClipPlaneToWorldBoundingBox = (() => {
        const corners = Array.from({length: 8}, () => (new Vector3()));
        const closestCorner: Vector3 = new Vector3();

        return () => {
            this._getBox3Corners(this._api.models.worldBoundingBox.value, corners);
            const side = this._translatingClipPlane.plane.distanceToPoint(corners[0]);
            let shortestDistance = side;
            closestCorner.copy(corners[0]);
            let allOnSameSide = true;

            for (let i = 1; i < corners.length; i++) {
                const distance = this._translatingClipPlane.plane.distanceToPoint(corners[i]);
                if (distance * side < 0) {
                    allOnSameSide = false;
                    break;
                }
                if (Math.abs(distance) < Math.abs(shortestDistance)) {
                    shortestDistance = distance;
                    closestCorner.copy(corners[i]);
                }
            }

            if (allOnSameSide) {
                this._translatingClipPlane.offsetPosition(shortestDistance);
                this._api.camera.callListeners();
            }
        };
    })();
}


