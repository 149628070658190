import {
	Scene,
	ShaderMaterial, Mesh,
	BoxGeometry,
	PerspectiveCamera,
	WebGLCubeRenderTarget, BackSide,
	NoBlending,
	Material,
	WebGLRenderer,
	Texture
} from "three";

export class EquirectangularToCubeGenerator {
    private renderTarget: WebGLCubeRenderTarget;
	private readonly resolution: number;
	private readonly views: Array<{t: number[], u: number[]}>;
	private readonly boxMesh: Mesh;
	private readonly material: Material;
	private readonly camera: PerspectiveCamera;
	private readonly scene: Scene;

	constructor(sourceTexture: Texture, options: any = {}) {
		this.camera = new PerspectiveCamera(90, 1, 0.1, 10);
		this.scene = new Scene();
		this.material = this.createMaterial(sourceTexture);
		this.boxMesh = new Mesh(new BoxGeometry(1, 1, 1), this.material);
		this.scene.add(this.boxMesh);

		this.resolution = options.resolution || 1024;
		this.views = [
			{ t: [1, 0, 0], u: [0, -1, 0] },
			{ t: [- 1, 0, 0], u: [0, -1, 0] },
			{ t: [0, 1, 0], u: [0, 0, 1] },
			{ t: [0, - 1, 0], u: [0, 0, - 1] },
			{ t: [0, 0, 1], u: [0, -1, 0] },
			{ t: [0, 0, - 1], u: [0, - 1, 0] },
		];

		const params = {
			format: options.format || sourceTexture.format,
			magFilter: sourceTexture.magFilter,
			minFilter: sourceTexture.minFilter,
			type: options.type || sourceTexture.type,
			generateMipmaps: sourceTexture.generateMipmaps,
			anisotropy: sourceTexture.anisotropy,
			colorSpace: sourceTexture.colorSpace
		};

		this.renderTarget = new WebGLCubeRenderTarget(this.resolution, params);
	}

	private createMaterial(sourceTexture: Texture): ShaderMaterial {
		const shaderMaterial = new ShaderMaterial({
			uniforms: {
				"equirectangularMap": { value: sourceTexture },
			},

            // language=GLSL
			vertexShader: `
			    varying vec3 localPosition;
        
                void main() {
                    localPosition = position;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }
            `,

            // language=GLSL
			fragmentShader: `
				#include <common>
                varying vec3 localPosition;
                uniform sampler2D equirectangularMap;
                
                vec2 EquirectangularSampleUV(vec3 v) {
                  	vec2 uv = vec2(atan(v.z, v.x), asin(v.y));
                  	uv *= vec2(0.1591, 0.3183); // inverse atan
                  	uv += 0.5;
                  	return uv;
                }
                
                void main() {
                  	vec2 uv = EquirectangularSampleUV(normalize(localPosition));
					uv.x = 0.5-uv.x;
                  	gl_FragColor = texture2D(equirectangularMap, uv);
                }
            `,

			blending: NoBlending,
            side: BackSide
		});

		shaderMaterial.type = 'EquirectangularToCubeGenerator';
		return shaderMaterial;

	}

	update(renderer: WebGLRenderer): WebGLCubeRenderTarget {
		const currentRenderTarget = renderer.getRenderTarget();

		for (let i = 0; i < 6; i++) {
			const v = this.views[i];

			this.camera.position.set(0, 0, 0);
			this.camera.up.set(v.u[0], v.u[1], v.u[2]);
			this.camera.lookAt(v.t[0], v.t[1], v.t[2]);

			renderer.setRenderTarget(this.renderTarget, i);
			renderer.clear();
			renderer.render(this.scene, this.camera);
		}
		renderer.setRenderTarget(currentRenderTarget);
		return this.renderTarget;
	}

	dispose(): void {
		this.renderTarget.dispose();
	}
}


