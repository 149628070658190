import { Api } from "../Api.js";
import { Vector3, Matrix4, Scene, Quaternion } from "three";
import {animationFrameScheduler, Observable} from "rxjs";
import { tap, pairwise } from "rxjs/operators";
import { msElapsed } from "../Animation.js";
import {Web3DCamera} from "../Rendering/Web3DCamera.js";
import {Tool} from "./Tool.js";

export class GamepadNavigation extends Tool {
    static get Name(): string { return "gamepad"; }

    private _enabled: boolean;
    private _api: Api;
    private _camera: Web3DCamera;
    private _scene: Scene;

    private _speed: number = 0;
    private _refOrientation: Quaternion;
    private _refMatrix: Matrix4;
    private _artificialRotationInProgress: boolean = false;
    private _artificialRotation: number = 0;
    private _accelerating: boolean = false;
    private observable: Observable<[number, number]>;

    private readonly BASE_SPEED = 0.015;
    private readonly ACC = 50e-6;
    private readonly ARTIFICIAL_ROT_ANGLE = Math.PI/8;
    private readonly ACCELERATION_THRESHOLD = 0.75;
    private readonly INSTANT_BREAK_THRESHOLD = 0.05;

    get enabled(): boolean { return this._enabled; }

    set enabled(value: boolean) {
        if (this._enabled === value) return;
        this._enabled = value;
        if (this._enabled) this.observable.subscribe();
    }

    get name(): string {
        return GamepadNavigation.Name;
    }

    constructor(api: Api) {
        super();
        this._camera = api.camera;
        this._api = api;
        this._scene = api.scene;

        this._refOrientation = new Quaternion();
        const vrCam = this._api.renderingManager.xr.toVRCamera(this._camera);
        vrCam.getWorldQuaternion(this._refOrientation);
        this._refMatrix = vrCam.matrixWorld.clone();

        this.observable = msElapsed(animationFrameScheduler).pipe(
            pairwise(),
            tap(t => this.move(t[1] - t[0]))
        );
    }

    private move(delta: number): void {
        if (!this.enabled || !window.navigator || !window.navigator.getGamepads) return;

        const gamePads = window.navigator.getGamepads();
        let axisSumX = 0.0;
        let axisSumY = 0.0;

        for (const pad of gamePads) {
            if (pad) {
                axisSumX += pad.axes[0];
                axisSumY -= pad.axes[1];
            }
        }

        const vrCam = this._api.renderingManager.xr.toVRCamera(this._camera);
        const camOrientation = new Quaternion();
        vrCam.getWorldQuaternion(camOrientation);

        const camDir = new Vector3(0,0,-1)
            .applyQuaternion(camOrientation);

        // Instant breaking
        if (this._speed > 0 && axisSumY < this.INSTANT_BREAK_THRESHOLD || this._speed < 0 && axisSumY > -this.INSTANT_BREAK_THRESHOLD)
            this._speed = 0;

        // Accelerating
        if (Math.abs(axisSumY) > this.ACCELERATION_THRESHOLD) {
            this._speed += axisSumY * Math.abs(Math.pow(axisSumY, 2)) * this.ACC * delta;
            this._accelerating = true;
        } else {
            this._speed *= Math.pow(Math.abs(axisSumY), 0.01 * delta);
            this._accelerating = false;
        }

        // Navigation:
        const baseSpeed = this.BASE_SPEED * axisSumY * Math.min(axisSumY*axisSumY, 0.2);
        const dTot = (baseSpeed + this._speed) * delta;
        this._camera.position.add(camDir.clone().multiplyScalar(dTot));

        // Artificial rotation:
        let artificialRotationDelta = 0.0;
        if (Math.abs(axisSumY) < 0.25 && Math.abs(axisSumX) > 0.75) {
            if (!this._artificialRotationInProgress) {
                this._artificialRotationInProgress = true;
                artificialRotationDelta -= Math.sign(axisSumX) * this.ARTIFICIAL_ROT_ANGLE;
            }
        }
        if (Math.abs(axisSumX) < 0.7) {
            this._artificialRotationInProgress = false;
        }
        this._artificialRotation += artificialRotationDelta;
        const newCamDir = new Vector3();
        this._camera.getWorldDirection(newCamDir);
        newCamDir.applyAxisAngle(new Vector3(0,0,1), artificialRotationDelta);
        const camPos = new Vector3();
        this._camera.getWorldPosition(camPos);
        this._camera.lookAt(camPos.clone().add(newCamDir));
    }
}
