import { Api } from "../Api.js";
import {
    Vector3,
    Matrix4,
    Scene,
    Quaternion,
    Camera,
    SphereGeometry,
    MeshBasicMaterial,
    Mesh,
    Object3D,
    Group, BufferGeometry, LineBasicMaterial, Line, Ray, Raycaster, XRTargetRaySpace, DirectionalLight
} from "three";
import {Tool} from "./Tool.js";
import {Vector3Const} from "../Helpers/common-utils.js";
import {IIntersection} from "../Picker/IIntersection.js";
import {MeshPointsGeometry} from "../CustomObjects/MeshPointsGeometry.js";
import {MeshPoints} from "../CustomObjects/MeshPoints.js";
import {createMeshPointIconMaterial} from "../Helpers/utils.js";
import {XRControllerModelFactory} from "three/examples/jsm/webxr/XRControllerModelFactory.js";

/**
 * Simple navigation in VR, based on WebXR and the GamePad API
 * NB: Experimental.
 */
export class XRNavigation extends Tool {
    static get Name(): string { return "xrNavigation"; }

    hapticsEnabled = true;
    useRealControllerModels = true;

    private _enabled: boolean;
    private _initialized = false;
    private _api: Api;
    private _scene: Scene;
    private _speed: number = 0;
    private _refOrientation: Quaternion;
    private _refMatrix: Matrix4;
    private _artificialRotationInProgress: boolean = false;
    private _artificialRotation: number = 0;
    private _accelerating: boolean = false;
    private vrCam: Camera;
    private markerObject: Object3D;
    private textContainer = new Group();
    private buttonCallbacks: Map<number, (pressed: boolean, data?: object) => void> = new Map();
    private raycaster = new Raycaster;

    private readonly BASE_SPEED = 0.015;
    private readonly ACC = 50e-6;
    private readonly ARTIFICIAL_ROT_ANGLE = Math.PI/8;
    private readonly ACCELERATION_THRESHOLD = 0.75;
    private readonly INSTANT_BRAKE_THRESHOLD = 0.05;

    get name(): string { return XRNavigation.Name; }

    get enabled(): boolean { return this._enabled; }

    set enabled(value: boolean) {
        if (this._enabled === value) return;
        this._enabled = value;
        if (this._enabled) this._api.renderingManager.addAnimationFrameListener(this.update);
        else this._api.renderingManager.removeAnimationFrameListener(this.update);
    }

    addButtonCallback(buttonIndex: number, callback: (pressed: boolean, data?: object) => void): void {
        this.buttonCallbacks.set(buttonIndex, callback);
    }

    renderText(text: string, position: Vector3, size: number): void {
        const textures = this._api.textureGenerator.generateTextTextures([{ text, font: `"Open Sans", sans-serif`, size: 2 * size }]);
        const mat = createMeshPointIconMaterial(this._api, textures.atlases[0].texture, size);

        const geom = new MeshPointsGeometry();
        geom.setAttributes(new Float32Array([0,0,0]));

        const obj = new MeshPoints(geom, mat, this._api.camera, this._api.container);
        obj.position.copy(position);
        this.textContainer.add(obj);
    }

    private get _session(): any {
        return this._api.renderingManager.renderer.xr.getSession();
    }

    constructor(api: Api) {
        super();
        this._api = api;
        this._scene = api.scene;

        this._refOrientation = new Quaternion();
        this.vrCam = this._api.renderingManager.xr.toVRCamera(api.camera);
        this.vrCam.getWorldQuaternion(this._refOrientation);
        this._refMatrix = this.vrCam.matrixWorld.clone();
    }

    private initialize(): void {
        if (!this._api.renderingManager?.xr?.cameraRig) return;

        this._initialized = true;

        const xr = this._api.renderingManager.renderer.xr;
        const xrCamRig = this._api.renderingManager.xr.cameraRig;
        this._api.scene.add(this._api.renderingManager.xr.cameraRig);

        let selectorRay: Line;
        {   // Selector ray:
            const geom = new BufferGeometry().setFromPoints([new Vector3(), new Vector3(0, 0, -1).normalize().multiplyScalar(100)]);
            const mat = new LineBasicMaterial({ color: 0xaaffcc });
            mat.transparent = true;
            mat.opacity = 0.8;
            selectorRay = new Line(geom, mat);
        }

        {   // Selection marker:
            const geom = new SphereGeometry(0.1);
            const mat = new MeshBasicMaterial({color: 0x00ff00});
            this.markerObject = new Mesh(geom, mat);
            this.markerObject.visible = false;
            this._api.scene.add(this.markerObject);
        }

        // Initialize controllers:
        for (let i=0; i<2; ++i) {
            const controller = xr.getController(i);
            controller.add(selectorRay.clone());
            xrCamRig.add(controller);

            const controllerGrip = xr.getControllerGrip(i);
            controllerGrip.add(this.createControllerObject(controllerGrip));
            xrCamRig.add(controllerGrip);

            controller.addEventListener('selectstart',    (e) => { this.onSelect(true, e.target, e.data.gamepad); });
            controller.addEventListener('selectend',      (e) => { this.onSelect(false, e.target, e.data.gamepad); });
            controller.addEventListener('squeezestart',   (e) => { this.onSqueeze(true, e.target, e.data.gamepad); });
            controller.addEventListener('squeezeend',     (e) => { this.onSqueeze(false, e.target, e.data.gamepad); });
        }

        {   // Lights, required by controller materials:
            // TODO: Use Web3D materials instead, as in GLTF plugin, to avoid adding light?
            const light = new DirectionalLight( 0xffffff, 3 );
            light.position.set( 0, 0, 6 );
            this._api.scene.add(light);
        }

        this._api.scene.add(this.textContainer);
    }

    update = (() => {
        const that = this;
        let previousTimestamp = 0;
        let delta = 0;
        return (timestamp: number) => {
            delta = timestamp - previousTimestamp;
            previousTimestamp = timestamp;
            if (that.enabled) {
                if (!that._initialized) that.initialize();
                that.move(delta);
            }
        };
    })();

    private hapticsPulse(gamepad: Gamepad, intensity: number = 0.5, duration: number = 100): void {
        if (!this.hapticsEnabled) return;
        void gamepad?.vibrationActuator?.playEffect("dual-rumble", {
            startDelay: 0,
            duration,
            weakMagnitude: intensity,
            strongMagnitude: intensity,
        });
    }

    private onSelect(value: boolean, controller: XRTargetRaySpace, gamepad?: any): void {
        if (this.buttonCallbacks.get(0)) {
            // Action is overridden
            this.callButtonCallback(0, value, controller, gamepad);
            return;
        }

        if (value && controller) this.hapticsPulse(gamepad, 0.25, 100);
        if (value) this.pick(controller, (int: IIntersection) => {
            if (int.point) { // Render marker
                this.markerObject.position.copy(int.point);
                this.markerObject.visible = true;
            }
        });
    }

    private onSqueeze(value: boolean, controller: XRTargetRaySpace, gamepad?: any): void {
        if (this.buttonCallbacks.get(1)) {
            // Action is overridden
            this.callButtonCallback(1, value, controller, gamepad);
            return;
        }
        if (value && controller) this.hapticsPulse(gamepad, 0.25, 200);
        if (value) this.pick(controller, (int: IIntersection) => {
            if (int.point && int.normal) {
                // Teleport:
                const camPos = this._api.renderingManager.xr.cameraRig.position;
                camPos.copy(int.point);
                camPos.add(int.normal); // Offset away from surface
                camPos.z += 1.70 - int.normal.dot(Vector3Const.up); // Body height
            }
        });
    }

    private callButtonCallback(buttonIndex: number, value: boolean, controller: XRTargetRaySpace, gamepad?: any): void {
        const callback = this.buttonCallbacks.get(buttonIndex);
        if (callback) {
            if (value && controller) this.hapticsPulse(gamepad, 0.25, 100);
            this.pick(controller, (int: IIntersection) => {
                if (int.point && int.normal) callback(value, { point: int.point, normal: int.normal });
            });
        }
    }

    private move = (() => {
        const camQuat = new Quaternion();
        const camDir = new Vector3();
        const newCamDir = new Vector3();
        const target = new Vector3();

        return (delta: number): void => {
            if (!this._session) return;
            const sources = this._session.inputSources;
            if (!sources) return;

            const cameraRig = this._api.renderingManager.xr.cameraRig;
            if (!cameraRig) return;

            let axisSumX = 0.0;
            let axisSumY = 0.0;

            // https://www.w3.org/TR/webxr-gamepads-module-1/
            for (const source of sources) {
                const axes = source.gamepad ? source.gamepad.axes : undefined;
                if (axes && axes.length === 4) {
                    axisSumX += axes[0];
                    axisSumY -= axes[1];
                    axisSumX += axes[2];
                    axisSumY -= axes[3];
                }
            }

            this.vrCam = this._api.camera;
            this.vrCam.getWorldQuaternion(camQuat);
            camDir.set(0,0,-1).applyQuaternion(camQuat);

            // Instant braking:
            if (this._speed > 0 && axisSumY < this.INSTANT_BRAKE_THRESHOLD || this._speed < 0 && axisSumY > -this.INSTANT_BRAKE_THRESHOLD)
                this._speed = 0;

            // Accelerating:
            if (Math.abs(axisSumY) > this.ACCELERATION_THRESHOLD) {
                this._speed += axisSumY * Math.pow(axisSumY, 2) * this.ACC * delta;
                this._accelerating = true;
            } else {
                this._speed *= Math.pow(Math.abs(axisSumY), 0.01 * delta);
                this._accelerating = false;
            }

            // Navigation:
            const baseSpeed = this.BASE_SPEED * axisSumY * Math.min(axisSumY * axisSumY, 0.2);
            const dTot = (baseSpeed + this._speed) * delta;
            cameraRig.position.addScaledVector(camDir, dTot);

            // Artificial rotation:
            let artificialRotationDelta = 0.0;
            if (Math.abs(axisSumY) < 0.5 && Math.abs(axisSumX) > 0.75) {
                if (!this._artificialRotationInProgress) {
                    if (sources[0]) this.hapticsPulse(sources[0].gamepad, 0.25, 50);
                    if (sources[1]) this.hapticsPulse(sources[1].gamepad, 0.25, 50);
                    this._artificialRotationInProgress = true;
                    artificialRotationDelta -= Math.sign(axisSumX) * this.ARTIFICIAL_ROT_ANGLE;
                }
            }
            if (Math.abs(axisSumX) < 0.7) this._artificialRotationInProgress = false;
            this._artificialRotation += artificialRotationDelta;
            cameraRig.getWorldDirection(newCamDir);
            newCamDir.applyAxisAngle(Vector3Const.up, artificialRotationDelta);
            cameraRig.getWorldPosition(target);
            cameraRig.lookAt(target.add(newCamDir));
        };
    })();

    private createControllerObject = (() => {
        let factory: XRControllerModelFactory;

        return (controller: Group): Object3D => {
            if (this.useRealControllerModels) { // Fetch and render actual controller model:
                if (!factory) factory = new XRControllerModelFactory();
                return factory.createControllerModel(controller);
            } else { // Render dummy model:
                const geom = new SphereGeometry(0.05, 5, 5);
                const mat = new MeshBasicMaterial({color: 0x333333});
                return new Mesh(geom, mat);
            }
        };
    })();

    private getRay(controller: XRTargetRaySpace): Ray {
        controller.updateMatrixWorld();
        this.raycaster.setFromXRController(controller);
        return this.raycaster.ray;
    }

    private pick(controller: XRTargetRaySpace, callback: (int: IIntersection) => void): void {
        const ray = this.getRay(controller);
        const intersections = this._api.inputHandler.picker.pickRay(ray, undefined);
        intersections.then(int => {
            if (!int) return;
            callback(int);
        });
    }
}
