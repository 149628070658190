import {
    Box3,
    BufferGeometry,
    Color, DoubleSide,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Plane,
    Ray,
    Scene,
    Texture,
    Vector3
} from "three";
import {BehaviorSubject} from "rxjs";
import {Web3DCamera} from "./Rendering/Web3DCamera.js";

import {LineMaterial} from "three/examples/jsm/lines/LineMaterial.js";

import {ClipPlaneEventName, GeometryType} from "./common.js";
import {Api} from "./Api.js";
import {Web3DMeshPointsMaterial} from "./Rendering/Web3DMeshPointsMaterial.js";
import {MeshPoints} from "./CustomObjects/MeshPoints.js";
import {IIntersection} from "./Picker/IIntersection.js";
import {TransformControls} from "./Tools/TransformControls.js";
import {RenderingManager} from "./Rendering/RenderingManager.js";
import {closestPointBetweenRays, createMeshPointIconMaterial, getBoxCorners} from "./Helpers/utils.js";
import {Vector3Const} from "./Helpers/common-utils.js";
import {MeshPointsGeometry} from "./CustomObjects/MeshPointsGeometry.js";
import {MeshLine} from "./CustomObjects/MeshLine.js";
import {MeshLineGeometry} from "./CustomObjects/MeshLineGeometry.js";
import {AlignmentPickableModel} from "./Model.js";

export class ClipPlane extends Object3D {
    private _lineMaterial: LineMaterial;
    private _handleMaterial: Web3DMeshPointsMaterial;
    private _handleBgMaterial: Web3DMeshPointsMaterial;
    private _surfaceMaterial: MeshBasicMaterial;
    private visualPlane: Mesh;
    handle: MeshPoints;
    travelRay: Ray;
    intersection: IIntersection;
    private transformControls: TransformControls;
    private rotationObject = new Object3D();
    private rotationResetNormal: Vector3;

    plane: Plane;
    selected: boolean;
    origin?: any;

    get coplanarPoint(): Vector3 {
        return this.position;
    }

    set coplanarPoint(value: Vector3) {
        this.position.copy(value);
    }

    get normal(): Vector3 {
        return this.plane.normal;
    }

    get constant(): number {
        return this.plane.constant;
    }

    set constant(v: number) {
        this.plane.constant = v;
    }

    distanceToPoint(point: Vector3): number {
        return this.plane.distanceToPoint(point);
    }

    setPosition(position: Vector3): void {
        this.position.copy(position);
        this.constant = -this.normal.dot(position);
        this.handle.position.copy(position);
        this.updateVisual();
    }

    offsetPosition(offset: number): void {
        const pos = this.position.clone().add(this.normal.clone().multiplyScalar(offset));
        this.setPosition(pos);
    }

    get color(): Color {
        return this._color;
    }

    set color(color: Color) {
        this._color = color;
        this._handleMaterial.color = color;
        this._lineMaterial.color = color;
        this.renderingManager.redraw();
        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Modified, {detail: this}));
    }

    get controlsVisible(): boolean {
        return this.handle.visible;
    }

    set controlsVisible(visible: boolean) {
        this.handle.visible = visible;
        this.visualPlane.visible = visible;
        this.disableRotation();
        this.renderingManager.redraw();
    }

    constructor(
        position: Vector3,
        normal: Vector3,
        private modelBoundingBox: BehaviorSubject<Box3>,
        private _scissor: Texture,
        private _scissorBg: Texture,
        container: HTMLElement,
        camera: Web3DCamera,
        private _color: Color,
        private _api: Api,
        private renderingManager: RenderingManager,
        private handleScene: Scene
    ) {
        super();
        this.plane = new Plane().setFromNormalAndCoplanarPoint(normal, position);
        this.position.copy(position);
        this.handle = this.createHandle(camera, container);
        handleScene.add(this.handle);
        this.plane = new Plane().setFromNormalAndCoplanarPoint(normal, position);
        this.position.copy(position);
        this.handle.position.copy(position);

        this._lineMaterial = new LineMaterial();
        this._lineMaterial.color = this.color;
        this._lineMaterial.linewidth = 1.4;

        this.visualPlane = this.createPlane();
        this.add(this.visualPlane);
        this.travelRay = new Ray(position, normal);
        modelBoundingBox.subscribe(() => {
            this.remove(this.visualPlane);
            this.visualPlane = this.createPlane();
            this.add(this.visualPlane);
        });
    }

    async translate(ray: Ray): Promise<void> {
        let intersectionPoint: Vector3;
        const alignmentModel = this.intersection?.model as unknown as AlignmentPickableModel;

        // If clip plane is attached to a line geometry, follow the line geometry instead of linear translation
        if (this.intersection && alignmentModel.pickFollowLine &&
            this.intersection.snapGeometryType === GeometryType.LINE) {

            const hit = await  alignmentModel.pickFollowLine(this.intersection.id, ray);
            if (!hit) return;

            const prevNormal = this.normal.clone();
            const newNormal = this.normal.copy(hit.snapLineEnd).sub(hit.snapLineStart).normalize();
            // should be codirected with previous normal
            if (newNormal.dot(prevNormal) < 0) newNormal.negate();

            this.setNormal(newNormal);
            intersectionPoint = hit.point;
        }
        else {
            const copy = new Ray().copy(this.travelRay);
            intersectionPoint = closestPointBetweenRays(copy, ray);
        }

        this.position.copy(intersectionPoint);
        this.handle.position.copy(intersectionPoint);
        this.constant = -this.normal.dot(intersectionPoint);
    }

    invert(): void {
        this.normal.negate();
        this.constant *= -1;
        this.updateVisual();
        this._api.camera.callListeners();
        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Modified, {detail: this}));
    }

    makeVertical(): void {
        this.normal.z = 0;
        this.normal.normalize();
        if (this.normal.equals(Vector3Const.zero)) this.normal.set(1, 0, 0);
        this.updateVisual();
        this._api.camera.callListeners();
        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Modified, {detail: this}));
    }

    makeHorizontal(): void {
        this.normal.set(0, 0, 1);
        this.normal.normalize();
        this.updateVisual();
        this._api.camera.callListeners();
        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Modified, {detail: this}));
    }

    select(): void {
        this.selected = true;
        this.applyColor(this._api.settingsDispatcher.settings.selectionColor);
    }

    deselect(): void {
        this.selected = false;
        this.applyColor(this._color);
        this.disableRotation();
    }

    private applyColor(color: Color): void {
        this._handleMaterial.color = color;
        this._lineMaterial.color = color;
        this._surfaceMaterial.color = this.selected ? color : this._api.settingsDispatcher.settings.selectionColor;
        this._surfaceMaterial.opacity = this.selected ? 0.1 : 0.0;
        this._surfaceMaterial.needsUpdate = true;
        this.renderingManager.redraw();
    }

    private updateVisual(): void {
        this.setNormal(this.normal);
    }

    setNormal(normal?: Vector3): void {
        this.plane.setFromNormalAndCoplanarPoint(normal, this.position);

        const tr = this.travelRay;
        tr.origin = this.position;
        tr.direction = normal;

        this.remove(this.visualPlane);
        this.visualPlane = this.createPlane();
        this.add(this.visualPlane);
        this._api.renderingManager.redraw();
    }

    createHandle(camera: Web3DCamera, container: HTMLElement): MeshPoints {
        const geometry = new MeshPointsGeometry();
        geometry.setPositions(new Float32Array([0, 0, 0]));
        geometry.computeBoundingSphere();

        const pointSize = 44;

        if (!this._handleMaterial)
            this._handleMaterial = createMeshPointIconMaterial(this._api, this._scissor, pointSize, this.color);
        if (!this._handleBgMaterial)
            this._handleBgMaterial = createMeshPointIconMaterial(this._api, this._scissorBg, pointSize);

        geometry.addGroup(0, 1, 0);
        geometry.addGroup(0, 1, 1);
        geometry.boundingSphere.radius = pointSize;

        return new MeshPoints(geometry, [this._handleBgMaterial, this._handleMaterial], camera, container);
    }

    enableRotation(): void {
        if (!this.transformControls) {
            this.transformControls = new TransformControls(this._api);
            this.transformControls.space = "local";
            this.transformControls.rotationSnap = Math.PI / 180 * 15;
            this.transformControls.addEventListener("change", () => this._api.camera.callListeners());
            this.transformControls.addEventListener("dragging-changed", (event) => {
                // @ts-ignore
                if (event.value) this._api.eventDispatcher.dragStart();
                else {
                    this._api.eventDispatcher.dragEnd();
                    this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Modified, {detail: this}));
                }
            });
        }

        this.rotationResetNormal = this.normal.clone();

        this.transformControls.addEventListener("objectChange", () => {
            this.setNormal(Vector3Const.up.clone().applyQuaternion(this.rotationObject.quaternion));
        });

        this.rotationObject.position.copy(this.position);
        const mx = new Matrix4().lookAt(this.normal, Vector3Const.zero, Vector3Const.up);
        this.rotationObject.quaternion.setFromRotationMatrix(mx);
        if (!this.rotationObject.parent) this._api.scene.add(this.rotationObject);

        this.transformControls.setMode("rotate");
        this.transformControls.attach(this.rotationObject);
        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.RotationEnabled));
        if (!this.transformControls.parent) this.handleScene.add(this.transformControls);
    }

    disableRotation(): void {
        if (!this.transformControls || !this.transformControls.object) return;

        this.transformControls.detach();
        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.RotationDisabled));
        this.handleScene.remove(this.transformControls);
    }

    removeHandle(): void {
        this.handleScene.remove(this.handle);
    }

    cancelRotation(): void {
        if (!this.isRotating()) return;

        this.disableRotation();
        this.setNormal(this.rotationResetNormal);
    }

    isRotating(): boolean {
        return !!(this.transformControls && this.transformControls.object);
    }

    private getDirectionalOutline(plane: Plane, box: Box3): Vector3[] {
        const tmp = new Vector3();

        const corners = getBoxCorners(box);
        const center = new Vector3();
        plane.projectPoint(box.getCenter(tmp), center);
        const projectedPoints = [];

        const maxVectorA = new Vector3();
        let maxLengthA = 0.0;

        for (const point of corners) {
            const projectedPoint = new Vector3();
            plane.projectPoint(point, projectedPoint).sub(center);
            projectedPoints.push(projectedPoint);

            const length = projectedPoint.lengthSq();
            if (length > maxLengthA) {
                maxLengthA = length;
                maxVectorA.copy(projectedPoint);
            }
        }

        const minVectorA = new Vector3();
        let minLengthA = 0.0;
        const maxVectorB = new Vector3();
        let maxLengthB = 0.0;
        const minVectorB = new Vector3();
        let minLengthB = 0.0;

        const vectorB = new Vector3().crossVectors(plane.normal, maxVectorA);

        for (const vector of projectedPoints) {
            const projA = maxVectorA.dot(vector);
            const projB = vectorB.dot(vector);

            if (projA < minLengthA) {
                minLengthA = projA;
                minVectorA.copy(vector);
            }

            if (projB > maxLengthB) {
                maxLengthB = projB;
                maxVectorB.copy(vector);
            }

            if (projB < minLengthB) {
                minLengthB = projB;
                minVectorB.copy(vector);
            }
        }

        return [
            maxVectorA.add(center),
            minVectorB.add(center),
            minVectorA.add(center),
            maxVectorB.add(center)
        ];
    }

    public createPlane(): Mesh {
        const vertices = this.getDirectionalOutline(this.plane, this.modelBoundingBox.value);
        vertices.forEach(vertex => vertex.sub(this.position));

        const lineSegments: Vector3[] = [];
        for (let i = 0; i < vertices.length; i++) {
            if (i === vertices.length - 1) lineSegments.push(...[vertices[i], vertices[0]]);
            else lineSegments.push(...[vertices[i], vertices[i + 1]]);
        }
        const line = new MeshLine(new MeshLineGeometry(), this._lineMaterial);
        line.update(lineSegments);
        const surfaceGeometry = new BufferGeometry().setFromPoints(vertices);
        surfaceGeometry.setIndex([0, 1, 2, 2, 3, 0]);

        if (!this._surfaceMaterial)
            this._surfaceMaterial = new MeshBasicMaterial({
                color: this._api.settingsDispatcher.settings.selectionColor,
                transparent: true,
                opacity: 0,
                depthTest: false,
                depthWrite: false,
                side: DoubleSide
            });

        const surface = new Mesh(surfaceGeometry, this._surfaceMaterial);
        surface.add(line);

        if (this.visualPlane)
            surface.visible = this.visualPlane.visible;
        return surface;
    }

    dispose(): void {
        this.disableRotation();
        this.removeHandle();
    }
}
