import {Color, Material, MeshBasicMaterial, PointsMaterial, WebGLRenderTarget} from "three";
import {Api} from "../Api.js";

export class DebugRenderPass {
    private pointsMaterial = new PointsMaterial({
        color: new Color(0x029be5),
        opacity: 0.3,
        transparent: true,
        size: 3,
        sizeAttenuation: false,
        depthWrite: false,
        depthTest: false
    });

    private wireframeMaterial = new MeshBasicMaterial({
        color: new Color(0xffffff),
        wireframe: true,
        depthWrite: false,
        depthTest: false
    });

    constructor(private api: Api) {
        api.settingsDispatcher.subscribe("debugRenderVertices", () => api.renderingManager.redraw());
        api.settingsDispatcher.subscribe("debugVerticesColor", () => {
            this.pointsMaterial.color = api.settingsDispatcher.settings.debugVerticesColor;
            api.renderingManager.redraw();
        });

        api.settingsDispatcher.subscribe("debugRenderWireframe", () => api.renderingManager.redraw());
        api.settingsDispatcher.subscribe("debugWireframeColor", () => {
            this.wireframeMaterial.color = api.settingsDispatcher.settings.debugWireframeColor;
            api.renderingManager.redraw();
        });
    }

    render(writeBuffer: WebGLRenderTarget): void {
        if (this.api.settingsDispatcher.settings.debugRenderWireframe) {
            this.api.scene.traverse((o) => {
                // @ts-ignore wireframes only for meshes and empty objects (mesh parents)
                o.visible = !o.geometry || !!(o.isMesh && (o.geometry.wireframeIndex || o.geometry.index && o.geometry.index.array));
            });
            this.renderDebug(writeBuffer, this.wireframeMaterial, null);
            this.api.scene.traverse((o) => o.visible = true);
        }
        if (this.api.settingsDispatcher.settings.debugRenderVertices)
            this.renderDebug(writeBuffer, this.pointsMaterial, this.api.renderingManager.renderer.getContext().POINTS);
    }

    private renderDebug(writeBuffer: WebGLRenderTarget, material: Material, drawMode: GLenum): void {
        const renderer = this.api.renderingManager.renderer;
        this.api.scene.overrideMaterial = material;
        renderer.overrideDrawMode = drawMode;
        renderer.setRenderTarget(writeBuffer);
        renderer.render(this.api.scene, this.api.camera);
        this.api.scene.overrideMaterial = null;
        renderer.overrideDrawMode = null;
    }

    dispose(): void {
        this.pointsMaterial.dispose();
        this.wireframeMaterial.dispose();
    }
}
