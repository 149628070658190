import {SettingsDispatcher} from "./SettingsDispatcher.js";
import {Settings} from "./common.js";

interface EventData {
    hitType: "event";
    eventCategory?: string;
    eventAction?: string;
    eventLabel?: string;
    eventValue?: number | string;
}

interface ExceptionData {
    hitType: "exception";
    description?: string;
    fatal?: boolean;
}

export class Analytics {
    private keywords: string[] = [];

    constructor(private settingsDispatcher: SettingsDispatcher<Settings>) {
        this.init();
        window.addEventListener("error", this.onError);
    }

    private onError = (event: ErrorEvent) => {
        if (!event.error) return;

        const keyword = this.containsViewerKeyword(event.error.stack);
        if (keyword)
            this.logError(event.error.stack + ` (keyword: ${keyword})`);
    }

    private containsViewerKeyword(errorStack: string): string {
        if (!errorStack) return;
        for (const k of this.keywords)
            if (errorStack.includes(k)) return k;
    }

    addViewerErrorStackKeyword(keyword: string): void {
        this.keywords.push(keyword);
    }

    private get enabled(): boolean {
        return this.settingsDispatcher && this.settingsDispatcher.settings.analyticsEnabled &&
            !navigator.userAgent.includes("HeadlessChrome"); // tests are running in headless chrome
    }

    private init(): void {
        // @ts-ignore
        if (!this.enabled || window.dataLayerWeb3D) return;

        // @ts-ignore
        window.dataLayerWeb3D = window.dataLayerWeb3D || [];
        this.gtag('js', new Date());
        this.gtag('config', 'G-82SPHFZ26X');

        ((w,d,s,l,i) => {
            // @ts-ignore
            w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});const f=d.getElementsByTagName(s)[0];const j=d.createElement(s);
            // @ts-ignore
            const dl=l!=='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayerWeb3D','G-82SPHFZ26X');
    }

    private gtag(cmd: string, param1: any, param2?: any): void {
        if (!this.enabled) return;
        // @ts-ignore
        window.dataLayerWeb3D.push(arguments);
    }

    send(data: EventData | ExceptionData): void {
        this.gtag("event", data.hitType, data);
    }

    logLoadEvent(modelId: string): void {
        this.send({hitType: "event", eventCategory: "Model", eventAction: "Load", eventLabel: modelId});
    }

    logError(message: string): void {
        this.send({
            hitType: "exception",
            description: message,
        });
    }

    dispose(): void {
        window.removeEventListener("error", this.onError);
    }
}
