import { Vector2 } from "three";
import { IIntersection } from "./IIntersection.js";
import { Checksum } from "../Helpers/Checksum.js";
import { Model } from "../Model.js";

// pick results with larger complexity are reused for lower complexity pick requests to avoid multiple pick calculations
export const enum PickComplexity {
    NORMAL = 0,
    SNAPPED = 1
}

interface CachedPick {
    listeners: Array<() => void>;
    intersection: IIntersection;
}

export class PickerCache {
    private cache: Map<number, CachedPick> = new Map();
    private checksum = new Checksum();

    async execCached(screenPosition: Vector2, models: Model[], complexity: PickComplexity, command: () => Promise<IIntersection>): Promise<IIntersection> {
        // lets wait for some time in case another pick with larger complexity will be requested, so we could share the result
        await this.sleep( (2 - complexity) * 10);

        // do not use 'await' here, as it is important to return immediately if no result is cached
        let result = this._get(screenPosition, models, complexity);
        if (result instanceof Promise) return await result;
        if (result !== null) return result;

        this._reportProcessingStart(screenPosition, models, complexity);
        result = await command();
        this._reportProcessingEndAndStore(screenPosition, models, complexity, result);
        return result;
    }

    /**
     * returns null if no result was cached
     * returns undefined if cached result was empty
     */
    _get(screenPosition: Vector2, models: Model[], complexity: PickComplexity): Promise<IIntersection> | IIntersection {
        const cachedPick = this.getCompatibleCache(screenPosition, models, complexity);
        if (!cachedPick) return null;
        if (cachedPick.intersection !== null) return cachedPick.intersection;

        return new Promise<IIntersection>(resolve => {
            if (!cachedPick.listeners) cachedPick.listeners = [];
            cachedPick.listeners.push(() => resolve(cachedPick.intersection));
        });
    }

    _reportProcessingStart(screenPosition: Vector2, models: Model[], complexity: PickComplexity): void {
        const cachedPick = this.getCache(screenPosition, models, complexity);
        if (cachedPick) throw new Error("reportProcessingStart() is called multiple times");
        this.setCache(screenPosition, models, complexity, {intersection: null} as CachedPick);
    }

    _reportProcessingEndAndStore(screenPosition: Vector2, models: Model[], complexity: PickComplexity, intersection: IIntersection): void {
        const cachedPick = this.getCache(screenPosition, models, complexity);
        if (cachedPick.intersection !== null) throw new Error("reportProcessingEndAndStore() is called before reportProcessingStart(), or called multiple times");
        cachedPick.intersection = intersection;
        if (cachedPick.listeners)
            cachedPick.listeners.forEach(l => l());
    }

    clear(): void {
        for (const entry of this.cache)
            if (entry[1].intersection !== null) this.cache.delete(entry[0]);
    }

    private getCompatibleCache(screenPosition: Vector2, models: Model[], compatibility: PickComplexity): CachedPick {
        const checksum = this._toChecksum(screenPosition, models);
        // pick with larger complexity are reused for lower complexity pick requests
        for (let i = PickComplexity.SNAPPED; i >= compatibility; i--) {
            const r = this.cache.get(checksum + i);
            if (r) return r;
        }
    }

    private getCache(screenPosition: Vector2, models: Model[], compatibility: PickComplexity): CachedPick {
        const checksum = this._toChecksum(screenPosition, models);
        return this.cache.get(checksum + compatibility);
    }

    private setCache(screenPosition: Vector2, models: Model[], compatibility: PickComplexity, cachedPick: CachedPick): void {
        this.cache.set(this._toChecksum(screenPosition, models) + compatibility, cachedPick);
    }

    _toChecksum(screenPosition: Vector2, models: Model[]): number {
        this.checksum.clear().add(screenPosition.x).add(screenPosition.y);
        if (models) {
            if (models.length === 0) this.checksum.add("empty");
            for (const m of models) this.checksum.add(m.modelId);
        }
        return this.checksum.get();
    }

    private sleep(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
