import { StickyElement } from "./CustomObjects/StickyElement.js";

import { Api } from "./Api.js";

export class HtmlElementPositioner {
    readonly #htmlObjects: Set<StickyElement>;
    #api: Api;

    constructor(api: Api) {
        this.#api = api;
        this.#htmlObjects = new Set();
        this.#api.renderingManager.addBeforeRenderListener(() => this.update());
    }

    addElement(object: StickyElement): void {
        if (!this.#htmlObjects.has(object)) {
            this.#api.container.appendChild(object.element);
            this.#htmlObjects.add(object);
        }
    }

    removeElement(object: StickyElement): void {
        if (this.#htmlObjects.has(object)) {
            this.#api.container.removeChild(object.element);
            this.#htmlObjects.delete(object);
        }
    }

    getElement(element: HTMLDivElement): StickyElement {
        return [...this.#htmlObjects].find(e => e.element === element);
    }

    update(): void {
        const parentClientRect = this.#api.container.getBoundingClientRect();
        for (const htmlObject of this.#htmlObjects)
            htmlObject.updatePosition(parentClientRect);
    }
}
