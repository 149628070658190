import {
    LinearFilter,
    RedFormat,
    Texture,
} from "three";
import {toImage} from "./utils.js";

export interface TextTextures {
    atlases: Array<{
        texture: Texture;
        size: number;
    }>;
    samples: AtlasSample[];
}

export interface AtlasSample {
    atlasIndex: number;
    offsetX: number;
    offsetY: number;
    width: number;
    height: number;
}

export class TextureGenerator {

    static readonly MAX_ATLAS_SIZE = 2048;
    private static readonly TEXT_ATLAS_MARGIN = 4;

    private textures: TextTextures;
    private atlasIndex: number = 0;

    private readonly measurementContext = document.createElement('canvas').getContext('2d');
    private atlasCanvas: HTMLCanvasElement;
    private samplesForCurrentAtlas: number;
    private offsetX: number;
    private offsetY: number;
    private boundingBoxX: number;
    private boundingBoxY: number;

    generateTextTextures(textDescriptions: Array<{text: string, font: string, size: number}>): TextTextures {
        this.textures = { atlases: [], samples: [] } as TextTextures;
        this.atlasIndex = 0;
        const drawCommands = new Array<{fontString: string, text: string, x: number, y: number}>();
        this.resetCanvas();

        for (const td of textDescriptions) {
            const lineSpace = 1.2;
            const lines = td.text.split(/\r?\n/);
            const scaledSize = td.size * devicePixelRatio;
            const fontString = `${ scaledSize }px ${td.font}`;
            this.measurementContext.font = fontString;
            const metrics = this.measurementContext.measureText(td.text);

            let lineHeight = scaledSize * lineSpace;
            let bbAscent = scaledSize;

            // Calculating the correct measures for exact BB alignment (not supported in FF & Edge):
            if ("actualBoundingBoxAscent" in metrics && "actualBoundingBoxDescent" in metrics) {
                bbAscent = metrics.actualBoundingBoxAscent;
                lineHeight = bbAscent + metrics.actualBoundingBoxDescent;
            }

            const height = scaledSize * 1.2 * (lines.length - 1) + lineHeight;

            // The width of the texture is the width of the widest row:
            let width = 0;
            for (const l of lines) {
                const w = this.measurementContext.measureText(l).width;
                if (w > width) width = w;
            }

            // If does not fit at current offset, switch to a new row:
            if (!this.fits(width, height, TextureGenerator.TEXT_ATLAS_MARGIN)) {
                this.offsetX = 0;
                this.offsetY = this.boundingBoxY;
            }

            // If still cannot fit, consider atlas as full, store it as a texture, and start a new one:
            if (!this.fits(width, height, TextureGenerator.TEXT_ATLAS_MARGIN)) {
                this.makeTextAtlasTexture(drawCommands);
                this.resetCanvas();
            }

            // Draw the text on the canvas:
            for (let i = 0; i < lines.length; ++i)
                drawCommands.push({
                    fontString: fontString,
                    text: lines[i],
                    x: this.offsetX + 1,
                    y: this.offsetY + bbAscent + i * scaledSize * lineSpace + 1
                });

            // Store as an atlas sample:
            this.textures.samples.push(this.createAtlasSample(width, height));

            // Update the atlas object
            this.boundingBoxX = Math.max(this.boundingBoxX, this.offsetX + width + TextureGenerator.TEXT_ATLAS_MARGIN);
            this.boundingBoxY = Math.max(this.boundingBoxY, this.offsetY + height + TextureGenerator.TEXT_ATLAS_MARGIN);
            this.offsetX += width + TextureGenerator.TEXT_ATLAS_MARGIN;
        }

        // If current atlas contains textures, store it as a texture:
        if (this.samplesForCurrentAtlas > 0) this.makeTextAtlasTexture(drawCommands);

        return this.textures;
    }

    private resetCanvas(): void {
        this.samplesForCurrentAtlas = 0;
        this.offsetX = 0;
        this.offsetY = 0;
        this.boundingBoxX = 0;
        this.boundingBoxY = 0;
    }

    private fits(width: number, height: number, margin: number): boolean {
        return  this.offsetX + width + margin <= TextureGenerator.MAX_ATLAS_SIZE &&
                this.offsetY + height + margin <= TextureGenerator.MAX_ATLAS_SIZE;
    }

    private createAtlasSample(width: number, height: number): AtlasSample {
        this.samplesForCurrentAtlas++;
        return {
            atlasIndex: this.atlasIndex,
            offsetX: this.offsetX,
            offsetY: this.offsetY,
            width: width + 2,
            height: height + 2,
        };
    }

    private makeTextAtlasTexture(drawCommands: Array<{fontString: string, text: string, x: number, y: number}>): void {

        // Crating a square canvas as small as possible, containing the bounding box of its textures:
        const atlasSize = Math.max(this.boundingBoxX, this.boundingBoxY);
        this.atlasCanvas = document.createElement('canvas');
        this.atlasCanvas.width = atlasSize;
        this.atlasCanvas.height = atlasSize;

        // Drawing the text on the canvas and emptying the command array:
        const ctx = this.atlasCanvas.getContext('2d');
        ctx.fillStyle = "#ffffff";
        for (const cmd of drawCommands) {
            ctx.font = cmd.fontString;
            ctx.fillText(cmd.text, cmd.x, cmd.y);
        }
        drawCommands.length = 0;

        // Creating the atlas:
        this.textures.atlases.push({
            texture: TextureGenerator.createTextTexture(this.atlasCanvas),
            size: atlasSize,
        });
        this.atlasIndex++;
        disposeOfCanvas(this.atlasCanvas);
        this.atlasCanvas = undefined;
    }

    private static createTextTexture(canvas: HTMLCanvasElement): Texture {
        const texture = new Texture();
        const disposeImage = () => {
            texture.image.close();
            texture.image = undefined;
        };
        toImage(canvas).then(image => {
            texture.image = image;
            texture.needsUpdate = true;
            texture.onUpdate = disposeImage;
        });
        texture.format = RedFormat;
        texture.minFilter = LinearFilter;
        texture.magFilter = LinearFilter;
        texture.generateMipmaps = false;
        texture.premultiplyAlpha = true;
        return texture;
    }
}

// Some iOS versions have a bug where canvas elements are not properly garbage collected. Safer to
// dispose of it explicitly:
// Ref: https://bugs.webkit.org/show_bug.cgi?id=195325
const disposeOfCanvas = (canvas: HTMLCanvasElement) => {
    canvas.width = 0;
    canvas.height = 0;
    canvas.remove();
};
