import { EventDispatcher } from "./EventDispatcher.js";

export class Selection extends Map<string, number[]> {
    private listeners: Array<(origin: any) => void>;

    startSelectionEventEmitter(eventDispatcher: EventDispatcher): void {
        this.subscribe((origin) => {
            const count = Array.from(this.keys())
                .reduce((c, current) => c + this.get(current).length, 0);
            eventDispatcher.selected({ count: count, origin: origin });
        });
    }

    setOnly(modelId: string, ids: number[], origin?: any): void {
        super.clear();
        super.set(modelId, ids);
        this.callListeners(origin);
    }

    override set(modelId: string, ids: number[], origin?: any): this {
        super.set(modelId, ids);
        this.callListeners(origin);
        return this;
    }

    override delete(modelId: string, origin?: any): boolean {
        const result = super.delete(modelId);
        this.callListeners(origin);
        return result;
    }

    add(modelId: string, ids: number[], origin?: any): void {
        ids = this.has(modelId) ? [...this.get(modelId), ...ids] : ids;
        const set = new Set(ids);

        if (this.has(modelId)) {
            const newSelection = [...set];
            super.set(modelId, newSelection);
        } else {
            super.set(modelId, [...set]);
        }
        this.callListeners(origin);
    }

    subtract(modelId: string, ids: number[], origin?: any): void {
        if (this.has(modelId)) {
            const newIds = this.get(modelId).filter(e => !ids.includes(e));
            if (newIds.length > 0) super.set(modelId, newIds);
            else super.delete(modelId);
        }
        this.callListeners(origin);
    }

    override clear(origin?: any): void {
        super.clear();
        this.callListeners(origin);
    }

    private callListeners(origin: any): void {
        if (this.listeners)
            this.listeners.forEach(l => l(origin));
    }

    subscribe(listener: (origin: any) => void): void {
        if (!this.listeners) this.listeners = [];
        this.listeners.push(listener);
    }

    unsubscribe(listener: () => void): void {
        this.listeners.splice(this.listeners.indexOf(listener), 1);
    }

    // Use to perform multiple operations with selection and make listeners be called only once
    change(callback: () => void, origin?: any): void {
        const origListeners = this.listeners;
        this.listeners = undefined;
        callback();
        this.listeners = origListeners;
        this.callListeners(origin);
    }
}
