import {SinglePointerTool} from "./SinglePointerTool.js";
import {Web3DCamera} from "../Rendering/Web3DCamera.js";
import {InputHandler, PointerInput} from "../InputHandler.js";
import {MouseButton} from "../common.js";
import {Observable, Subscription} from "rxjs";
import {MathUtils, Vector3} from "three";
import {Api} from "../Api.js";
import {Vector3Const} from "../Helpers/common-utils.js";

export class PanoramaControls extends SinglePointerTool {
    static get Name(): string { return "panoramaControls"; }

    #inputs: InputHandler;
    #camera: Web3DCamera;
    #dragObservable: Observable<PointerInput>;
    #pinchObservable: Observable<PointerInput>;
    #dragHandle: Subscription;
    #zoomHandle: Subscription;
    #pinchHandle: Subscription;

    #isUserInteracting = false;
    lon = 0;
    lat = 0;
    #onPointerDownPointerX = 0;
    #onPointerDownPointerY = 0;
    #onPointerDownLon = 0;
    #onPointerDownLat = 0;

    minFov = 12.5;
    maxFov = 137.5;

    get name(): string {
        return PanoramaControls.Name;
    }

    constructor(api: Api) {
        super();
        this.#inputs = api.inputHandler;
        this.#camera = api.camera;
        this.mouseButton = MouseButton.left;
        this.touchCount = 1;

        this.#dragObservable = this.#inputs.createDragObservable(this.observableOptions,
            e => this.#downCallback(e),
            e => this.#moveCallback(e),
            e => this.#upCallback(e));

        let pinchPrevDistance: number;
        this.#pinchObservable = this.#inputs.createPinchObservable(
            (e, d) => pinchPrevDistance = d,
            (e, d) => {
                this.#zoomCallback((pinchPrevDistance - d) * 0.1);
                pinchPrevDistance = d;
                return e;
            });
    }

    set enabled(enabled: boolean) {
        if (this.#dragHandle) {
            this.#dragHandle.unsubscribe();
            this.#dragHandle = null;
            this.#zoomHandle.unsubscribe();
            this.#pinchHandle.unsubscribe();
        }
        if (!enabled) return;
        this.#dragHandle = this.#dragObservable.subscribe();
        this.#zoomHandle = this.#inputs.zoom$.subscribe((e) => this.#zoomCallback(e.speed));
        this.#pinchHandle = this.#pinchObservable.subscribe();

        this.#cameraRotationToLatLon();
        this.updateCamera();
    }

    get enabled(): boolean {
        return !!this.#dragHandle;
    }

    #downCallback(e: PointerInput): PointerInput {
        this.#onPointerDownPointerX = e.screenX;
        this.#onPointerDownPointerY = e.screenY;
        this.#isUserInteracting = true;
        this.#cameraRotationToLatLon();
        this.#onPointerDownLon = this.lon;
        this.#onPointerDownLat = this.lat;
        return e;
    }

    #moveCallback(e: PointerInput): PointerInput {
        if (this.#isUserInteracting) {
            const x = e.screenX;
            const y = e.screenY;
            const fov = MathUtils.degToRad(this.#camera.fov);
            this.lon = ( x - this.#onPointerDownPointerX ) / this.#inputs.container.clientWidth * fov * this.#inputs.container.clientWidth / this.#inputs.container.clientHeight + this.#onPointerDownLon;
            this.lat = ( -y + this.#onPointerDownPointerY ) / this.#inputs.container.clientHeight * fov + this.#onPointerDownLat;
            this.updateCamera();
        }
        return e;
    }

    #cameraRotationToLatLon(): void {
        if (this.#camera.isInitial()) {
            this.lat = Math.PI / 2;
            this.lon = 0;
            return;
        }

        const forward = Vector3Const.threejsCameraForward.clone().applyQuaternion(this.#camera.quaternion);
        this.lat = Math.acos(forward.z);
        this.lon = Math.atan2(forward.y, forward.x);
    }

    updateCamera(): void {
        this.#camera.lookAt(new Vector3(
            Math.sin(this.lat) * Math.cos(this.lon),
            Math.sin(this.lat) * Math.sin(this.lon),
            Math.cos(this.lat),
        ).add(this.#camera.position));
        this.#camera.callListeners();
    }

    #updateCameraFov(fov: number): void {
        this.#camera.fov = MathUtils.clamp(fov, this.minFov, this.maxFov);
        this.#camera.updateProjectionMatrix();
        this.#camera.callListeners();
    }

    #upCallback(e: PointerInput): PointerInput {
        this.#isUserInteracting = false;
        return e;
    }

    #zoomCallback(delta: number): void {
        this.#updateCameraFov(this.#camera.fov + delta * 3);
    }
}
