import {PassComposer} from "../PassComposer.js";
import {DepthTexture, FloatType, RenderTarget, Texture} from "three";
import {Pass} from "three/examples/jsm/postprocessing/Pass.js";
import {WebGPUEdgesAndSsaoRenderPass} from "./WebGPUEdgesAndSsaoRenderPass.js";
import {WebGPUSelectionEffectPass} from "./WebGPUSelectionEffectPass.js";
import {WebGPUBackground} from "./WebGPUBackground.js";
import {Api} from "../../Api.js";
import {RenderingManager} from "../RenderingManager.js";
import {WebGPURenderer, texture, PostProcessing, fxaa, ShaderNodeObject, FXAANode} from "../../Three.WebGPU.js";

const trueFn = () => true;

export class WebGPUPassComposer extends PassComposer {
    edgesAndSsaoRenderPass: WebGPUEdgesAndSsaoRenderPass;
    selectionEffectPass: WebGPUSelectionEffectPass;
    background: WebGPUBackground;
    renderTarget: RenderTarget;
    private readonly postProcessing: PostProcessing;
    private readonly fxaaPass: ShaderNodeObject<FXAANode>;
    private antialias: boolean;

    get colorTexture(): Texture {
        return this.renderTarget.texture;
    }

    get normalTexture(): Texture {
        return this.edgesAndSsaoRenderPass.normalTexture;
    }

    override get depthTexture(): DepthTexture {
        return this.renderTarget.depthTexture;
    }

    constructor(api: Api, renderingManager: RenderingManager) {
        super(api, renderingManager);

        this.background = new WebGPUBackground(this.renderingManager, this.api.settingsDispatcher, this.api.camera);
        this.selectionEffectPass = new WebGPUSelectionEffectPass(this.api.camera, this.api.settingsDispatcher.settings);
        this.api.selectionEffectPass = this.selectionEffectPass;

        this.renderTarget = new RenderTarget(this.renderingManager.width, this.renderingManager.height, {
            stencilBuffer: false,
            depthTexture: new DepthTexture(undefined, undefined, FloatType),
        });
        this.edgesAndSsaoRenderPass = new WebGPUEdgesAndSsaoRenderPass(this.api, this.renderingManager);

        this.postProcessing = new PostProcessing(this.renderingManager.renderer as any as WebGPURenderer);
        this.postProcessing.outputColorTransform = false;
        this.fxaaPass = fxaa(texture(this.renderTarget.texture));

        this.postProcessing.outputNode = this.fxaaPass;

        this.updateFxaa();
        this.api.settingsDispatcher.subscribe("antialias", () => this.updateFxaa());
        this.api.settingsDispatcher.subscribe("fxaa", () => this.updateFxaa());
    }

    override render(renderMain: boolean = true): void {
        const renderer = this.renderingManager.renderer as any; // WebGPURenderer

        renderer.autoClear = true;
        renderer.autoClearColor = true;
        renderer.autoClearDepth = true;

        if (renderMain) {
            renderer.setRenderTarget(this.renderTarget);

            renderer.setClearColor(0x000000, 0); // TODO: This seems to do nothing (transparent backgrounds will be white, whereas in WebGL they are black)

            this.background.render();

            renderer.autoClear = false;
            renderer.autoClearColor = false;
            renderer.autoClearDepth = false;

            this.edgesAndSsaoRenderPass.render(renderer, this.renderTarget, true, trueFn);

            renderer.autoClear = true;
            renderer.autoClearColor = true;
            renderer.autoClearDepth = true;
            this.selectionEffectPass.render(renderer, this.renderTarget);
            renderer.autoClear = true;
            renderer.autoClearColor = true;
            renderer.autoClearDepth = true;
        }

        renderer.setRenderTarget( null );
        this.postProcessing.render();
    }

    private updateFxaa(): void {
        this.antialias = this.api.settingsDispatcher.settings.fxaa !== false;

        if (this.antialias) this.postProcessing.outputNode = this.fxaaPass;
        else this.postProcessing.outputNode = texture(this.renderTarget.texture);
        this.postProcessing.needsUpdate = true;

        this.postProcessing.needsUpdate = true;
        if (this.api.renderingManager)
            this.api.renderingManager.redraw();
    }

    override setSize(width: number, height: number): void {
        this.renderTarget.setSize(width, height);
        this.edgesAndSsaoRenderPass.setSize(width, height);
        this.selectionEffectPass.setSize(width, height);
    }

    // TODO: Implement:
    addPassBeforeMain(pass: Pass): void {}
    addPassBeforeSelection(pass: Pass): void {}
    addPassAfterSelection(pass: Pass): void {}
    addPassBeforeAntialiasing(pass: Pass): void {}
    addPassAfterAntialiasing(pass: Pass): void {}

    override dispose(): void {
        this.background.dispose();
        this.edgesAndSsaoRenderPass.dispose();
        this.selectionEffectPass.dispose();
        this.copyPass.dispose();
        this.renderTarget.dispose();
    }
}
