import {Tool} from "./Tool.js";
import {ClipPlaneEventName, MouseButton, Settings, SnappingTool, SnapType} from "../common.js";
import {Ray, Raycaster, Vector3, Box3, Plane, Color, Texture, Vector2, Scene} from "three";
import {ClipPlaneModel} from "../ClipPlaneModel.js";
import {Cursor3D} from "../Picker/Cursor3D.js";
import {
    addIntersection,
    allowInput,
    PointerObservableOptions,
    controlsReleased,
    InputHandler,
    PointerInput, ScrollEvent} from "../InputHandler.js";
import {Api} from "../Api.js";
import { finalize, Subscription } from "rxjs";
import { debounceTime, filter, mergeMap, switchMap, takeUntil, tap} from "rxjs/operators";
import {Key} from "ts-key-enum";
import {screenPositionToRayTracePoint} from "../Picker/Picker.js";
import {ClipPlane} from "../ClipPlane.js";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass.js";
import {loadPointIconTexture} from "../Helpers/utils.js";

export class ClipPlaneTool extends Tool implements SnappingTool {
    static get Name(): string { return "clipPlane"; }

    private readonly _scissor: Texture;
    private readonly _scissorBg: Texture;
    private _handleRenderPass: RenderPass;
    private handleOriginalWorldPositions: Map<number, Vector3> = new Map();
    private handleScreenPositions: Map<number, Vector2> = new Map();

    private model: ClipPlaneModel;
    private _tapsHandle: Subscription;
    private _rayCaster: Raycaster;
    private _translatingClipPlane: ClipPlane;
    private _cursor: Cursor3D;
    private _snapTypes: SnapType[] = [SnapType.FACE];
    private observableOptions: PointerObservableOptions;
    private _controlsVisible: boolean = true;

    #clipPlanes: ClipPlane[] = [];

    get allowedSnapTypes(): SnapType[] {
        return [SnapType.FACE, SnapType.LINE];
    }

    get snapTypes(): SnapType[] {
        return this._snapTypes;
    }

    set snapTypes(value: SnapType[]) {
        this._snapTypes = value;
        if (this.enabled) this._cursor.snapTypes = value;
    }

    get name(): string {
        return ClipPlaneTool.Name;
    }

    set intersectingClipPlanes(value: boolean) {
        this.settings.intersectingClipPlanes = value;
    }

    get intersectingClipPlanes(): boolean {
        return this.settings.intersectingClipPlanes;
    }

    set enabled(enabled: boolean) {
        if (this._tapsHandle) {
            this._tapsHandle.unsubscribe();
            this._tapsHandle = null;
            this._cursor.unsubscribe();
        }
        if (!enabled) return;

        this._cursor = this._api.cursor;
        this._cursor.snapTypes = this.snapTypes;
        this._cursor.subscribe();

        const taps = this._inputs.createSnappedTapObservable(this._cursor, this.observableOptions);
        this._tapsHandle = taps.subscribe(event => {
            this._addClipPlane(event);
        });
    }

    get enabled(): boolean {
        return !!this._tapsHandle;
    }

    constructor(
        private _inputs: InputHandler,
        private _api: Api,
        private settings: Settings
    ) {
        super();
        this.settings.clipPlaneCuts = false;
        this.settings.intersectingClipPlanes = false;

        this._rayCaster = new Raycaster();

        this.observableOptions = {
            button: MouseButton.left,
            touchCount: 1
        };

        this._scissor = loadPointIconTexture(this._api.staticRootUrl + "images/scissors.png");
        this._scissorBg = loadPointIconTexture(this._api.staticRootUrl + "images/icon_bg.png");

        this._handleRenderPass = new RenderPass(new Scene(), this._api.camera);
        this._handleRenderPass.clear = false;
        this._api.renderingManager.composer.addPassAfterSelection(this._handleRenderPass);

        this.startKeyListener();
        this.setupWheelTranslation();
    }

    private setupWheelTranslation(): void {
        const tool = this;
        let wheelStarted: boolean = false;
        this._api.inputHandler.wheel$.pipe(
            mergeMap(async (event: ScrollEvent) => {
                if (!wheelStarted && (event.originalEvent as WheelEvent).shiftKey) {
                    this._api.eventDispatcher.dragStart(tool);
                    wheelStarted = true;
                }
                return event;
            }),
            mergeMap(async (e: ScrollEvent) => {
                if (wheelStarted) {
                    for (const cp of this.getClipPlanes()) {
                        if (cp.selected) {
                            const speed = -e.speed * cp.plane.distanceToPoint(this._api.camera.position) * 0.04;
                            const ray = new Ray(
                                cp.travelRay.direction.clone().multiplyScalar(speed).add(cp.position),
                                cp.travelRay.direction.clone().cross(this._api.camera.getWorldDirection(new Vector3())));
                            await cp.translate(ray);
                        }
                    }
                    this._api.camera.callListeners();
                }
            }),
            debounceTime(200),
            tap(() => {
                if (wheelStarted) {
                    this._api.eventDispatcher.dragEnd(tool);
                    for (const cp of this.getClipPlanes()) {
                        if (cp.selected) {
                            cp.origin = this;
                            this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Modified, {detail: cp}));
                        }
                    }
                }
                wheelStarted = false;
            })
        ).subscribe();
    }

    get controlsVisible(): boolean {
        return this._controlsVisible;
    }

    set controlsVisible(visible: boolean) {
        this._controlsVisible = visible;
        for (const cp of this.getClipPlanes())
            cp.controlsVisible = visible;
    }

    private startKeyListener(): void {
        this._api.inputHandler.keyDown$.subscribe((e: KeyboardEvent) => {
            if (e.code === Key.Delete || e.code === Key.Backspace)
                this.removeSelected();

            if (e.code === Key.Tab) {
                this.selectNext();
                e.preventDefault();
            }
        });
    }

    private selectNext(): void {
        const planes = this.getClipPlanes();
        for (let i = 0; i < planes.length; i++) {
            if (!planes[(i + 1) % planes.length].selected && planes[i].selected) {
                planes[(i + 1) % planes.length].select();
                planes[i].deselect();
                break;
            }
        }
    }

    getClipPlanes(): ClipPlane[] {
        return this.#clipPlanes;
    }

    private getModel(): ClipPlaneModel {
        if (this.model) return this.model;

        this.model = this._api.models.get(ClipPlaneModel.ModelId) as ClipPlaneModel;

        if (!this.model) {
            this.model = new ClipPlaneModel(this._api);
            this._api.models.add(this.model);
        }

        this.subscribeToDoubleClick();
        this.subscribeToMovePlanes();

        return this.model;
    }

    private subscribeToDoubleClick(): void {
        const doubleTap = this._inputs.createDoubleTapObservable(this.observableOptions).pipe(
            filter(this._isClipPlane)
        );
        doubleTap.subscribe(event => {
            const cp = this.getClipPlane(event);
            cp.origin = this;
            cp.invert();
        });
    }

    private subscribeToMovePlanes(): void {
        const button = MouseButton.left;
        const touchCount = 1;

        let dragStarted = false;
        const moveCallback = async (event: PointerInput) => {
            if (!dragStarted) {
                this._api.eventDispatcher.dragStart(this);
                dragStarted = true;
            }
            await this._translateClipPlane(event);
        };

        const upCallback = () => {
            this._limitClipPlaneToWorldBoundingBox();
            this._inputs.cursor = "";
            if (!dragStarted) return;

            dragStarted = false;
            this._api.eventDispatcher.dragEnd(this);
            if (this._translatingClipPlane) {
                this._translatingClipPlane.origin = this;
                this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Modified, {detail: this._translatingClipPlane}));
                this._translatingClipPlane = undefined;
            }
        };

        const options = {button, touchCount};
        this._inputs.pointerDown$
            .pipe(
                filter(allowInput(options)),
                mergeMap(addIntersection(this._inputs.picker, [this.model])),
                filter(this._isClipPlane),
                tap(e => this._startTranslating(e)),
                switchMap(() =>
                    this._inputs.pointerMove$.pipe(
                        mergeMap(moveCallback),
                        takeUntil(controlsReleased(this._inputs, options)),
                        finalize(upCallback)
                    )
                )
            ).subscribe();
    }

    private getClipPlane(event: PointerInput): ClipPlane {
        if (this._translatingClipPlane) this._translatingClipPlane = undefined;

        if (!event.intersection || !event.intersection.object) return;

        const m = event.intersection.object;
        return m instanceof ClipPlane ? m : undefined;
    }

    public add(position: Vector3, normal: Vector3, color?: Color, origin?: any): ClipPlane {
        const clipPlane = this.addClipPlane(normal, position, color, origin);
        clipPlane.controlsVisible = this._controlsVisible;
        return clipPlane;
    }

    addClipPlane(normal: Vector3, position: Vector3, color: Color, origin?: any): ClipPlane {
        const clipPlane = new ClipPlane(
            position, normal,
            this._api.models.worldBoundingBox,
            this._scissor,
            this._scissorBg,
            this._api.container,
            this._api.camera,
            color || this.settings.clipPlaneColor,
            this._api,
            this._api.renderingManager,
            this._handleRenderPass.scene
        );

        clipPlane.origin = origin;

        this.#clipPlanes.push(clipPlane);
        this.getModel().root.add(clipPlane);
        this._api.renderingManager.clippingPlanes.push(clipPlane.plane);
        this._api.renderingManager.renderer.localClippingEnabled = true;
        this._api.camera.callListeners(); // update clip planes in WorkerCamera
        this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Added, {detail: clipPlane}));
        this.handleOriginalWorldPositions.set(clipPlane.handle.id, clipPlane.position);

        return clipPlane;
    }

    clear(origin?: any): void {
        const l = this.#clipPlanes.length;
        for (let i = l - 1; i >= 0; i--) {
            const clipPlane = this.#clipPlanes[i];
            this._removeClipPlane(clipPlane, origin);
        }
    }

    remove(plane: Plane, origin?: any): void {
        const clipPlane = this.#clipPlanes.find(cp => cp.plane.equals(plane));
        this._removeClipPlane(clipPlane, origin);
    }

    _removeClipPlanePlaneFromRenderingManager(clipPlane: ClipPlane): void {
        for (let i = 0; i < this._api.renderingManager.clippingPlanes.length; i++) {
            if (this._api.renderingManager.clippingPlanes[i].equals(clipPlane.plane)) {
                this._api.renderingManager.clippingPlanes.splice(i, 1);
                break;
            }
        }
    }

    private _removeClipPlane(clipPlane: ClipPlane, origin?: any): void {
        if (clipPlane) {
            // remove clip plane from the list
            this.#clipPlanes.splice(this.#clipPlanes.indexOf(clipPlane), 1);
            // remove clip plane from rendering manager
            this._removeClipPlanePlaneFromRenderingManager(clipPlane);

            clipPlane.origin = origin;
            this._api.eventDispatcher.dispatch(new CustomEvent(ClipPlaneEventName.Removed, {detail: clipPlane}));

            // dispose of visible geometry
            this.getModel().root.remove(clipPlane);
            clipPlane.dispose();

            this.handleOriginalWorldPositions.delete(clipPlane.handle.id);
            this.handleScreenPositions.delete(clipPlane.handle.id);
            this._api.selection.subtract(this.getModel().modelId, [clipPlane.id], this);

            if (this._api.renderingManager.clippingPlanes.length === 0)
                this._api.renderingManager.renderer.localClippingEnabled = false;

            this._api.camera.callListeners();
        }
    }

    removeById(id: number, origin?: any): void {
        const clipPlane = this.#clipPlanes.find(p => p.id === id);
        this._removeClipPlane(clipPlane, origin);
    }

    removeSelected(origin?: any): void {
        for (let i = this.#clipPlanes.length - 1; i >= 0; i--) {
            if (this.#clipPlanes[i].selected) this._removeClipPlane(this.#clipPlanes[i], origin);
        }
    }

    private _isClipPlane = (event: PointerInput) => {
        const cp = this.getClipPlane(event);
        return !!cp && !cp.isRotating();
    }

    private _addClipPlane(event: PointerInput): PointerInput {
        let position: Vector3;
        let normal: Vector3;
        if (this._cursor.intersection.snapLineStart) {
            position = this._cursor.intersection.point.clone();
            normal = this._cursor.intersection.snapLineEnd.clone().sub(this._cursor.intersection.snapLineStart).normalize();
            if (normal.dot(this._api.camera.position.clone().sub(position)) > 0) normal.negate();
        }
        else if (this._cursor.intersection.normal) {
            position = this._cursor.intersection.point.clone().add(this._cursor.intersection.normal.clone().multiplyScalar(0.01));
            normal = this._cursor.intersection.normal.clone().negate();
        }

        const cp = this.add(position, normal, undefined, this);
        cp.intersection = this._cursor.intersection;
        return event;
    }

    private _startTranslating(event: PointerInput): PointerInput {
        this._api.inputHandler.cursor = "grabbing";
        const clipPlane = this.getClipPlane(event);

        if (clipPlane)
            this._translatingClipPlane = clipPlane;
        return event;
    }

    private async _translateClipPlane(event: PointerInput): Promise<PointerInput> {
        this._api.inputHandler.cursor = "grabbing";
        const point = screenPositionToRayTracePoint(event, this._api.container);
        this._rayCaster.setFromCamera(point, this._api.camera);
        await this._translatingClipPlane.translate(this._rayCaster.ray);
        this._api.camera.callListeners();
        return event;
    }

    private _getBox3Corners(box: Box3, corners: Vector3[] = []): void {
        corners[0].set(box.min.x, box.min.y, box.min.z);
        corners[1].set(box.max.x, box.min.y, box.min.z);
        corners[2].set(box.min.x, box.max.y, box.min.z);
        corners[3].set(box.min.x, box.min.y, box.max.z);
        corners[4].set(box.max.x, box.max.y, box.min.z);
        corners[5].set(box.min.x, box.max.y, box.max.z);
        corners[6].set(box.max.x, box.min.y, box.max.z);
        corners[7].set(box.max.x, box.max.y, box.max.z);
    }

    private _limitClipPlaneToWorldBoundingBox = (() => {
        const corners = Array.from({length: 8}, () => (new Vector3()));
        const closestCorner: Vector3 = new Vector3();

        return () => {
            this._getBox3Corners(this._api.models.worldBoundingBox.value, corners);
            const side = this._translatingClipPlane.plane.distanceToPoint(corners[0]);
            let shortestDistance = side;
            closestCorner.copy(corners[0]);
            let allOnSameSide = true;

            for (let i = 1; i < corners.length; i++) {
                const distance = this._translatingClipPlane.plane.distanceToPoint(corners[i]);
                if (distance * side < 0) {
                    allOnSameSide = false;
                    break;
                }
                if (Math.abs(distance) < Math.abs(shortestDistance)) {
                    shortestDistance = distance;
                    closestCorner.copy(corners[i]);
                }
            }

            if (allOnSameSide) {
                this._translatingClipPlane.offsetPosition(shortestDistance);
                this._api.camera.callListeners();
            }
        };
    })();
}


