import {
    PointerInput,
    ScrollEvent,
} from "../InputHandler.js";
import { Api } from "../Api.js";
import { Vector3, Vector2 } from "three";
import { tap, map, mergeMap, debounceTime } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { Web3DCamera } from "../Rendering/Web3DCamera.js";
import { screenPositionToRayTracePoint } from "../Picker/Picker.js";
import {getPerspectiveViewWorldSize, Vector3Const} from "../Helpers/common-utils.js";
import {Models} from "../Models.js";
import {Tool} from "./Tool.js";

export class Zoom extends Tool {
    static get Name(): string { return "zoom"; }

    private readonly _camera: Web3DCamera;
    private _pinchHandle: Subscription;
    private _scrollHandle: Subscription;
    private _zoom$: Observable<void>;
    private _pinch$: Observable<any>;
    private readonly _zoomTarget = new Vector3();
    private _rayTracePoint: Vector2;
    private readonly _pinchSpeed = 0.0035;
    private previousPinchDistance: number;
    private previousTargetTime: number;

    private zoomPosition = new Vector3();
    private zoomOffset = new Vector3();
    private cameraDirection = new Vector3();

    private right = new Vector3();
    private up = new Vector3();
    private forward = new Vector3();

    scrollSpeed = 0.1;
    useWorldCenter: boolean = false;

    get name(): string {
        return Zoom.Name;
    }

    constructor(private _api: Api) {
        super();
        this._camera = _api.camera;

        const inputs = this._api.inputHandler;

        let scrollStarted: boolean = false;
        this._zoom$ = inputs.zoom$.pipe(
            mergeMap(async (event) => {
                if (!scrollStarted) {
                    await this._scrollStart(event);
                    scrollStarted = true;
                }
                return event;
            }),
            tap(e => this.updateTargetIfNeeded(e)),
            map(e => this.applyZoom(this._camera, this.scrollSpeed * e.speed)),
            debounceTime(200),
            tap(() => scrollStarted = false)
        );

        this._pinch$ = inputs.createPinchObservable(
            (e, d) => this.onPinchStart(e, d),
            (e, d) => this.onPinchMove(e, d));
        this.enabled = true;
    }

    private async _scrollStart(event: ScrollEvent | PointerInput): Promise<void> {
        if (this.useWorldCenter) {
            this._api.models.worldBoundingBox.value.getCenter(this._zoomTarget);
            return;
        }
        await this.setZoomTarget(event.x, event.y);
    }

    set enabled(enabled: boolean) {
        if (this._scrollHandle) {
            this._scrollHandle.unsubscribe();
            this._pinchHandle.unsubscribe();
            this._scrollHandle = null;
        }
        if (!enabled) return;

        this._scrollHandle = this._zoom$.subscribe();
        this._pinchHandle = this._pinch$.subscribe();
    }

    get enabled(): boolean {
        return !!this._scrollHandle;
    }

    private setZoomTarget = (() => {
        const screenPoint = new Vector2();

        return async (x: number, y: number): Promise<void> => {
            const intersectionPoint = await this._api.picker.pickForNavigation(new Vector2(x, y));

            screenPoint.set(x,y);
            this._rayTracePoint = screenPositionToRayTracePoint(screenPoint, this._api.container);

            if (intersectionPoint && intersectionPoint.distanceTo(this._camera.position) > this._camera.nearMin * 2) {
                this._zoomTarget.copy(intersectionPoint);
            } else {
                // Zoom in mouse direction
                this._zoomTarget.set(this._rayTracePoint.x, this._rayTracePoint.y, 1).unproject(this._camera);
                // TODO: use Picker.pickNearestModelCenter instead?
                const dist = intersectionPoint ? this._camera.nearMin * 10 : Zoom.getDistanceToNearestModel(this._camera.position, this._api.models, this._camera.nearMin * 10, 1000);
                this._zoomTarget.sub(this._camera.position).normalize().multiplyScalar(dist).add(this._camera.position);
            }
            this.previousTargetTime = performance.now();
        };
    })();

    static getDistanceToNearestModel(position: Vector3, models: Models, min: number, max: number): number {
        let distance = max;
        for (const box of models.getBoundingBoxesIterable()) {
            const d = box.distanceToPoint(position);
            if (distance > d)
                distance = d;
        }
        return Math.max(distance, min);
    }

    private perspectiveZoom(camera: Web3DCamera, deltaZoom: number): void {
        const position = camera.position;
        this._camera.getWorldDirection(this.cameraDirection);
        this.zoomPosition.copy(this.zoomOffset).multiplyScalar(-deltaZoom).add(position);

        camera.position.copy(this.zoomPosition);
        this._api.camera.callListeners();
    }

    private orthographicZoom(camera: Web3DCamera, deltaZoom: number): void {
        camera.getWorldDirection(this.forward);
        this.right.copy(Vector3Const.right);
        this.up.copy(Vector3Const.forward);

        this.right.applyQuaternion(this._camera.quaternion);
        this.up.applyQuaternion(this._camera.quaternion);

        const top = camera.aspect > 1 ? camera.orthoSize / camera.aspect : camera.orthoSize;
        const right = camera.aspect > 1 ? camera.orthoSize : camera.orthoSize * camera.aspect;

        const pickDY = top * this._rayTracePoint.y;
        const pickDX = right * this._rayTracePoint.x;

        const remainingTop = top - pickDY; // distance to original camera top plane from new zoom centre
        const remainingRight = right - pickDX;

        const remainingTopScaled = remainingTop * (1 + deltaZoom); // distance to top plane after zoom scaling applied
        const remainingRightScaled = remainingRight * (1 + deltaZoom);

        const shiftY = remainingTopScaled + pickDY - (1 + deltaZoom) * top; // distances to new camera centre
        const shiftX = remainingRightScaled + pickDX - (1 + deltaZoom) * right;

        this.up.multiplyScalar(shiftY);
        this.right.multiplyScalar(shiftX);

        camera.position.add(this.up);
        camera.position.add(this.right);

        // Smaller values produce depth buffer precision artifacts in SSAO and edges
        camera.orthoSize = Math.max(camera.orthoSize * (1 + deltaZoom), 0.1);

        this.adjustOrthoClipping(camera);
        this._api.camera.callListeners();
    }

    private adjustOrthoClipping(camera: Web3DCamera): void {
        // Camera distance does not influence object size on screen, but we still change distance to adjust near clipping
        let dist = this._zoomTarget.distanceTo(camera.position);
        dist -= camera.orthoSize / 2 / getPerspectiveViewWorldSize(camera.fov, 1);

        // do not move near camera plane closer to model, as this is "not what customer expects", see https://jira.trimble.tools/browse/TC3DV-1275
        if (dist < 0)
            camera.position.add(this.forward.multiplyScalar(dist));
    }

    private applyZoom(camera: Web3DCamera, deltaZoom: number): void {
        this.zoomOffset.copy(this._zoomTarget).sub(camera.position);

        if (camera.isPerspectiveCamera)
            this.perspectiveZoom(camera, deltaZoom);
        else if (camera.isOrthographicCamera)
            this.orthographicZoom(camera, deltaZoom);
    }

    private onPinchStart(event: PointerInput, touchDistance: number): void {
        this.setZoomTarget(event.x, event.y);
        this.previousPinchDistance = touchDistance;
    }

    private updateTargetIfNeeded(event: ScrollEvent | PointerInput): void {
        if (performance.now() - this.previousTargetTime > 200)
            this.setZoomTarget(event.x, event.y);
    }

    private onPinchMove(event: PointerInput, touchDistance: number): PointerInput {
        this.updateTargetIfNeeded(event);
        const delta = this.previousPinchDistance - touchDistance;

        this.applyZoom(this._camera, delta * this._pinchSpeed);
        this.previousPinchDistance = touchDistance;
        return event;
    }
}
